import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
//import Survey from '../components/Survey/Survey'
import { getSurvey, getFetchError, getFetchStatus, getSubmitStatus } from '../reducers/survey'
import { submitResult, fetchSurvey } from '../actions/survey'
import { withRouter } from 'react-router'

import $ from 'jquery';
//import '../styles.css'
import 'survey-react/survey.css'
import '../assets/survey_result.css'

import * as Survey from 'survey-react'
import { Loader, Segment, Dimmer, Image, Modal, Header, Form, Checkbox, Confirm } from 'semantic-ui-react'
import { updateResult, getUserDetails } from '../api'
import CommentModal from '../components/CommentModal';

import {
	Card,
	Icon,
	Badge,
	Affix,
	Button,
	Divider,
	Popconfirm,
	Descriptions
} from 'antd'

import { mapUserDetailToString } from '../util'

class SurveyResult extends React.Component {

	constructor(props) {
		super(props)

		this.state = {
			recordId: 'checklist',
			isCompleted: false,
			disabled: true,
			showCommentModal: false,
			moreInfos: undefined,
			value: '',
			match: undefined,
			score: 0,
			userDetails: undefined,
			userDetailsLoading: true
		}
	}

	isAdmin = () => this.props.currentUser.role === 'super_admin' || this.props.currentUser.role === 'admin'

	componentDidMount() {
		const surveyId = this.props.match.params.surveyId

		this.props.fetchSurvey(surveyId)

		if (this.props.location.state.result) {
			this.checkSurveyIsLoadedAndSetData = setInterval(() => {
				if (this.survey) {
					this.survey.data = this.props.location.state.result
					clearInterval(this.checkSurveyIsLoadedAndSetData)

					$('span:contains("**")').parent().css('background-color', '#FFFC76');
					$('span:contains("***")').parent().css('background-color', '#FFC000');
					$('span:contains("****")').parent().css('background-color', '#F15E0D');


				}
			}, 1000)
		}

		getUserDetails(this.props.match.params.recordId)
			.then(details => {
				this.setState({
					userDetails: details,
					userDetailsLoading: false
				})
			})
	}

	componentWillUnmount() {
		clearInterval(this.checkSurveyIsLoadedAndSetData)
	}

	componentDidUpdate(prevProps) {
		if (this.props.surveyId !== prevProps.surveyId) {
			this.props.fetchSurvey(this.props.surveyId)
		}
	}

	saveFormAsDraft = () => {
		const results = {
			...this.survey.data,
			draft: true
		}

		const recordId = this.props.match.params.recordId
		const surveyId = this.props.survey._id

		if (this.props.survey.type === 'fiche4')
			this.getScore(this.survey.data)

		updateResult(this.props.location.state._id, results)
		.then(() => {
			this.props.history.replace(`/app/regions/${this.props.survey.region}/records`)
		})
	}

	validateForm = () => {
		if (Object.keys(this.survey.data).length < (this.props.survey.minQuestions || 0)) {
			alert('يجب عليك الإجابة على الأقل 10 أسئلة قبل تقديم الإستمارة ')
		} else {
			const results = {
				...this.survey.data,
				draft: false
			}

			const recordId = this.props.match.params.recordId
			const surveyId = this.props.survey._id

			if (this.props.survey.type === 'fiche4')
				this.getScore(this.survey.data)

			updateResult(this.props.location.state._id, results)
			.then(() => {
				this.props.history.replace(`/app/regions/${this.props.survey.region}/records`)
			})
		}
	}

	onValueChanged = (a, b) => {
		const data = this.survey.data





		if (this.props.survey.type === 'fiche3') {
			$(".sv_progress_bar span").text(
				parseInt(
					(((Object.keys(this.survey.data).length) / 116) * 100)
				)

				+ '%');
			this.getScore(this.survey.data)
		}
	}

	getScore(reseult) {
		let allQuestion = this.survey.getAllQuestions()

		let score1 = 0
		let score2 = 0
		let score = 0

		Object.entries(reseult).map((item, index) => {

			if (allQuestion.find(a => a.name === item[0]) && allQuestion.find(a => a.name === item[0]).isVisible) {
				if (item[0].includes('yes') && item[1] === 'نعم ') {
					if (parseInt(item[0]) < 25)
						score1 = score1 + 3
					else if (parseInt(item[0]) >= 25) {
						score2 = score2 + 3
					}
				} else if (item[0].includes('yes') && item[1] === 'لا') {
					{
						if (parseInt(item[0]) < 25)
							score1 = score1 + 2
						else if (parseInt(item[0]) >= 25) {
							score2 = score2 + 2
						}
					}
				} else if
					(item[0].includes('no') && item[1] === 'نعم ') {
					{
						if (parseInt(item[0]) < 25)
							score1 = score1 + 1
						else if (parseInt(item[0]) >= 25) {
							score2 = score2 + 1
						}
					}
				} else if (item[0].includes('no') && item[1] === 'لا') {
					{
						if (parseInt(item[0]) < 25)
							score1 = score1
						else if (parseInt(item[0]) >= 25) {
							score2 = score2
						}
					}
				}
			}
		})

		score = score1 + score2

		this.survey.setValue('total1', score1)
		this.survey.setValue('total2', score2)
		this.survey.setValue('total', score)
		return score
	}



	render() {
		const {
			survey,
			isLoading
		} = this.props

		if (isLoading)
			{
			return (
				<div
					style={{
						paddingLeft: 50,
						paddingRight: 50,
						marginTop: 50,
						height: 150
					}}
				>
					<Segment loading>
						<Image style={{ opacity: 0 }} src='https://react.semantic-ui.com/images/wireframe/paragraph.png' />
					</Segment>
				</div>
			)
		}
		else
			{
			let survey_model_copy = { ...survey }
			delete survey_model_copy.deleted
			delete survey_model_copy.minQuestions
			delete survey_model_copy.questions
			delete survey_model_copy.region
			delete survey_model_copy.type
			delete survey_model_copy.updatedAt
			delete survey_model_copy.__v
			delete survey_model_copy._id
			delete survey_model_copy.locale
			delete survey_model_copy.goNextPageAutomatic
				delete survey_model_copy.isSinglePage

			//delete survey_model_copy.title
			if (this.props.currentUser.role === 'panel' && this.props.survey.type === 'fiche2') {

				survey_model_copy.pages.map((index, item) => {
					if (item !== 8)
						index.visible = false
				})


			}
			if (
				this.props.location.state.owner != this.props.currentUser._id // not the owner
				||
				!this.props.location.state.draft // not a draft
			) {

				survey_model_copy = {
					mode: 'display',
					...survey_model_copy
				}
			}

			if (this.isAdmin()) {
				delete survey_model_copy.mode
			}

			const model = new Survey.Model({
				"goNextPageAutomatic": false,
				"isSinglePage": false,
				locale: 'ar',
				showTitle: false,
				...survey_model_copy
			})

			return (
			  <div style={{overflow : 'auto', paddingBottom: 100}}>
				  <Affix
					  offsetTop={81}
				  >
					  <div
						  style={{
							  width: '100%',
							  height: 'auto',
							  minHeight : '50px',
							  backgroundColor: '#1bb393',
							  display: 'flex',
							  alignItems: 'center',
							  justifyContent: 'center',
							  boxShadow: '0px 0px 2px 1px rgba(235,235,235,1)'
						  }}
					  >
						  <h3
							  style={{
								  fontFamily: 'Tajawal',
								  color: 'white',
								  textAlign: 'center',
								  marginTop: '9px'
							  }}
						  >
							  {survey_model_copy.title}
						  </h3>
					  </div>
				  </Affix>

				  <div style={{
						direction: 'rtl',
						paddingLeft: 50,
						paddingRight: 50,
						marginTop: 50,
						position: 'relative',
						textAlign: 'right',
						display: 'flex',
						flexDirection: 'row',

					}}>

					<div style={{
						  //  flex: 2,
							boxShadow: '0px 6px 19px -8px rgba(140,140,140,1)'
						}}>
						<Survey.Survey
							// get a ref from the survey to pull data from it
							ref={survey => {
								if (survey && survey.survey) {
									this.survey = survey.survey
								}
							}}
							style={{
								direction: 'rtl'
							}}
							model={model}

							onValueChanged={this.onValueChanged.bind(this)}
						/>
					</div>

					<div
						style={{
							width: '5%'
						}}
					/>

					<Affix
						offsetTop={181}
					>
						<div style={{
								boxShadow: '0px 0px 2px 1px rgba(235,235,235,1)',
								backgroundColor: 'white',
								display: 'flex',
								flexDirection: 'column',
								position: 'relative'
							}}>
							<div style={{ height: 10 }} />

							<Divider dashed>
								<span
									style={{
										fontFamily: 'Tajawal',
										fontWeight: 'bold'
									}}
								>
									تفاصيل الحالة
								</span>
							</Divider>

							<div
								style={{
									padding: 10
								}}
							>
								<Descriptions
									title=''
									column={1}
									bordered
								>
									{
										this.state.userDetails && Object.entries(this.state.userDetails).map((detail, index) => (
											<Descriptions.Item
												key={`${index}`}
												label={mapUserDetailToString(detail[0])}
											>
												{detail[1] || <Icon type="small-dash" />}
											</Descriptions.Item>
										))
									}
								</Descriptions>
							</div>

							{
								this.props.survey && this.props.survey._id

									?   <div
											style={{
												display: 'flex',
												flexDirection: 'column',
												alignItems: 'center',
												bottom: 5,
												width: '100%',
												minHeight: '50px',
												paddingTop: '10px'
											}}
										>
											<Divider dashed />
											<div
												style={{
													display: 'flex',
													flexDirection: 'row',
													alignItems: 'center',
													justifyContent: 'center',
													width: '100%',
													flexWrap : 'wrap'
												}}
											>
												<CommentModal
													userId={this.props.currentUser._id}
													surveyId={this.props.survey._id}
													regionId={this.props.survey.region}
												/>

												{
													(this.props.location.state.draft || this.isAdmin()) || (this.props.location.state.draft && !this.isAdmin())

													?   <>
															<Button
																size='large'
																type='dashed'
																onClick={this.saveFormAsDraft.bind(this)}
																style={{margin : '5px' }}
															>
																حفظ النموذج كمسودة
															</Button>

															<Popconfirm
																placement='top'
																title='تفعيل الإستمارة'
																onConfirm={this.validateForm.bind(this)}
																okText={'حسنا'}
																cancelText={'إلغاء'}
															>
																<Button
																	size='large'
																	type='primary'
																	style={{
																		marginBottom: '5px'
																	}}
																	//onClick={this.validateForm.bind(this)}
																>
																	تفعيل الإستمارة
																</Button>
															</Popconfirm>
														</>
													:   null
												}
											</div>
										</div>
									:   null
							}
						</div>
					</Affix>

				</div>
			</div>
			)
		}
	}
}

const mapStateToProps = (state) => {
	return {
		survey: getSurvey(state.survey),
		isLoading: getFetchStatus(state.survey),
		error: getFetchError(state.survey),
		isSuccess: getSubmitStatus(state.survey),
		currentUser: state.auth.user
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		onSubmit: bindActionCreators(submitResult, dispatch),
		fetchSurvey: bindActionCreators(fetchSurvey, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SurveyResult))
