import React, { Component } from 'react'
import PropTypes from 'prop-types'

import {
    Form,
    Col,
    Row,
    Input,
    Button,
    Drawer,
    Popconfirm,
    message,
    Card,
    Icon,
    Skeleton,
    Avatar
} from 'antd'

import { connect } from 'react-redux'

import {
    createRegionRequest,
    createRegionRequestFail,
    createRegionRequestSuccess, fetchRegionsRequest
} from '../actions/regions'

import { withRouter } from 'react-router'
import { deleteRecord, editRegion, deleteRegion } from "../api"
import { bindActionCreators } from "redux"

import '../assets/regionslist.css'

const { Meta } = Card

class EditRegion extends Component {

    constructor(props) {
        super(props)

        this.state = {
            editRegionDrawerVisible: false
        }
    }

    componentWillReceiveProps(nextProps, nextState) {
        if (nextProps.isCreated) {
            this.setState({
                editRegionDrawerVisible: false
            })
        }
    }

    onSubmit = (e) => {
        e.preventDefault()

        this.props.form.validateFields((err, values) => {
            if (!err) {
                editRegion(this.props.region._id, {
                    nom: values.nom_region,
                    users : this.props.region.users, _id :this.props.region._id })
                this.props.fetchRegions()

                this.setState({
                    editRegionDrawerVisible: false
                })
            }
        })
    }

    render() {

        const { getFieldDecorator } = this.props.form
        const { region, match } = this.props

        return (
            <>
                <Card
                    key={`${region._id}`}

                    style={{
                        width: 250,
                        margin: 16,
                        boxShadow: '0px 6px 19px -8px rgba(140,140,140,1)',
                        fontFamily: 'Tajawal',
                        fontWeight: 'bold',
                        textAlign: 'right',
                        cursor: 'pointer'
                    }}
                    actions={[
                        <Popconfirm
                            title="هل أنت متأكد من حذف هذه المنطقة؟"
                            onConfirm={() => {
                                // TODO
                                 deleteRegion(this.props.region._id).then(()=>{
                                     this.props.fetchRegions()
                                     message.success('تم حذف المنطقة')
                                 })

                            }}
                            onCancel={() => {
                                message.error('لم يتم حذف المنطقة')
                            }}
                            okText="حسنا"
                            cancelText="إلغاء"
                        >
                            <Icon type="delete" />
                        </Popconfirm>,
                        <Icon type="edit"
                            onClick={() => {
                                this.setState({
                                    editRegionDrawerVisible: true
                                })
                            }}
                        />
                    ]}
                >
                    <Skeleton loading={false} active>
                        <Meta
                            onClick={() => {
                                this.props.history.push(`${match.path}/${region._id}/users`)
                            }}
                            title={region.nom}
                            description={'عدد المستخدمين: ' + (region.users ? region.users.filter(a => !a.deleted).length : 0)}
                        />
                    </Skeleton>
                </Card>

                {/* <Card
                    key={region._id}
                    link
                >
                    <Card.Content>
                        <Card.Header
                            style={{
                                textAlign: 'right',
                                display: 'flex',
                                flexDirection :'row',
                                justifyContent: 'space-between'
                            }}
                        >

                            <p
                                onClick={()=>{
                                    this.props.history.push(`${match.path}/${region._id}`)
                                }}
                            >
                                {region.nom}
                            </p>

                            <div>

                                <i
                                    className="edit icon"
                                    onClick={(e)=>{
                                        e.preventDefault()

                                        this.setState({
                                            editRegionDrawerVisible: true
                                        })
                                    }}
                                />

                                <Popconfirm
                                    title="هل أنت متأكد من حذف هذه المنطقة؟"
                                    onConfirm={()=>{

                                        deleteRegion(this.props.region._id)
                                        this.props.fetchRegions()
                                        message.success(' تم الحذف بنجاح ')

                                    }}
                                    onCancel={()=>{message.error('الغاء الحذف')}}
                                    okText="نعم"
                                    cancelText="لا"
                                >
                                    <i className="delete icon"></i>
                                </Popconfirm>

                            </div>
                        </Card.Header>
                    </Card.Content>
                </Card> */}



                <Drawer
                    title="تعديل المنطقة "
                    placement="left"
                    width={520}
                    closable={false}
                    onClose={() => this.setState({ editRegionDrawerVisible: false })}
                    visible={this.state.editRegionDrawerVisible}
                >
                    <Form
                        layout='vertical'
                        onSubmit={this.onSubmit.bind(this)}
                    >
                        <Row gutter={16}>
                            <Form.Item label="اسم المنطقة">
                                {
                                    getFieldDecorator('nom_region',

                                        {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'الرجاء إدخال اسم المنطقة'
                                            }
                                        ],
                                        initialValue : region.nom
                                    })

                                    (<Input size='large'  placeholder="الرجاء إدخال اسم المنطقة" />)
                                }
                            </Form.Item>
                        </Row>

                        <div
                            style={{
                                position: 'absolute',
                                bottom: 0,
                                width: '100%',
                                borderTop: '1px solid #e8e8e8',
                                padding: '10px 16px',
                                textAlign: 'right',
                                left: 0,
                                background: '#fff',
                                borderRadius: '0 0 4px 4px',
                                direction: 'rtl'
                            }}
                        >
                            <Button
                                style={{
                                    marginLeft: 8,
                                }}
                                onClick={() => this.setState({ editRegionDrawerVisible: false })}
                            >
                                إلغاء
                            </Button>
                            <Button
                                type="primary"
                                htmlType='submit'
                            >
                                حسنا
                            </Button>
                        </div>
                    </Form>
                </Drawer>
            </>
        )
    }
}

function mapStateToProps (state) {
    return {
        isCreated: state.regions.isCreated
    }
}

function mapDispatchToProps (dispatch) {
    return {
        createRegion: (values) => {
            return dispatch(createRegionRequest({
                nom: values
            })).then(res => {
                dispatch(createRegionRequestSuccess(res))
                return Promise.resolve(res)
            }).catch(err => {
                dispatch(createRegionRequestFail(err))
                return Promise.reject(err)
            })
        } ,
        fetchRegions: bindActionCreators(fetchRegionsRequest, dispatch)

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(withRouter(EditRegion)))
