
import React, { useState, useEffect } from 'react'

import {
	Card,
	Statistic,
	Spin,
	Divider
} from 'antd'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { fetchRegionsRequest } from '../actions'

import { fetchAllRecords } from '../api'
import RegionStats from './RegionStats';

function GeneralStats({ regions, isLoading, loadError, fetchRegions }) {

	const [records, setRecords] = useState([])

	useEffect(() => {
		
		async function loadRegions () {
			await fetchRegions()
			const records = await fetchAllRecords()
			setRecords(records)
		}

		loadRegions()

	}, [])

	const totalNumberOfUsers = regions.map(region => region.users)
								.flat()
								.filter(user => !user.deleted)
								.length

	const totalNumberOfMaleUsers = regions.map(region => region.users)
									.flat()
									.filter(user => !user.deleted)
									.filter(user => user.gender == 'male')
									.length

	const totalNumberOfFemaleUsers = totalNumberOfUsers - totalNumberOfMaleUsers

	const percentageOfFemaleUsers = ((totalNumberOfFemaleUsers / totalNumberOfUsers) * 100).toFixed(2)

	const percentageOfMaleUsers = ((totalNumberOfMaleUsers / totalNumberOfUsers) * 100).toFixed(2)

	if (isLoading) {
		return (
			<div
				style={{
					flex: 1,
					height: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center'
				}}
			>
				<Spin size='large' />
			</div>
		)
	}

	if (loadError) {
		return <div>Load Error</div>
	}

	return (
		<div
			style={{
				display: 'flex',
				flexWrap: 'wrap',
				justifyContent: 'flex-end',
				margin: 16
			}}
		>
			<div
				style={{
					display: 'flex',
					flexWrap: 'wrap',
					justifyContent: 'flex-end',
					alignContent: 'flex-start',
					flex: 1
				}}
			>
				<Card
					hoverable
					style={{
						margin: 16
					}}
				>
					<Statistic
						style={{
							textAlign: 'center',
							width: 200
						}}
						title='عدد الملفات الجملي'
						value={records.totalDocs}
						//valueStyle={{ color: '#3f8600' }}
					/>
				</Card>

				<Card
					hoverable
					style={{
						margin: 16
					}}
				>
					<Statistic
						style={{
							textAlign: 'center',
							width: 200
						}}
						title='عدد المستخدمين الاناث الجملي'
						value={`${totalNumberOfFemaleUsers} (${percentageOfFemaleUsers}%)`}
						//valueStyle={{ color: '#3f8600' }}
					/>
				</Card>

				<Card
					hoverable
					style={{
						margin: 16
					}}
				>
					<Statistic
						style={{
							textAlign: 'center',
							width: 200
						}}
						title='عدد المستخدمين الذكور الجملي'
						value={`${totalNumberOfMaleUsers} (${percentageOfMaleUsers}%)`}
						//valueStyle={{ color: '#3f8600' }}
					/>
				</Card>

				<Card
					hoverable
					style={{
						margin: 16
					}}
				>
					<Statistic
						style={{
							textAlign: 'center',
							width: 200
						}}
						title="عدد المستخدمين الجملي"
						value={totalNumberOfUsers}
						//valueStyle={{ color: '#3f8600' }}
					/>
				</Card>

				<Card
					hoverable
					style={{
						margin: 16
					}}
				>
					<Statistic
						style={{
							textAlign: 'center',
							width: 200
						}}
						title="عدد المناطق الجملي"
						value={regions.length}
						//valueStyle={{ color: '#3f8600' }}
					/>
				</Card>
			</div>

			<Divider/>
			
			<div
				style={{
					marginBottom: 10,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					width: '100%'
				}}
			>
				<RegionStats/>
			</div>
		</div>
	)
}

function mapStateToProps (state) {
	return {
		regions: state.regions.regions,
		isLoading: state.regions.isLoading,
		loadError: state.regions.error
	}
}

function mapDispatchToProps (dispatch) {
	return {
		fetchRegions: bindActionCreators(fetchRegionsRequest, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneralStats)