import * as Survey from 'survey-react'

const model = new Survey.Model(JSON.stringify(
	{
		"mode": 'display',
		"locale": 'ar',

		"title": "محضر جلسة متابعة برنامج جسور",
		"pages": [
			{
				"name": "page1",
				"elements": [
					{
						"type": "text",
						"name": "المكان",
						"title": "المكان",
						"placeHolder": "مركز الدفاع والإدماج الاجتماعي بـ"
					},
					{
						"type": "text",
						"name": "المكان1",
						"title": "المكان",
						"placeHolder": "قسم النهوض الاجتماعي بـــ"
					},
					{
						"type": "text",
						"name": "مكان آخر, ذكره",
						"title": "مكان آخر, ذكره"
					},
					{
						"type": "matrixdynamic",
						"name": "الحاضرون",
						"title": "الحاضرون",
						"columns": [
							{
								"name": "الاسم واللقب"
							},
							{
								"name": "الصفة في البرنامج"
							}
						],
						"choices": [
							1,
							2,
							3,
							4,
							5
						],
						"cellType": "text"
					},
					{
						"type": "matrixdynamic",
						"name": "متدخلين  تم تشريكهم بالإجتماع",
						"title": "متدخلين \nتم تشريكهم بالإجتماع\n",
						"columns": [
							{
								"name": "الاسم واللقب"
							},
							{
								"name": "الصفة /المؤسسة"
							}
						],
						"choices": [
							1,
							2,
							3,
							4,
							5
						],
						"cellType": "text"
					}
				],
				"title": "الإطار العام "
			},
			{
				"name": "page2",
				"elements": [
					{
						"type": "matrixdynamic",
						"name": "دراسة حالات جديدة تم إستكشافها",
						"title": "دراسة حالات جديدة تم إستكشافها",
						"columns": [
							{
								"name": "yes",
								"cellType": "boolean"
							},
							{
								"name": "عددها ",
								"cellType": "text",
								"enableIf": "{دراسة حالات جديدة تم إستكشافها[0].yes} = true"
							}
						],
						"choices": [
							1,
							2,
							3,
							4,
							5
						],
						"rowCount": 1
					},
					{
						"type": "matrixdynamic",
						"name": "متابعة حالات (تم استكشافها سابقا) بهدف التعمق في دراسته",
						"title": "متابعة حالات (تم استكشافها سابقا) بهدف التعمق في دراسته",
						"columns": [
							{
								"name": "yes",
								"cellType": "boolean"
							},
							{
								"name": "عددها ",
								"cellType": "text",
								"enableIf": "{متابعة حالات (تم استكشافها سابقا) بهدف التعمق في دراسته[0].yes} = true"
							}
						],
						"choices": [
							1,
							2,
							3,
							4,
							5
						],
						"rowCount": 1
					},
					{
						"type": "boolean",
						"name": "مناقشة عوامل الهشاشة التي تمت ملاحظتها  بالحي",
						"title": "مناقشة عوامل الهشاشة التي تمت ملاحظتها  بالحي"
					},
					{
						"type": "expression",
						"name": "  يتم تقديم الوضعيات المكتشفة طبقا للأنموذج المرفق بالملاحق  واستنادا على المثال المقدم في الغرض",
						"title": "  يتم تقديم الوضعيات المكتشفة طبقا للأنموذج المرفق بالملاحق  واستنادا على المثال المقدم في الغرض\n",
						"commentText": "Other (describe)"
					}
				],
				"title": "جدول الأعمال"
			},
			{
				"name": "page3",
				"elements": [
					{
						"type": "comment",
						"name": "في علاقة بوضعيات الشباب",
						"title": "في علاقة بوضعيات الشباب"
					},
					{
						"type": "comment",
						"name": "في علاقة بوضعية الهشاشة بالحي",
						"title": "في علاقة بوضعية الهشاشة بالحي"
					},
					{
						"type": "comment",
						"name": "القرارات و التوصيّات",
						"title": "القرارات و التوصيّات"
					},
					{
						"type": "expression",
						"name": "الاجتماع القادم",
						"title": "الاجتماع القادم",
						"commentText": "Other (describe)"
					},
					{
						"type": "datepicker",
						"name": "التاريخ",
						"title": "التاريخ"
					},
					{
						"type": "text",
						"name": "التوقيت",
						"title": "التوقيت",
						"placeHolder": "من ............. إلى ............. "
					},
					{
						"type": "text",
						"name": "المكان2",
						"title": "المكان"
					},
					{
						"type": "text",
						"name": "مقرر الجلسة",
						"title": "مقرر الجلسة"
					}, {
						"type": "signaturepad",
						"name": "الإمضاء ",
						"title": "الإمضاء "
					}
				],
				"title": "فعاليات الجلسة"
			}
		]
	}
))

export default model