
import React from 'react'
import { connect } from 'react-redux'
import { Breadcrumb, Tag } from 'antd'
import { NavLink } from 'react-router-dom'
import withBreadcrumbs from 'react-router-breadcrumbs-hoc'

function text_truncate (str, length, ending) {
	if (length == null) {
		length = 100
	}
	if (ending == null) {
		ending = '...'
	}
	if (str.length > length) {
		return str.substring(0, length - ending.length) + ending
	} else {
		return str
	}
}

const RegionBreadCrumb = ({ regions, params }) => {
	const region = regions.find(region => region._id === params.regionId || params.id)

	return (
		<span>{region && region.nom ? region.nom : 'بدون عنوان'}</span>
	)
}

const SurveyBreadCrumb = ({ survey }) => {
	return (
		<span>{survey && survey.title ? text_truncate(survey.title, 20) : 'بدون عنوان'}</span>
	)
}

const RecordBreadCrumb = ({ records, params }) => {
	const record = records.find(record => record._id == params.recordId)
	
	return (
		<span>{record && record.numFolder ? record.numFolder : 'جديد'}</span>
	)
}

function mapStateToProps (nextState, nextOwnProps) {
	return {
		regions: nextState.regions.regions,
		survey: nextState.edit.survey,
		params: nextOwnProps.match.params,
		records: nextState.records.records
	}
}

const ConnectedRegionBreadCrumb = connect(mapStateToProps, null)(RegionBreadCrumb)
const ConnectedSurveyBreadCrumb = connect(mapStateToProps, null)(SurveyBreadCrumb)
const ConnectedRecordBreadCrumb = connect(mapStateToProps, null)(RecordBreadCrumb)

const routes = [
	{ path: '/app', breadcrumb: 'جسور' },
	
	{ path: '/app/regions', breadcrumb: 'المناطق' },
	{ path: '/app/stats', breadcrumb: 'احصائيات' },
	{ path: '/app/regions/:regionId', breadcrumb: ConnectedRegionBreadCrumb },
	
	{ path: '/app/regions/:regionId/users', breadcrumb: 'المستخدمين' },
	{ path: '/app/regions/:regionId/surveys', breadcrumb: 'الإستمارات' },
	{ path: '/app/regions/:regionId/records', breadcrumb: 'الملفات' },
	{ path: '/app/regions/:regionId/matches', breadcrumb: 'تطابقات' },
	{ path: '/app/regions/:regionId/reports', breadcrumb: 'المحاضر' },
	{ path: '/app/regions/:regionId/comments', breadcrumb: 'تعليقات' },
	{ path: '/app/regions/:regionId/stats', breadcrumb: 'إحصائيات' },

	{ path: '/app/regions/:regionId/surveys/:surveyId', breadcrumb: ConnectedSurveyBreadCrumb},
	{ path: '/app/regions/:regionId/surveys/:surveyId/edit', breadcrumb: 'تعديل'},
	{ path: '/app/regions/:regionId/surveys/:surveyId/edit', breadcrumb: 'تعديل'},
	///regions/:id/records/:recordId/surveys/:surveyId/results
	
	{ path: '/app/records', breadcrumb: 'الملفات' },
	{ path: '/app/records/:recordId', breadcrumb: ConnectedRecordBreadCrumb },
	{ path: '/app/records/:recordId/surveys', breadcrumb: 'الإستمارات' },
	{ path: '/app/records/:recordId/surveys/:surveyId', breadcrumb: ConnectedSurveyBreadCrumb },
	{ path: '/app/records/:recordId/surveys/:surveyId/results', breadcrumb:'الأجوبة' },

	{ path: '/app/regions/:regionId/records/:recordId', breadcrumb: ConnectedRecordBreadCrumb },
	{ path: '/app/regions/:regionId/records/:recordId/surveys', breadcrumb: 'الإستمارات' },
	{ path: '/app/regions/:regionId/records/:recordId/surveys/:surveyId', breadcrumb: ConnectedSurveyBreadCrumb },
	{ path: '/app/regions/:regionId/records/:recordId/surveys/:surveyId/results', breadcrumb: 'الأجوبة' }
]

const LimeLight = ({ breadcrumbs, clickable }) => {
	return (
		<div className='limelight'>
			<Breadcrumb>
				{
					breadcrumbs.map(({ match, breadcrumb }, index) => {
						if (
							match.path == '/app/regions/:regionId' ||
							match.path == '/app' ||
							match.path == '/app/regions/:regionId/surveys/:surveyId' ||
							match.path == '/app/regions/:regionId/records/:recordId' ||
							match.path == '/app/regions/:regionId/records/:recordId/surveys' ||
							match.path == '/app/regions/:regionId/records/:recordId/surveys/:surveyId' ||
							match.path == '/app/regions/:regionId/records/:recordId/surveys/:surveyId/results' ||
							!clickable
						) {
							return (
								<Breadcrumb.Item key={index}>
									<Tag>
										{breadcrumb}
									</Tag>
								</Breadcrumb.Item>
							)
						}

						return (
							<Breadcrumb.Item key={index}>
								<Tag>
									<NavLink to={match.url}>
										{breadcrumb}
									</NavLink>
								</Tag>
							</Breadcrumb.Item>
						)
					})
				}
			</Breadcrumb>
		</div>
	)
}

export default withBreadcrumbs(routes, { excludePaths: ['/'] })(LimeLight)