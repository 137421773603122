//import { Path } from '../routes'
import * as types from '../constants/action-types'

const initialState = {
    isSuccess: false,
    isLoading: false,
    user: null,
    prevPath: null,
	error: null
}

export default function (state = initialState, action) {

	switch (action.type) {
		case types.LOGIN_REQUEST:
			return {
				...state,
				isLoading: true,
				error: action.payload

			}

        case types.LOGIN_REQUEST_SUCCESS:
            return {
                ...state,
				isLoading: false,
				isSuccess: true,
				user: action.payload,
				error:null
            }

		case types.LOGIN_REQUEST_FAILED:
			return {
				...state,
				isLoading: false,
				isSuccess: false,
				error: action.payload
            }

		case types.LOGOUT:
            return initialState


		default:
			return state
	}
}

/*

export const home = (user) => {
	//return Path.surveyList()
	if (user.role === 'super_admin')
		return Path.regionsList()
	else
		return Path.regionById(user.region)
}*/
