/* global location */
/* eslint no-restricted-globals: ["off", "location"] */

import React from 'react'
import { connect } from 'react-redux'
import {fetchUserSurveys} from '../api'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import RecordDetailsModal from "./RecordDetailsModal"
import { Card, Divider, Popconfirm, message, Spin } from 'antd'
import { fetchRegionsRequest , fetchRecordsRequest } from '../actions'
import { Button, Container, Modal, Input } from 'semantic-ui-react'

import '../App.css'
import { mapFicheEtatToString } from '../util';
import NewRecord from './NewRecord';
import Record from './Record'

class RegionRecords extends React.Component {

	_isMounted = false

	constructor(props) {
		super(props);
		this.state= {
			all_surveys: [],
			loading: true ,
			open : false,
			num_dossier: ''
		}
	}

	componentDidMount() {
		this.props.fetchRegions()
		this._isMounted = true
		fetchUserSurveys(this.props.currentUser)
			.then(surveys => {
				this.props.fetchRecords(this.props.currentUser.role, this.props.match.params.id)
					this.setState({
						all_surveys: surveys.filter(survey => survey.region === this.props.match.params.id),
						loading : false
					})

			})
	}

	componentWillUnmount () {
		this._isMounted = false
	}

	render() {
		
		const region = this.props.regions.find(region => region._id == this.props.match.params.id)
		const checklist = this.state.all_surveys.find(survey => survey.type == 'fiche0')

		if (this.state.loading) {
			return (
				<div
					style={{
						flex: 1,
						height: '100%',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center'
					}}
				>
					<Spin size='large' />
				</div>
			)
		}

		return (
			<div
				style={{
					flex: 1,
					display: 'flex',
					justifyContent: 'flex-end',
					flexDirection : 'column',
					direction : 'rtl'
				}}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'space-between'
					}}
				>
					<div
						style={{
							flexDirection: 'row',
							display: 'flex',
							marginBottom: 16
						}}
					>
						{
							checklist &&
							(
								<NewRecord checklist={checklist} />
							)
						}
					</div>
				</div>

				<div
					style={{
						display: 'flex' ,
						flexDirection : 'row' ,
						flexWrap : 'wrap'
					}}
				>
					{
						this.props.records ? this.props.records.map(record => (
							<Record
								key={record._id}
								record={record}
								all_surveys={this.state.all_surveys}
								currentUser={this.props.currentUser}
							/>
						))

						:	null
					}
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	regions: state.regions.regions,
	isLoading: state.regions.isLoading,
	loadError: state.regions.error,
	currentUser: state.auth.user,
	isCreated: state.regions.isCreated,
	records : state.records.records
})

const mapDispatchToProps = (dispatch) => {
	return {
		fetchRegions: bindActionCreators(fetchRegionsRequest, dispatch),
		fetchRecords: bindActionCreators(fetchRecordsRequest, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RegionRecords))