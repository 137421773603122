import React, { useState, useEffect } from 'react'
import { baseUri, getFiles, uploadNewFile, Back, uri } from '../api'

import { Icon, Drawer, Form, Input, Upload, message, Button, Progress, Spin} from 'antd'
import Colors from '../constants/colors'

function AddDocument(props) {

	const [drawerOpen, setDrawerOpen] = useState(false)
	const [fileToUpload, setFileToUpload] = useState({})
	const [uploading, setUploading] = useState(false)

	const changeFromFirebase = (file, title, cb) => {
		let updates = {}

		let metadata = {
			contentType: 'file/pdf'
		}

		const fileId = (new Date()).getTime()

		setUploading(true)

		Back.storage().ref(`/pdfs/${fileId}.pdf`).put(file, metadata)
		.then(res => {
			res.ref.getDownloadURL().then((downloadURL) => {
				let image = {
					id: fileId,
					title: title,
					URL: downloadURL,
				}
				try {
					updates[`pdfs/${fileId}`] = image
					Back.database().ref().update(updates).then(
						() => {
							setUploading(false)
							cb()
						}
					)
				} catch (error) {
					if (process.env.NODE_ENV === 'development')
						console.log(error)
				}
			})
		})
		.catch(error => {
			console.log(error)
		})
	}
	const changeFromServer = (file, title, cb) => {

		console.log(props.document)
		uploadNewFile(file, title ).then(() => {
			props.refresh()
			cb()
		})
	}
	const onSubmit = event => {

		event.preventDefault()
		const { form } = props

		form.validateFields((err, values) => {

			if (!values.title && values.title.length == 0) {
				message.error('يرجى إدخال عنوان')
				return
			}

			if (err) {
				if (values.dragger === undefined) {
					message.error('اضغط أو اسحب الملف إلى هذه المنطقة للتحميل')
				}
				return
			}

			changeFromServer(values.dragger.file, values.title, () => {
				props.refresh()
				setDrawerOpen(!drawerOpen)
			})
		})
	}

	const { getFieldDecorator } = props.form

	if (!props.admin)
		return null

	return (
		<div
			onClick={() => setDrawerOpen(true)}
			style={{
				backgroundColor: Colors.dark_blue_active,
				width: '100%',
				height: '80px',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				cursor: 'pointer',
				marginTop: 2
			}}
		>
			<Icon
				type='file-add'
				style={{
					fontSize: 40,
					color: 'white'
				}}
			/>

			<Drawer
				title='تعديل'
				placement='left'
				width={'50%'}
				onClose={(e) => {
					e.stopPropagation()
					setDrawerOpen(false)
				}}
				visible={drawerOpen}
			>
				<Form onSubmit={onSubmit}>

					<Form.Item
						label='العنوان'
						style={{
							marginBottom: 20
						}}
					>
						{
							getFieldDecorator('title', {
								initialValue: '',
								rules: [{ required: true, message: 'يرجى اختيار العنوان' }]
							})(
								<Input size='large' placeholder='العنوان' disabled={uploading} />
							)
						}
					</Form.Item>

					<Spin spinning={uploading}>
						{
							getFieldDecorator('dragger', {
								valuePropName: 'file',
								rules: [{ required: true, message: 'اضغط أو اسحب الملف إلى هذه المنطقة للتحميل' }]
							})(
								<Upload.Dragger
									multiple={false}
									name="file"
									onRemove={() => {
										setFileToUpload({})
									}}
									beforeUpload={file => {
										setFileToUpload(file)
										return false
									}}
								>
									<p className="ant-upload-drag-icon">
										<Icon type="inbox" />
									</p>
									<p className="ant-upload-text">اضغط أو اسحب الملف إلى هذه المنطقة للتحميل</p>
								</Upload.Dragger>
							)
						}
					</Spin>

					<div
						style={{
							position: 'absolute',
							bottom: 0,
							width: '100%',
							borderTop: '1px solid #e8e8e8',
							padding: '10px 16px',
							textAlign: 'right',
							left: 0,
							background: '#fff',
							borderRadius: '0 0 4px 4px',
						}}
					>
						<Button
							disabled={uploading}
							style={{
								marginRight: 8,
							}}
							onClick={(e) => {
								e.stopPropagation()
								setDrawerOpen(false)
							}}
						>
							إلغاء
						</Button>
						<Button
							disabled={uploading}
							type="primary"
							htmlType='submit'
						>
							حسنا
						</Button>
					</div>
				</Form>
			</Drawer>
		</div>
	)
}

export default Form.create()(AddDocument)
