import $ from "jquery";

import * as Survey from "survey-knockout";
import * as widgets from "surveyjs-widgets";

// import "jquery-ui/ui/widgets/datepicker.js";
// import "select2/dist/js/select2.js";
import "jquery-bar-rating";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-slider/dist/css/bootstrap-slider.css";
// import "jquery-ui/themes/base/all.css";
import "nouislider/distribute/nouislider.css";
// import "select2/dist/css/select2.css";
import "jquery-bar-rating/dist/themes/css-stars.css";

// import * as Inputmask from 'inputmask';
window["$"] = window["jQuery"] = $;
// window['Inputmask'] = Inputmask;

// widgets.select2(Survey, $);
widgets.inputmask(Survey);
widgets.jquerybarrating(Survey, $);
widgets.jqueryuidatepicker(Survey, $);
widgets.nouislider(Survey);
widgets.select2tagbox(Survey, $);
widgets.signaturepad(Survey);
widgets.sortablejs(Survey);
widgets.ckeditor(Survey);
widgets.autocomplete(Survey, $);
widgets.bootstrapslider(Survey);

Survey.JsonObject.metaData.addProperty("survey", {
    name: "!type",
    default: "Choisir le type du formulaire",
    choices: [
        "Choisir le type du formulaire",
        "Checklist",
        "Résumé Tableau",
        "Enquête - Fiche 1",
        "Analyse des facteurs de risque et des facteurs de résiliences - Fiche 2",
        "Analyse des besoins - Fiche 3",
        "Outil psychologique",
        "Plan d'intervention - Fiche 4 (1)",
        "Service delivery - Fiche 5"
    ],
    onSetValue: function (obj, value, jsonConverter) {obj.survey_type = value; /* Perform some actions */ },
    onGetValue: function (obj: any) {

        if (obj.type == "Choisir le type du formulaire") {
            return ''
        }
        if (obj.type == "Checklist") {
            return 'fiche0'
        }
        if (obj.type == "Résumé Tableau") {
            return 'fiche1'
        }
        if (obj.type == "Enquête - Fiche 1") {
            return 'fiche2'
        }
        if (obj.type == "Analyse des facteurs de risque et des facteurs de résiliences - Fiche 2") {
            return 'fiche4'
        }

        if (obj.type == "Analyse des besoins - Fiche 3") {
            return 'fiche5'
        }

        if (obj.type == "Outil psychologique") {

            return 'fiche3'
        }

        if (obj.type == "Plan d'intervention - Fiche 4 (1)") {
            return 'fiche6'
        }

        // if (obj.type == "Plan de services - Fiche 4 (2)") {
        //     return 'fiche7'
        // }

        if (obj.type == "Service delivery - Fiche 5") {
            return 'fiche7'
        }

        return obj.type
    }
})

Survey.JsonObject.metaData.addProperty("survey", "minQuestions:number")

// var defaultThemeColors = Survey.StylesManager.ThemeColors["default"];
// defaultThemeColors["$main-color"] = "#2F2E7C";
// defaultThemeColors["$main-hover-color"] = "#6fe06f";
// defaultThemeColors["$text-color"] = "#4a4a4a";
// defaultThemeColors["$header-color"] = "#7ff07f";

// defaultThemeColors["$header-background-color"] = "#4a4a4a";
// defaultThemeColors["$body-container-background-color"] = "#f8f8f8";

// Survey
//     .StylesManager
//     .applyTheme();
