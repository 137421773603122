import * as actionTypes from '../constants/action-types'

const initialState = {
	error: null,
	survey: null,
	isLoading: false,
	isDeleteSuccess: false
}

export default function (state = initialState, action) {
	switch (action.type) {
		case actionTypes.FETCH_SURVEY_REQUEST:
			return {
				...state,
				isLoading: true
			}

		case actionTypes.FETCH_SURVEY_REQUEST_SUCCESS:
			return {
				...state,
				isLoading: false,
				survey: action.payload
			}

		case actionTypes.FETCH_SURVEY_REQUEST_FAIL:
			return {
				...state,
				isLoading: false,
				error: action.payload
			}

		case actionTypes.UPDATE_SURVEY_REQUEST:
			return {
				...state,
				isLoading: true
			}

		case actionTypes.UPDATE_SURVEY_REQUEST_SUCCESS:
			return {
				...state,
				isLoading: false,
				survey: action.payload
			}

		case actionTypes.UPDATE_SURVEY_REQUEST_FAIL:
			return {
				...state,
				isLoading: false,
				error: action.payload
			}

		case actionTypes.DELETE_SURVERY_REQUEST:
			return {
				...state,
				isLoading: true,
				isDeleteSuccess: false
			}

		case actionTypes.DELETE_SURVEY_REQUEST_SUCCESS:
			return {
				...state,
				isLoading: false,
				isDeleteSuccess: true
			}

		case actionTypes.DELETE_SURVEY_REQUEST_FAIL:
			return {
				...state,
				isLoading: false,
				isDeleteSuccess: false,
				error: action.payload
			}

		case actionTypes.RESET_CREATE_SURVEY:
			return initialState

		default:
			return state
	}
}

export const getSurvey = (state) => {
	return state.survey.survey;
};

export const getFetchStatus = (state) => {
	return state.survey.isLoading;
};

export const getFetchError = (state) => {
	return state.survey.error;
};