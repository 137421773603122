import React, { Component } from 'react'
import PropTypes from 'prop-types'

import {
	Form,
	Col,
	Row,
	Input,
	Button,
	Drawer,
	Card,
	List,
	Avatar,
	Descriptions,
	Icon
} from 'antd'

import { connect } from 'react-redux'
import EditableUserDetails from './EditableUserDetails'
import { mapRoleValueToString } from '../util'

class UsersByRole extends Component {

	constructor(props) {
		super(props)

		this.state = {
			usersByRoleDrawerVisible: false
		}
	}

	componentWillReceiveProps(nextProps, nextState) {
		if (nextProps.isCreated) {
			this.setState({
				usersByRoleDrawerVisible: false
			})
		}
	}

	render() {

		const { usersByRole } = this.props

		return (
			<div
				style={{
					marginLeft: 16,
					marginTop: 16,
					width: 250
				}}
			>
				<Card
					size='default'
					style={{
						fontFamily: 'Tajawal',
						width: 250
					}}
					bordered
					hoverable
					onClick={() => this.setState({ usersByRoleDrawerVisible: true })}
				>
					<p><span style={{ fontWeight: 'bold'}}>الدور :</span>{mapRoleValueToString(usersByRole.role)}</p>
					<p>{usersByRole.users.filter(a => !a.deleted).length}</p>
				</Card>

				<Drawer
					title={mapRoleValueToString(usersByRole.role)}
					placement='left'
					width={520}
					closable={false}
					onClose={() => this.setState({ usersByRoleDrawerVisible: false })}
					visible={this.state.usersByRoleDrawerVisible}
				>
					<List
						itemLayout="horizontal"
						dataSource={usersByRole.users}
						renderItem={user => (
							<>
								<List.Item
									onClick={() => this.setState({ [`${user._id}DrawerOpen`]: true })}
								>
									<List.Item.Meta
										avatar={<Avatar shape='square' src="https://image.flaticon.com/icons/png/512/55/55089.png" />}
										title={user.name}
										description={user.email}
									/>
								</List.Item>

								<Drawer
									title={user.name}
									placement='left'
									width={520}
									bodyStyle={{
										textAlign: 'right'
									}}
									closable={false}
									destroyOnClose
									onClose={() => this.setState({ [`${user._id}DrawerOpen`]: false })}
									visible={this.state[`${user._id}DrawerOpen`]}
								>
									<EditableUserDetails
										user={user}
										onEditSuccess={() => this.setState({ [`${user._id}DrawerOpen`]: false })}
									/>
								</Drawer>
							</>
						)}
					/>
				</Drawer>
			</div>
		)
	}
}

export default UsersByRole
