import * as firebase from 'firebase';

import axios from 'axios'
import decode from 'jwt-decode'

// export const uri = 'http://192.168.1.38:3005/api'
// export const baseUri = 'http://192.168.1.38:3005/'
export const baseUri = 'https://api.joussour.beder.org/'
export const uri = 'https://api.joussour.beder.org/api'
//export const baseUri = 'http://localhost:3005/'
//export const uri = 'http://localhost:3005/api'

let firebaseConfig = {
    apiKey: "AIzaSyClIEA5WvFhX6lFewg2bX2zGyP07TdUaJY",
    authDomain: "glass-bridge-217521.firebaseapp.com",
    databaseURL: "https://glass-bridge-217521.firebaseio.com",
    projectId: "glass-bridge-217521",
    storageBucket: "glass-bridge-217521.appspot.com",
    messagingSenderId: "98265486278",
    appId: "1:98265486278:web:be40fdcc35b4d970"
}

// Initialize Firebase
export const Back = firebase.initializeApp(firebaseConfig)


const fetcher = axios.create({
    baseURL: uri,
    headers: {

        'Content-Type': 'application/json',
        'Authorization':localStorage.getItem('user') ? 'bearer ' + JSON.parse(localStorage.getItem('user')).token : undefined
        //'Authorization': undefined
    }
})

export const createUser = (params) => {
    return fetcher.post("/users", params).then(res => res.data)
}

export const createAndAddUserToRegion = (params) => {
    return fetcher.post(`/regions/${params.region}/register`, {...params}).then(res => res.data)
}

export const createRegion = (params) => {
    return fetcher.post('/regions', params).then(res => res.data)
}

export const login = (email, password) => {
    return fetcher.post("/login", {
        email,
        password
    }).then(res => {
        localStorage.setItem('user', JSON.stringify(res.data))
        Object.assign(fetcher.defaults, {headers: {authorization: 'bearer ' + res.data.token}});

      //  fetcher.defaults.headers.common['Authorization'] = 'bearer ' + res.data.token
        return decode(res.data.token)
    }).catch(error => {
        if (process.env.NODE_ENV == 'development')
            console.log(error)
    })
}

export const logout = () => {
    localStorage.removeItem('user')
    return Promise.resolve()
}

export const fetchAllResults = () => {
    return fetcher.get('/results').then(res => res.data)
}

export const fetchCurrentUser = () => {

    const user = JSON.parse(localStorage.getItem('user'))

    if (user) {
        return fetcher.get(`/user/${user.user._id}`)
            .then(res => res.data)
    } else {
        return Promise.reject('no user session')
    }
}

export const fetchUserSurveys = (user) => {
    return fetcher.get(`/users/${user._id}/surveys`).then(res => res.data)
}

export const fetchRecords = (role, regionId) => {
    return fetcher.post(`/records/region`, {role, regionId}).then(res => res.data)
}

export const fetchAllRecords = (limit = 6, page = 1) => {
    return fetcher.get(`/records?limit=${limit}&page=${page}`).then(res => res.data)
}

export const searchRecords = (limit = 9, page = 1, term = '') => {
    return fetcher.get(`/records/search?limit=${limit}&page=${page}&search=${term}`).then(res => res.data)
}

export const fetchRegions = () => {
    return fetcher.get('/regions').then(res => res.data)
}

export const fetchResults = (surveyId) => {
    return fetcher.get(`/surveys/${surveyId}/results`).then(res => res.data)
}

export const fetchRecordResults = (recordId) => {
    return fetcher.get(`/records/${recordId}`).then(res => res.data)
}

export const createSurvey = (userId, initSurvey) => {
    return fetcher.post(`/users/${userId}/surveys`, initSurvey).then(res => res.data)
}

export const saveResult = (surveyId, recordId, result) => {
    const userId = JSON.parse(localStorage.getItem('user')).user._id

    return fetcher.post(`/surveys/${surveyId}/${userId}/${recordId}/results`, result)
}

export const fetchSurvey = (surveyId) => {
    return fetcher.get(`/surveys/${surveyId}`).then(res => res.data)
}

export const fetchRegion = (regionId) => {
    return fetcher.get(`/regions/${regionId}`).then(res => res.data)
}

export const deleteSurvey = (survey) => {
    return fetcher.put(`/surveys/${survey._id}`, {deleted: true}).then(res => res.data)
}

export const updateSurvey = (survey) => {
    return fetcher.put(`/surveys/${survey._id}`, survey).then(res => res.data)
}

export const deleteResults = (surveyId, results) => {
    return Promise.all(results.map(result => fetcher.delete(`/surveys/${surveyId}/results/${result.id}`)))
}

export const fetchQuestionResultsPairs = (resultId, surveyId) => {
    return fetcher.post(`/records/results`, {resultId, surveyId}).then(res => res.data)
}
export const fetchSurveysByIds = (surveys) => {
    return fetcher.post(`/surveys`, {surveys}).then(res => res.data)
}

export const updateResult = (resultid, result) => {
    return fetcher.put(`/result/${resultid}/edit`, {result}).then(res => res.data)
}

export const fetchUserResults = (userId) => {
    return fetcher.get(`/results/${userId}`).then(res => res.data)
}

export const fetchMatchesBySurvey = (surveyId) => {
    return fetcher.get(`/match/${surveyId}/survey`).then(res => res.data)
}
export const fetchMatchesByRegion = (regionId) => {
    return fetcher.get(`/match/${regionId}/region`).then(res => res.data)
}

export const addMatch = (match) => {
    return fetcher.post(`/match`, match).then(res => res.data)
}
export const deleteMatch = (matchId) => {
    return fetcher.delete(`/match/${matchId}`).then(res => res.data)
}
export const updateMatch = (matchId, match) => {
    return fetcher.put(`/match/${matchId}`, match).then(res => res.data)
}

export const addPv = (Pv) => {
    return fetcher.post(`/Pv`, Pv).then(res => res.data)
}
export const deletePv = (PvId) => {
    return fetcher.delete(`/Pv/${PvId}`).then(res => res.data)
}
export const updatePv = (PvId, Pv) => {
    return fetcher.put(`/Pv/${PvId}`, Pv).then(res => res.data)
}

export const fetchPvesByRegion = (regionId) => {
    return fetcher.get(`/Pv/${regionId}/region`).then(res => res.data)
}

export const fetchMatchBySurvey = (surveyId, questionId) => {
    return fetcher.get(`/match/${surveyId}/${questionId}/survey`).then(res => res.data)
}

export const fetchCommentsByRegion = (regionId) => {
    return fetcher.get(`/flags/${regionId}/region`).then(res => res.data)
}

export const createComment = (user, survey, region, title, content) => {

    const comment = {
        user,
        survey,
        region,
        title,
        content
    }

    return fetcher.post(`/flags`, comment).then(res => res.data)
}

export const deleteComment = (commentId) => {
    return fetcher.delete(`/flag/${commentId}`).then(res => res.data)
}

export const deleteUser = (userID) => {
    return fetcher.delete(`/user/${userID}`).then(res => res.data)
}
export const getUsers = () => {
    return fetcher.get(`/users`).then(res => res.data)
}

export const cloneSurvey = (survey, regionId) => {
    return fetcher.post(`/surveys/${regionId}/clone`, survey).then(res => res.data)
}


export const deleteRecord = (recordId) => {
    return fetcher.delete(`/record/${recordId}`).then(res => res.data)
}
export const deleteSurveyHard = (surveyId) => {
    return fetcher.delete(`/survey/${surveyId}`).then(res => res.data)
}

export const updateUser = (userID, userData) => {
    return fetcher.put(`/user/${userID}`, userData).then(res => res.data)
}

export const editRegion = (regionId, region) => {
    return fetcher.put(`/regions/${regionId}`, region).then(res => res.data)
}

export const deleteRegion = (regionId) => {
    return fetcher.delete(`/regions/${regionId}`).then(res => res.data)
}

export const getUserDetails = (recordId) => {
    return fetcher.get(`/records/${recordId}/details`).then(res => res.data)
}
export const getFiles = () => {
    return fetcher.get(`/upload`).then(res => res.data)
}

export const updateRecord = (recordId, data) => {
    return fetcher.put(`/records/${recordId}`, data).then(res => res.data)
}

export const uploadFile = (file, name, id) => {

    let formData = new FormData()
    formData.append('image', file, name)

    /*	fetcher.headers =
            {
            'Authorization': undefined,
            'Accept' : 'application/json'
            }*/

    return fetcher.post(`/upload?fileName=${name}&_id=${id}`, formData).then(res => res.data)

}
export const uploadNewFile = (file, name) => {

    let formData = new FormData()
    formData.append('image', file, name)

    /*	fetcher.headers =
            {
            'Authorization': undefined,
            'Accept' : 'application/json'
            }*/

    return fetcher.post(`/upload?fileName=${name}&_id=${null}`, formData).then(res => res.data)

}
export const deleteFile = (id) => {

    return fetcher.delete(`/upload/${id}`).then(res => res.data)

}

export const fetchRecordsStats = (regionId = null) => {
    return fetcher.get(`/stats/records?idRegion=${regionId}`).then(res => res.data)
}

export const fetchRecordStatsByDate = (regionId = null) => {
    return fetcher.get(`/statsByDate/records?idRegion=${regionId}`).then(res => res.data)
}

export const fetchRecordStatsByAge = (regionId = null) => {
    return fetcher.get(`/statsAge/result?idRegion=${regionId}`).then(res => res.data)
}

export const fetchRecordStatsByClosure = (regionId = null) => {
    return fetcher.get(`/stats/closed/records?idRegion=${regionId}`).then(res => res.data)
}
