import * as api from '../api'
import * as types from '../constants/action-types'

export const loginRequest = (email, password) => dispatch => {
	dispatch({
		type: types.LOGIN_REQUEST,
		payload: {
			email,
			password
		}
	})
	return api.login(email, password)
}

export const loginRequestSuccess = (res) => (dispatch) => {
	dispatch({
		type: types.LOGIN_REQUEST_SUCCESS,
		payload: res
	})
}

export const loginRequestFail = (err) => ({
	type: types.LOGIN_REQUEST_FAILED,
	payload: err
})

export const userRequestLogout = () => {
	return {
		type: types.LOGOUT,
		payload: {}
	}
}

// export const fetchCurrentUserRequest = () => dispatch => {
// 	dispatch({
// 		type: 'CURRENT_USER_REQUEST'
// 	})
// 	return api.fetchCurrentUser()
// }

// export const fetchCurrentUserRequestSuccess = (res) => ({
// 	type: 'CURRENT_USER_REQUEST_SUCCESS',
// 	payload: res
// })

// export const fetchCurrentUserRequestFail = (prevPath) => ({
// 	type: 'CURRENT_USER_REQUEST_FAIL',
// 	payload: prevPath
// })
