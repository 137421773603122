import * as actionTypes from '../constants/action-types'

export default function (state = { isSuccess: false, isLoading: false }, action) {
	switch (action.type) {
		case actionTypes.REGISTER_REQUEST:
			return {
				...state,
				isLoading: true,
				isSuccess: false
			}

		case actionTypes.REGISTER_REQUEST_SUCCESS:
			return {
				...state,
				isLoading: false,
				isSuccess: true
			}

		case actionTypes.REGISTER_REQUEST_FAIL:
			return {
				...state,
				isLoading: false,
				isSuccess: false,
				err: action.payload
			}

		default:
			return state;
	}
}
