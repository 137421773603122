import * as api from '../api'
import {FETCH_RECORDS_REQUEST , FETCH_RECORDS_REQUEST_SUCCESS , FETCH_RECORDS_REQUEST_FAIL, PAGINATE_REQUESTS} from "../constants/action-types";

export const fetchRecordsRequest = (role, regionId) => dispatch => {
    dispatch({
        type: FETCH_RECORDS_REQUEST
    })

    return api.fetchRecords(role, regionId).then(res => {
        dispatch({
            type: FETCH_RECORDS_REQUEST_SUCCESS,
            payload: res
        })
    }).catch(err => {
        dispatch({
            type: FETCH_RECORDS_REQUEST_FAIL,
            payload: err
        })
    })
}

export const paginateRecords = records => dispatch => {
    dispatch({
        type: PAGINATE_REQUESTS,
        payload: records
    })
}
