
import { connect } from 'react-redux'
import React, { Component } from 'react'
import { loginRequest, loginRequestSuccess, loginRequestFail } from '../actions'
import { Form, Icon, Input, Button, Checkbox, notification } from 'antd'

import beder from '../assets/logo.png'
import joussour from '../assets/joussour.png'

import { Redirect } from 'react-router-dom'

const loginImageURL = 'https://images.unsplash.com/photo-1516149893016-813d9a01d5d3?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1080&fit=max&ixid=eyJhcHBfaWQiOjF9'

class LoginPageV2 extends Component {

	handleSubmit = e => {
		e.preventDefault()

		const { form, login } = this.props

		form.validateFields((err, values) => {
			if (err)
				return

				login(values.email, values.password)
					.catch(error => {
						notification['error']({
							message: 'خطأ في تسجيل الدخول',
							description: 'يرجى التحقق من بريدك الإلكتروني وكلمة المرور'
						})
					})
		})
	}

	render() {

		const { isLoading, user } = this.props
		const { getFieldDecorator } = this.props.form

		if (user) {
			if (user.role === 'super_admin') {
				return <Redirect to={'/app/stats'} />
			} else {
				return <Redirect to={`/app/regions/${user.region}/records`} />
			}
		}

		return (
			<div
				style={{
					display: 'flex',
					height: '100vh'
				}}
			>
				<div
					style={{
						flex: 3,
						backgroundImage: `linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%), url(${loginImageURL})`,
						backgroundRepeat: 'no-repeat',
						backgroundSize: 'cover',
						backgroundPositionX: 'center',
						backgroundBlendMode: 'multiply',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center'
					}}
				>
					<p
						style={{
							fontFamily: 'Tajawal',
							fontSize: '80px',
							marginTop: 20,
							color: 'white',
							position: 'absolute',
							top: 0,
							left: 40,
							fontWeight: 'bold'
						}}
					>
						جسور
					</p>
				</div>

				<div
					style={{
						flex: 1.5,
						backgroundColor: 'white',
						zIndex: '99',
						boxShadow: '-11px 0px 10px -12px rgba(0,0,0,0.49)',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						flexDirection: 'column'
					}}
				>
					<div
						style={{
							height: '10%',
							width: '100%',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							flexDirection: 'row'
						}}
					>
						<img
							src={beder}
							style={{
								height: 80
							}}
						/>
						<img
							src={joussour}
							style={{
								height: 80
							}}
						/>
					</div>

					<div
						style={{
							height: '20%',
							width: '100%',
							padding: 50
						}}
					>
						<Form onSubmit={this.handleSubmit.bind(this)} className="login-form">
							<Form.Item>
								{getFieldDecorator('email', {
									rules: [{ required: true, message: 'يرجى إدخال البريد الإلكتروني الخاص بك' }],
								})(
									<Input
										disabled={isLoading}
										size='large'
										prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
										placeholder="البريد الإلكتروني"
									/>,
								)}
							</Form.Item>
							<Form.Item>
								{getFieldDecorator('password', {
									rules: [{ required: true, message: 'يرجى إدخال كلمة المرور الخاصة بك' }],
								})(
									<Input.Password
										disabled={isLoading}
										size='large'
										prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
										placeholder="كلمة السر"
									/>,
								)}
							</Form.Item>

							<Button
								block
								loading={isLoading}
								size='large'
								type="primary"
								htmlType="submit"
							>
								تسجيل الدخول
							</Button>
						</Form>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		isLoading: state.auth.isLoading,
		user: state.auth.user,
		error: state.auth.error
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		login: (email, password) => {
			return dispatch(loginRequest(email, password)).then(res => {
				if (res) {
					dispatch(loginRequestSuccess(res))
					return Promise.resolve(res)
				} else {
					dispatch(loginRequestFail(401))
					return Promise.reject(401)
				}
			}).catch(err => {
				dispatch(loginRequestFail(err))
				return Promise.reject(err)
			})
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(LoginPageV2))