import './index.css'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import configureStore from './configureStore'
import registerServiceWorker from './registerServiceWorker'
import { PersistGate } from "redux-persist/integration/react"
import { BrowserRouter } from 'react-router-dom'
import App from './App'

import 'antd/dist/antd.css'

const { persistor, store } = configureStore()

ReactDOM.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor} >
			<BrowserRouter>
				<App/>
			</BrowserRouter>
		</PersistGate>
	</Provider>
	, document.getElementById('root')
)

registerServiceWorker()