import React, {useState, useEffect} from 'react'

import {
    Card,
    Drawer,
    Timeline,
    Icon,
    Spin,
    Button,
    message,
    Popconfirm,
    Popover, Menu,
    Dropdown,
    Descriptions,
    Divider,
    Modal
} from 'antd'

import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {fetchRecordsRequest} from '../actions'

import Colors from '../constants/colors'
import {fetchRecordResults, deleteRecord, getUsers, updateRecord,} from '../api'
import {mapFicheEtatToString} from '../util'
import {Link, withRouter} from 'react-router-dom'
import NextNode from './NextNode';

import moment from 'moment'
import 'moment/locale/ar-tn'

moment.locale('ar-tn')

function Record({record, all_surveys, currentUser, match, history, fetchRecords, regions, postDelete}) {

    const [drawerVisible, setDrawerVisible] = useState(false)
    const [results, setResults] = useState([])
    const [surveysByIds, setSurveysByIds] = useState([])
    const [loading, setLoading] = useState(true)
    const [users, setUsers] = useState([])

    const compare = (a, b) => {
        if (b.survey.type && a.survey.type && (parseInt(a.survey.type[a.survey.type.length - 1]) < parseInt(b.survey.type[a.survey.type.length - 1]))) {
            return -1
        } else if (
            b.survey.type && a.survey.type &&
            (parseInt(a.survey.type[a.survey.type.length - 1]) > parseInt(b.survey.type[b.survey.type.length - 1]))
        ) {
            return 1
        } else
            return 0
    }


    const closeRecord = (type) => {

        let rec = record
        rec.status = {
            raison: type,
            user: currentUser,
            closed: true
        }
        updateRecord(record._id, rec)
    }

    useEffect(() => {
        fetchRecordResults(record._id)
            .then(json => {
                setResults(json.sort((a, b) => compare(a, b)))
                setTimeout(() => setLoading(false), 500)
            })

        getUsers().then(res => {
            setUsers(res)
        })

    }, [])

    let etat = record ? record.etat : undefined

    let nextSurvey = undefined
    let nextSurvey2 = undefined

    let lastSurvey = undefined
    let BeforeLastSurvey = undefined

    if (etat) {
        etat = parseInt(etat[etat.length - 1])
        nextSurvey = all_surveys.find(survey => survey.type === `fiche${etat + 1}`)
        nextSurvey2 = all_surveys.find(survey => survey.type === `fiche${etat + 2}`)
        lastSurvey = results[results.length - 1]
        BeforeLastSurvey = results[results.length - 2]
    }


    const changeStatus = () => {
    }

    const {confirm} = Modal

    function showConfirm(title, type) {
        confirm({
            title: title,
            cancelText: 'لا',
            okText: 'نعم',
            onOk() {
                closeRecord(type)
                setDrawerVisible(false)
            },
            onCancel() {
            },
        });
    }

    const menu = (
        <Menu>
            <Menu.Item>
                <Button onClick={() => showConfirm('من طرف فريق العمل الميداني', 1)} type="primary" block>
                    من طرف فريق العمل الميداني
                </Button>
            </Menu.Item>
            <Menu.Item>
                <Button onClick={() => showConfirm('من طرف عضو مجلس جسور', 2)} type="primary" block>
                    من طرف عضو مجلس جسور
                </Button>
            </Menu.Item>
            <Menu.Item>
                <Button onClick={() => showConfirm('الطرف المعني لم يعد موافق', 3)} type="primary" block>
                    الطرف المعني لم يعد موافق
                </Button>
            </Menu.Item>
        </Menu>
    );
    return (
        <>
            <Card
                type='inner'
                title={record.numFolder  }
                style={{
                    marginLeft: 16,
                    marginBottom: 16,
                    width: 300,
                    display: 'flex',
                    flexDirection: 'column'
                }}
                bodyStyle={{
                    flex: 1,
                    textAlign: 'right'
                }}
                extra={  record.status && record.status.closed ? <> <p>    {moment(record.updatedAt).calendar()} </p> <Icon type="lock" /></> : moment(record.updatedAt).calendar() }
                actions={
                    [
                        currentUser.role === 'super_admin' || currentUser.role === 'admin'
                            ? <Popconfirm
                                title="هل أنت متأكد من حذف الملف؟"
                                onConfirm={(e) => {
                                    e.stopPropagation()

                                    deleteRecord(record._id)
                                        .then(res => {
                                            fetchRecords(currentUser.role, match.params.id)
                                            message.success(' تم الحذف بنجاح ')
                                            if (postDelete) {
                                                postDelete()
                                            }
                                        })
                                }}
                                onCancel={() => {
                                    message.error('الغاء الحذف')
                                }}
                                okText="نعم"
                                cancelText="لا"
                            >
                                <Button
                                    type='danger'
                                >
                                    حذف الملف
                                </Button>
                            </Popconfirm>

                            : null
                        ,
                        <Button
                            type='default'
                            onClick={() => setDrawerVisible(true)}
                        >
                            التفاصيل
                        </Button>
                    ]
                }
            >
                <p>{mapFicheEtatToString(record.etat)}</p>
                <p>عدد النماذج المنجزة: {record.results.length}</p>
            </Card>

            <Drawer
                title={record.numFolder}
                visible={drawerVisible}
                placement='left'
                onClose={() => setDrawerVisible(false)}
                width={520}
                destroyOnClose
            >
                {
                    loading

                        ? <div
                            style={{
                                flex: 1,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <Spin size='large'/>
                        </div>

                        : <Timeline
                            mode='alternate'
                            pending={results.length < 8}
                            pendingDot={
                                <NextNode
                                    lastSurvey={lastSurvey}
                                    all_surveys={all_surveys}
                                    record={record}
                                    results={results}
                                    currentUser = {currentUser}
                                    closeDrawer={(close)=>{setDrawerVisible(close)}}
                                />
                            }
                        >

                            {
                                results.map(item => {
                                    if (
                                        (item.survey.type === 'fiche0' && currentUser.role !== 'panel')
                                        || (item.survey.type === 'fiche1' && currentUser.role !== 'panel')
                                        || (item.survey.type === 'fiche2' && currentUser.role !== 'jeune')
                                        || (item.survey.type === 'fiche3' && currentUser.role !== 'jeune'
                                        && currentUser.role !== 'panel')
                                        || (item.survey.type === 'fiche4' && currentUser.role !== 'jeune'
                                        && currentUser.role !== 'panel')
                                        || (item.survey.type === 'fiche5' && currentUser.role !== 'jeune')
                                        || (item.survey.type === 'fiche6' && currentUser.role !== 'jeune')
                                        || (item.survey.type === 'fiche7' && currentUser.role !== 'jeune')
                                    ) {
                                        return (
                                            <Timeline.Item
                                                key={`${item._id}`}
                                                style={{
                                                    fontFamily: 'Tajawal',
                                                    fontSize: 15
                                                }}
                                                dot={<Icon type={!item.draft ? 'check-circle' : 'clock-circle-o'}
                                                           theme='filled' style={{
                                                    color: !item.draft ? Colors.green
                                                        : Colors.red
                                                    , fontSize: 16
                                                }}/>}
                                            >
                                                <Popover
                                                    trigger={'hover'}
                                                    placement={'rightTop'}
                                                    title={''}
                                                    content={
                                                        <div>
                                                            <p style={{textAlign: 'right'}}> {users.find(a => a._id === item.user) ? users.find(a => a._id === item.user).name : null} : المستخدم</p>
                                                            <Divider/>
                                                            <p style={{textAlign: 'right'}}>أنشئت في: {moment(item.createdAt).calendar()}</p>
                                                            <Divider/>
                                                            <p style={{textAlign: 'right'}}>تم التحديث في: {moment(item.updatedAt).calendar()}</p>
                                                        </div>
                                                    }
                                                >
                                                    {
                                                        !item.draft && (item.user !== currentUser._id || currentUser.role !== 'super_admin' || currentUser.role !== 'admin') ?
                                                            <Link
                                                                key={item._id}
                                                                to={{
                                                                    pathname: `${match.url}/${record._id}/surveys/${item.survey._id}/results`,
                                                                    state: {
                                                                        result: item.result,
                                                                        survey: item.survey,
                                                                        owner: item.user,
                                                                        draft: item.draft,
                                                                        _id: item._id
                                                                    }
                                                                }}
                                                            >
                                                                {mapFicheEtatToString(item.survey.type)}
                                                            </Link> :
                                                            (item.user === currentUser._id || currentUser.role === 'super_admin' || currentUser.role === 'admin') ?
                                                                <Link
                                                                    key={item._id}
                                                                    to={{
                                                                        pathname: `${match.url}/${record._id}/surveys/${item.survey._id}/results`,
                                                                        state: {
                                                                            result: item.result,
                                                                            survey: item.survey,
                                                                            owner: item.user,
                                                                            draft: item.draft,
                                                                            _id: item._id
                                                                        }
                                                                    }}
                                                                >
                                                                    {mapFicheEtatToString(item.survey.type)}
                                                                </Link> :
                                                                mapFicheEtatToString(item.survey.type)
                                                    }
                                                </Popover>


                                            </Timeline.Item>
                                        )
                                    } else if (item.user === currentUser._id || currentUser.role === 'super_admin' || currentUser.role === 'admin') {
                                        return (
                                            <Timeline.Item
                                                key={`${item._id}`}
                                                style={{
                                                    fontFamily: 'Tajawal',
                                                    fontSize: 15
                                                }}
                                                dot={<Icon type='clock-circle-o'
                                                           style={{fontSize: 16, color: Colors.red}}/>}
                                            >
                                                <Popover
                                                    trigger={'hover'}
                                                    placement={'rightTop'}
                                                    title={''}
                                                    content={
                                                        <div>
                                                            <p>المستخدم: {item.user}</p>
                                                            <Divider/>
                                                            <p>أنشئت في: {moment(item.createdAt).calendar()}</p>
                                                            <Divider/>
                                                            <p>تم التحديث في: {moment(item.updatedAt).calendar()}</p>
                                                        </div>
                                                    }
                                                >
                                                    <Link
                                                        key={item._id}
                                                        to={{
                                                            pathname: `${match.url}/${record._id}/surveys/${item.survey._id}/results`,
                                                            state: {
                                                                result: item.result,
                                                                survey: item.survey,
                                                                owner: item.user,
                                                                draft: item.draft,
                                                                _id: item._id
                                                            }
                                                        }}
                                                    >
                                                        {mapFicheEtatToString(item.survey.type)}
                                                    </Link>
                                                </Popover>
                                            </Timeline.Item>
                                        )
                                    }
                                })
                            }

                        </Timeline>
                }

                {record.status && !record.status.closed && currentUser.role !== 'jeune ' ?
                    <div
                        style={{
                            position: 'absolute',
                            left: 0,
                            bottom: 0,
                            width: '100%',
                            borderTop: '1px solid #e9e9e9',
                            padding: '10px 16px',
                            background: '#fff',
                            textAlign: 'right',
                        }}
                    >

                        <Dropdown trigger={['click']} overlay={menu} placement="topCenter">
                            <Button type="danger" block>
                                غلق الملف
                            </Button>
                        </Dropdown>

                    </div> : null
                }

            </Drawer>
        </>
    )
}


const mapStateToProps = (state) => ({
        regions: state.regions.regions,
        currentUser: state.auth.user,
    }
)

function mapDispatchToProps(dispatch) {
    return {
        fetchRecords: bindActionCreators(fetchRecordsRequest, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Record))
