import { combineReducers } from 'redux'
import * as actionTypes from '../constants/action-types'

export const fetchReducer = (state = { survey: { id: '', questions: [] }, isLoading: false, error: null }, action) => {
	switch (action.type) {
		case actionTypes.FETCH_SURVEY_REQUEST:
			return {
				...state,
				isLoading: true,
				error: ''
			}
		case actionTypes.FETCH_SURVEY_REQUEST_SUCCESS:
			return {
				survey: action.payload,
				isLoading: false,
				error: ''
			}
		case actionTypes.FETCH_SURVEY_REQUEST_FAIL:
			return {
				...state,
				isLoading: false,
				error: action.payload
			}
		default:
			return state
	}
}

export const submitReducer = (state = { isSuccess: false, isLoading: false }, action) => {
	switch (action.type) {
		case actionTypes.SUBMIT_SURVEY:
			return {
				...state,
				isLoading: true
			}
		case actionTypes.SUBMIT_SURVEY_SUCCESS:
			return {
				isLoading: false,
				isSuccess: true
			}
		default:
			return state
	}
}

export default combineReducers({
	fetchSurvey: fetchReducer,
	submitSurvey: submitReducer
})

export const getSurvey = (state) => state.fetchSurvey.survey

export const getFetchStatus = (state) => {
	return state.fetchSurvey.isLoading
}

export const getFetchError = (state) => {
	return state.fetchSurvey.error
}

export const getSubmitStatus = (state) => {
	return state.submitSurvey.isSuccess
}