import React, { useState, useEffect } from 'react'
import { baseUri, getFiles, uploadFile, Back, uri } from '../api'

import Document from './Document'
import AddDocument from './AddDocument'

import { connect  } from 'react-redux'

function Documents (props) {

	const [documents, setDocuments] = useState([])
	// TODO must pass title and file from the collection and check if the user is the admin

	useEffect(() => {
		loadDocs()
	}, [])

	const loadDocs = () => {

		getFiles().then((res)=>{
			console.log('res after  delete')
			setDocuments(res)
		})
	}
	/*	const loadDocs = () => {
		Back.database().ref('pdfs/').once('value', (snapshot) => {
			const ds = []

			snapshot.forEach(doc => {
				ds.push({
					id: doc.key,
					...doc.val()
				})
			})

			setDocuments(ds)
		})
	}*/

	return (
		<>
			{
				documents.map(doc => (
					// id
					// URL
					// title
					<Document
						key={`${doc._id}`}
						document={doc}
						refresh={() => loadDocs()}
					/>
				))
			}

			{documents.length <3 ? 	<AddDocument
				admin={props.user.role === 'super_admin'}
				refresh={() => loadDocs()}
			/> : null}

		</>
	)
}

function mapStateToProps (state) {
	return {
		user: state.auth.user
	}
}

export default connect(mapStateToProps, null)(Documents)
