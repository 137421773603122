
const Colors = {
    turquoise: '#63CADE',
    dark_blue: '#394950',
    light_grey: '#EFF1F4',
    white: '#FFFFFF',
    dark_blue_active: '#324046',
    wraning: '#FFC635',
    green: '#23C61F',
    red: '#F81919'
}

export default Colors