/* global location */
/* eslint no-restricted-globals: ["off", "location"] */

import React, { Component } from 'react'

import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Button, Divider, Table } from 'semantic-ui-react'
import { fetchRecordResults, deleteRecord } from '../api/index'
import { Input, Popconfirm, message} from 'antd'

class RecordDetailsModal extends Component {

	constructor(props) {
		super(props)

		this.state = {
			surveysByIds: [],
			loading: true,
			results: []
		}
	}

	componentDidMount() {
		fetchRecordResults(this.props.record._id)
			.then(json => {
				this.setState({
					results: json.sort((a, b) => this.compare(a, b)),
					loading: false
				})
			})
	}

	compare(a, b) {
		if (b.survey.type && a.survey.type && (parseInt(a.survey.type[a.survey.type.length - 1]) < parseInt(b.survey.type[a.survey.type.length - 1]))) {
			return -1
		} else if (
			b.survey.type && a.survey.type &&
			(parseInt(a.survey.type[a.survey.type.length - 1]) > parseInt(b.survey.type[b.survey.type.length - 1]))
		) {
			return 1
		} else
			return 0
	}

	render() {

		let etat = this.props.record ? this.props.record.etat : undefined
		let nextSurvey = undefined
		let nextSurvey2 = undefined
		let lastSurvey = undefined
		let BeforeLastSurvey = undefined

		if (etat) {
			etat = parseInt(etat[etat.length - 1])
			nextSurvey = this.props.all_surveys.find(survey => survey.type === `fiche${etat + 1}`)
			nextSurvey2 = this.props.all_surveys.find(survey => survey.type === `fiche${etat + 2}`)
			lastSurvey = this.state.results[this.state.results.length - 1]
			BeforeLastSurvey = this.state.results[this.state.results.length - 2]

		}




		return (
			<div style={{
				direction: 'rtl'
			}}>
				<Divider />

				<Table celled>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>إستمارة</Table.HeaderCell>
							<Table.HeaderCell>الحالة</Table.HeaderCell>
							<Table.HeaderCell />
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{
							this.state.results.sort((a, b) => this.compare(a, b)).map((item) => {
								// if not draft
								if ((!item.draft && item.survey.type === 'fiche0' && this.props.currentUser.role !== 'panel')
									|| (!item.draft && item.survey.type === 'fiche1' && this.props.currentUser.role !== 'panel')
									|| (!item.draft && item.survey.type === 'fiche2' && this.props.currentUser.role !== 'jeune')
									|| (!item.draft && item.survey.type === 'fiche3' && this.props.currentUser.role !== 'jeune' && this.props.currentUser.role !== 'panel')
									|| (!item.draft && item.survey.type === 'fiche4' && this.props.currentUser.role !== 'jeune' && this.props.currentUser.role !== 'panel')
									|| (!item.draft && item.survey.type === 'fiche5' && this.props.currentUser.role !== 'jeune')
									|| (!item.draft && item.survey.type === 'fiche6' && this.props.currentUser.role !== 'jeune')
									|| (!item.draft && item.survey.type === 'fiche7' && this.props.currentUser.role !== 'jeune')

								) {
									return (
										<Table.Row key={item._id}>
											<Table.Cell>
												{
													item.survey.type === 'fiche0' ?
														'شبكة ملاحظة السلوكيات المتعلقة بالهشاشة للتطرف العنيف'
														:
														item.survey.type === 'fiche1' ?
															'جدول ملخص لحالة الهشاشة'
															: item.survey.type === 'fiche2' ?
																'استبيان رصد وتحديد وضعيات فردية ومجتمعية هشة'
																: item.survey.type === 'fiche3' ?
																	'سلم تقييم الهشاشة والميل للتطرف العنيف لدى الشباب '
																	: item.survey.type === 'fiche5' ?
																		'تحليل وتحديد حاجيات الشاب/ة الفردية/ الأسرية والمجتمعية للتعهد به/ا ووقايته/ا من التطرف العنيف'
																		: item.survey.type === 'fiche4' ?
																			'جدول تحليل وتحديد عوامل الخطر والوقاية من التطرف العنيف'
																			: item.survey.type === 'fiche6' ?
																				'جذاذة مداولات مجلس جسور'
																				: item.survey.type === 'fiche7' ?
																					'بطاقة المتابعة والتقييم الدوري لمخطط التدخل جسور'
																					: null
												}

											</Table.Cell>
											<Table.Cell>{item.draft ? "مسودة" : "تم تفعيل الاستمارة"}</Table.Cell>
											<Table.Cell>
												<Link
													key={item._id}
													to={{
														pathname: `${this.props.match.url}/${this.props.record._id}/surveys/${item.survey._id}/results`,
														state: {
															result: item.result,
															survey: item.survey,
															owner: item.user,
															draft: item.draft,
															_id: item._id
														}
													}}
												>
													تفاصيل
												</Link>
											</Table.Cell>
										</Table.Row>
									)
								} else if (item.user === this.props.currentUser._id || this.props.currentUser.role === 'super_admin'|| this.props.currentUser.role === 'admin') {

									return (
										<Table.Row key={item._id}>
											<Table.Cell>
												{
													item.survey.type === 'fiche0' ?
														'شبكة ملاحظة السلوكيات المتعلقة بالهشاشة للتطرف العنيف'
														:
														item.survey.type === 'fiche1' ?
															'جدول ملخص لحالة الهشاشة'
															: item.survey.type === 'fiche2' ?
																'استبيان رصد وتحديد وضعيات فردية ومجتمعية هشة'
																: item.survey.type === 'fiche3' ?
																	'  سلم تقييم الهشاشة والميل للتطرف العنيف لدى الشباب'
																	: item.survey.type === 'fiche5' ?
																		'تحليل وتحديد حاجيات الشاب/ة الفردية/ الأسرية والمجتمعية للتعهد به/ا ووقايته/ا من التطرف العنيف'
																		: item.survey.type === 'fiche4' ?
																			'جدول تحليل وتحديد عوامل الخطر والوقاية من التطرف العنيف'
																			: item.survey.type === 'fiche6' ?
																				'جذاذة مداولات مجلس جسور'
																				: item.survey.type === 'fiche7' ?
																					'بطاقة المتابعة والتقييم الدوري لمخطط التدخل جسور'
																					: null
												}

											</Table.Cell>
											<Table.Cell>{item.draft ? "مسودة" : "تم تفعيل الاستمارة"}</Table.Cell>
											<Table.Cell>
												<Link
													key={item._id}
													to={{
														pathname: `${this.props.match.url}/${this.props.record._id}/surveys/${item.survey._id}/results`,
														state: {
															result: item.result,
															survey: item.survey,
															owner: item.user,
															draft: item.draft,
															_id: item._id
														}
													}}
												>
													تفاصيل
												</Link>
											</Table.Cell>
										</Table.Row>
									)
								}
							})
						}
					</Table.Body>
					<Table.Footer fullWidth>
						<Table.Row>
							<Table.HeaderCell colSpan='4' style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'space-between'
							}}>

								{
									lastSurvey && lastSurvey.survey.type === 'fiche0' && !lastSurvey.draft && this.props.all_surveys.find(survey => survey.type === 'fiche1') ?

										<Button
											onClick={() => {
												this.props.history.push(`${this.props.match.url}/${this.props.record._id}/surveys/${this.props.all_surveys.find(survey => survey.type === 'fiche1')._id}`)
											}}
										>
											جدول ملخص للهشاشة
										</Button> :
										lastSurvey && lastSurvey.survey.type === 'fiche1' && !lastSurvey.draft &&
											this.props.all_surveys.find(survey => survey.type === `fiche2`) &&
											this.props.all_surveys.find(survey => survey.type === `fiche3`)
												?
												<div>
													<Button
														onClick={() => {
															this.props.history.push(`${this.props.match.url}/${this.props.record._id}/surveys/${this.props.all_surveys.find(survey => survey.type === 'fiche2')._id}`)
														}}
													>
														استبيان رصد وتحديد وضعيات
													</Button>
													<Button
														onClick={() => {
															this.props.history.push(`${this.props.match.url}/${this.props.record._id}/surveys/${this.props.all_surveys.find(survey => survey.type === 'fiche3')._id}`)
															//this.props.history.push(`/app/regions/:id/surveys/${this.props.all_surveys.find(survey => survey.type === `fiche3`)._id}/${this.props.record._id}`)
														}}
													>
														سلم تقييم الهشاشة
													</Button>
											</div> :

											lastSurvey && lastSurvey.survey.type === 'fiche2' && this.props.all_surveys.find(survey => survey.type === `fiche3`) ?


												<Button
													onClick={() => {
														this.props.history.push(`${this.props.match.url}/${this.props.record._id}/surveys/${this.props.all_surveys.find(survey => survey.type === 'fiche3')._id}`)
														//this.props.history.push(`/app/regions/:id/surveys/${this.props.all_surveys.find(survey => survey.type === `fiche3`)._id}/${this.props.record._id}`)
													}}
												>
													سلم تقييم الهشاشة
												</Button>
												:
											lastSurvey && lastSurvey.survey.type === 'fiche3' &&
											!lastSurvey.draft && this.state.results.find(a=>a.survey.type === 'fiche2') &&
											!this.state.results.find(a=>a.survey.type === 'fiche2').draft &&
											this.props.all_surveys.find(survey => survey.type === `fiche4`) ?
												<Button
													onClick={() => {
														this.props.history.push(`${this.props.match.url}/${this.props.record._id}/surveys/${this.props.all_surveys.find(survey => survey.type === 'fiche4')._id}`)
													}}
												>
													تحديد عوامل الخطر والوقاية
												</Button> :
											lastSurvey && lastSurvey.survey.type === 'fiche3'  && !this.state.results.find(a=>a.survey.type === 'fiche2') &&
											this.props.all_surveys.find(survey => survey.type === `fiche2`) ?
												<Button
													onClick={() => {
														this.props.history.push(`${this.props.match.url}/${this.props.record._id}/surveys/${this.props.all_surveys.find(survey => survey.type === 'fiche2')._id}`)
													}}
												>
													استبيان رصد وتحديد وضعيات
												</Button> :

												lastSurvey && lastSurvey.survey.type === 'fiche4' && !lastSurvey.draft && this.props.all_surveys.find(survey => survey.type === `fiche5`) ?

													<Button
														onClick={() => {
															this.props.history.push(`${this.props.match.url}/${this.props.record._id}/surveys/${this.props.all_surveys.find(survey => survey.type === 'fiche4')._id}`)
															//this.props.history.push(`/app/regions/:id/surveys/${this.props.all_surveys.find(survey => survey.type === `fiche4`)._id}/${this.props.record._id}`)
														}}
													>
														تحديد حاجيات الشاب
													</Button> :

													lastSurvey && lastSurvey.survey.type === 'fiche5' && !lastSurvey.draft &&
									this.props.all_surveys.find(survey => survey.type === `fiche6`)
														?
														<Button
															onClick={() => {
																this.props.history.push(`${this.props.match.url}/${this.props.record._id}/surveys/${this.props.all_surveys.find(survey => survey.type === 'fiche2')._id}`)
																//this.props.history.push(`/app/regions/:id/surveys/${this.props.all_surveys.find(survey => survey.type === `fiche2`)._id}/${this.props.record._id}`)
															}}
														>
															جذاذة مداولات مجلس جسور
														</Button> :
														lastSurvey && lastSurvey.survey.type === 'fiche4' && !lastSurvey.draft && this.props.all_surveys.find(survey => survey.type === `fiche5`) ?

															<Button
																onClick={() => {
																	this.props.history.push(`${this.props.match.url}/${this.props.record._id}/surveys/${this.props.all_surveys.find(survey => survey.type === 'fiche5')._id}`)
																	//this.props.history.push(`/app/regions/:id/surveys/${this.props.all_surveys.find(survey => survey.type === `fiche5`)._id}/${this.props.record._id}`)
																}}
															>
																بطاقة المتابعة والتقييم
															</Button> : null
								}

								{this.props.currentUser.role === 'super_admin' ?

									<Popconfirm
										title="هل أنت متأكد من حذف الملف؟"
										onConfirm={() => {

											deleteRecord(this.props.record._id).then(res => {

											})
											this.props.history.push(`/app/regions`)
											message.success(' تم الحذف بنجاح ')
										}}
										onCancel={() => { message.error('الغاء الحذف') }}
										okText="نعم"
										cancelText="لا"
									>
										<Button
											basic
											color='red'
										>
											حذف الملف
										</Button>
										</Popconfirm>: null
								}


							</Table.HeaderCell>

						</Table.Row>


					</Table.Footer>
				</Table>
			</div>
		)
	}
}

function mapStateToProps(state) {
	return {
		currentUser: state.auth.user
	}
}

export default connect(mapStateToProps, null)(withRouter(RecordDetailsModal))
