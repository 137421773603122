import { combineReducers } from 'redux'

import auth from './auth'
import regions from './regions'
import records from './records'
import create_survey from './create_survey'
import survey from './survey'
import surveys from './surveys'
import register from './register'
import edit_survey from './edit_survey'
import pvs from './pv'
const root = combineReducers({
	auth: auth,
	regions: regions,
	records: records,
	survey: survey,
	surveys: surveys,
	create_survey: create_survey,
	register: register,
	edit: edit_survey,
	pvs : pvs
})

export default root
