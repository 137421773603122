export function mapRoleValueToString (role) {
    switch (role) {

        case 'super_admin':
            return 'مشرف'
            break

        case 'jeune':
            return 'شاب مرافق جسور'
            break

        case 'social':
            return 'أخصّائي إجتماعي'
            break

        case 'psy':
            return 'أخصّائي نفساني'
            break

        case 'panel':
            return 'عضو في مجلس جسور'
            break
        case 'admin':
            return 'مشرف'
            break

        default:
            break
    }
}

export function mapFicheEtatToString (fiche) {
    switch (fiche) {
        case 'fiche0':
            return 'شبكة ملاحظة السلوكيات المتعلقة بالهشاشة للتطرف العنيف'
            break

        case 'fiche1':
            return 'جدول ملخص لحالة الهشاشة'
            break

        case 'fiche2':
            return 'استبيان رصد وتحديد وضعيات فردية ومجتمعية هشة'
            break

        case 'fiche3':
            return 'سلم تقييم الهشاشة والميل للتطرف العنيف لدى الشباب'

            break

        case 'fiche4':
            return 'تحليل وتحديد حاجيات الشاب/ة الفردية/ الأسرية والمجتمعية للتعهد به/ا ووقايته/ا من التطرف العنيف'
            break

        case 'fiche5':
            return ' جدول تحليل وتحديد عوامل الخطر والوقاية من التطرف العنيف'
            break

        case 'fiche6':
            return 'جذاذة مداولات مجلس جسور'
            break

        case 'fiche7':
            return 'بطاقة المتابعة والتقييم الدوري لمخطط التدخل جسور'
            break

        default:
            return fiche
            break
    }
}

export function mapUserDetailToString (detail) {
    switch (detail) {
        case 'age':
            return 'الشريحة العمرية'
            break

        case 'gender':
            return 'الجنس'
            break

        case 'name':
            return 'الاسم'
            break

        case 'place':
            return 'مكان الاقامة الحالي'
            break

        default:
            return detail
            break
    }
}

export function mapStatKeys (key) {
    switch (key) {
        case 'enCours':
            return 'ملف قيد المعالجة'
            break;

        case 'clotures':
            return 'ملف مغلق'
            break;

        case 'finalises':
            return 'ملف منجز'
            break;
    
        default:
            break;
    }
}

export function mapSexString (key) {
    switch (key) {
        case 'male':
            return 'ذكر'
            break;
        case 'female':
            return 'انثى'
            break; 
        default:
            return ''
            break;
    }
}
