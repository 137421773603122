
import React, { useState, useEffect } from 'react'

import { ResponsivePie } from '@nivo/pie'
import { ResponsiveCalendar } from '@nivo/calendar'
import { ResponsiveRadar } from '@nivo/radar'
import { ResponsiveBar } from '@nivo/bar'
import { Card, Spin } from 'antd'
import { fetchRecordsStats, fetchRecordStatsByDate, fetchRecordStatsByAge, fetchRecordStatsByClosure } from '../api'

import { withRouter } from 'react-router'
import { mapStatKeys } from '../util'

import { connect } from 'react-redux'

function RegionStats({ match, records }) {

	const regionId = match.params.id

	const [loading, setLoading] = useState(false)
	const [recordStats, setRecordStats] = useState([])
	const [recordStatsByDate, setRecordStatsByDate] = useState([])
	const [recordStatsByAge, setRecordStatsByAge] = useState([])
	const [sumOfRecords, setSumOfRecords] = useState(0)

	const [recordStatsByClosure, setRecordStatsByClosure] = useState(undefined)

	useEffect(() => {
		async function loadData() {
			const statsByClosure = await fetchRecordStatsByClosure(regionId)
			setRecordStatsByClosure(statsByClosure)
		}

		loadData()

	}, [records])

	useEffect(() => {
		async function loadData() {
			setLoading(true)

			let stats = await fetchRecordsStats(regionId)
			
			let s = 0
			Object.entries(stats).forEach(item => s += item[1])
			setSumOfRecords(s)
			
			const recordStatsByDate = await fetchRecordStatsByDate(regionId)
			const recordStatsByAge = await fetchRecordStatsByAge(regionId)
			const statsByClosure = await fetchRecordStatsByClosure(regionId)
			setRecordStatsByClosure(statsByClosure)

			stats = Object.entries(stats).map(item => {
				return {
					id: mapStatKeys(item[0]),
					label: mapStatKeys(item[0]),
					value: item[1]
				}
			})

			setRecordStats(stats)
			setRecordStatsByDate(recordStatsByDate)
			setRecordStatsByAge(recordStatsByAge)
			setLoading(false)
		}

		loadData()
	}, [])

	if (loading) {
		return (
			<div
				style={{
					flex: 1,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center'
				}}
			>
				<Spin size='large' />
			</div>
		)
	}

	return (
		<div
			style={{
				flex: 1,
				display: 'flex',
				height: '100%',
				flexWrap: 'wrap',
				justifyContent: 'flex-end'
			}}
		>
			<Card
				title={'عدد الملفات حسب التاريخ'}
				hoverable
				style={{
					width: '70%',
					//height: 250,
					marginBottom: 10
				}}
				bodyStyle={{
					width: '100%',
					height: 300
				}}
				headStyle={{
					textAlign: 'right'
				}}
			>
				<ResponsiveCalendar
					data={recordStatsByDate}
					from="2019-01-01" // TODO this must be determined from the api or from the returned results
					to="2019-12-31" // TODO this must be determined from the api or from the returned results
					emptyColor="#eeeeee"
					colors={['#97e3d5', '#a6cee3', '#e8c1a0', '#f47560']}
					margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
					yearSpacing={40}
					monthBorderColor="#ffffff"
					dayBorderWidth={2}
					dayBorderColor="#ffffff"
					legends={[
						{
							anchor: 'bottom-right',
							direction: 'row',
							translateY: 36,
							itemCount: 4,
							itemWidth: 42,
							itemHeight: 36,
							itemsSpacing: 14,
							itemDirection: 'right-to-left'
						}
					]}
				/>
			</Card>

			<Card
				title={'الفئة العمرية للحالات'}
				hoverable
				style={{
					width: '30%',
					marginBottom: 10
				}}
				bodyStyle={{
					height: 300
				}}
				headStyle={{
					textAlign: 'right'
				}}
			>
				<ResponsiveBar
					data={recordStatsByAge}
					keys={['value']}
					indexBy="age"
					margin={{ top: 50, right: 60, bottom: 50, left: 60 }}
					padding={0.3}
					colors={{ scheme: 'paired' }}
					borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
					axisTop={null}
					axisRight={null}
					
					axisBottom={{
						tickSize: 5,
						tickPadding: 5,
						tickRotation: 0,
						legend: "الفئة العمرية",
						legendPosition: 'middle',
						legendOffset: 32
					}}
					axisLeft={{
						tickSize: 5,
						tickPadding: 5,
						tickRotation: 0,
						legend: 'عدد الحالات',
						legendPosition: 'middle',
						legendOffset: -40
					}}
					labelSkipWidth={12}
					labelSkipHeight={12}
					labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
					tooltip={({ id, value, color }) => (
						// <strong style={{color: 'black'}}>
						// 	الفئة العمرية : {value} {`${Math.round(value / sumOfRecords)}%`}
						// </strong>
						<strong style={{color: 'black'}}>
							{`${((value / sumOfRecords) * 100).toFixed(2)}%`}
						</strong>
					)}
					animate={true}
					motionStiffness={90}
					motionDamping={15}
				/>
			</Card>

			<Card
				title={'عدد الملفات المغلقة'}
				hoverable
				style={{
					width: '50%'
				}}
				bodyStyle={{
					height: 300
				}}
				headStyle={{
					textAlign: 'right'
				}}
			>
				<ResponsiveRadar
					data={[
						{
							"closure": "من طرف فريق العمل الميداني",
							"عدد الملفات المغلقة": recordStatsByClosure && recordStatsByClosure.r1 ? recordStatsByClosure.r1 : 0
						},
						{
							"closure": "من طرف عضو مجلس جسور",
							"عدد الملفات المغلقة": recordStatsByClosure && recordStatsByClosure.r2 ? recordStatsByClosure.r2 : 0
						},
						{
							"closure": "الطرف المعني لم يعد موافق",
							"عدد الملفات المغلقة": recordStatsByClosure && recordStatsByClosure.r3 ? recordStatsByClosure.r3 : 0
						}
					  ]}
					keys={[ 'عدد الملفات المغلقة' ]}
					indexBy="closure"
					maxValue="auto"
					margin={{ top: 70, right: 80, bottom: 40, left: 80 }}
					curve="linearClosed"
					borderWidth={2}
					borderColor={{ from: 'color' }}
					gridLevels={5}
					gridShape="circular"
					gridLabelOffset={36}
					enableDots={true}
					dotSize={10}
					dotColor={{ theme: 'background' }}
					dotBorderWidth={2}
					dotBorderColor={{ from: 'color' }}
					enableDotLabel={true}
					dotLabel="value"
					dotLabelYOffset={-12}
					colors={{ scheme: 'nivo' }}
					fillOpacity={0.25}
					blendMode="multiply"
					animate={true}
					motionStiffness={90}
					motionDamping={15}
					isInteractive={true}
					legends={[
						{
							anchor: 'top-left',
							direction: 'column',
							translateX: -50,
							translateY: -40,
							itemWidth: 80,
							itemHeight: 20,
							itemTextColor: '#999',
							symbolSize: 12,
							symbolShape: 'circle',
							effects: [
								{
									on: 'hover',
									style: {
										itemTextColor: '#000'
									}
								}
							]
						}
					]}
				/>
			</Card>

			<Card
				title={'الملفات حسب الحالة'}
				hoverable
				style={{
					width: '50%'
				}}
				bodyStyle={{
					height: 300
				}}
				headStyle={{
					textAlign: 'right'
				}}
			>
				<ResponsivePie
					data={recordStats}
					margin={{ top: 40, right: 45, bottom: 80, left: 45 }}
					innerRadius={0.5}
					padAngle={0.7}
					cornerRadius={3}
					colors={{ scheme: 'pastel1' }}
					borderWidth={1}
					borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
					radialLabelsSkipAngle={10}
					radialLabelsTextXOffset={6}
					radialLabelsTextColor="#333333"
					radialLabelsLinkOffset={0}
					radialLabelsLinkDiagonalLength={16}
					radialLabelsLinkHorizontalLength={24}
					radialLabelsLinkStrokeWidth={1}
					radialLabelsLinkColor={{ from: 'color' }}
					slicesLabelsSkipAngle={10}
					slicesLabelsTextColor="#333333"
					animate={true}
					motionStiffness={90}
					motionDamping={15}
					tooltip={({ id, value, color }) => (
						<strong style={{color: 'black'}}>
							{`${((value / sumOfRecords) * 100).toFixed(2)}%`}
						</strong>
					)}
					defs={[
						{
							id: 'dots',
							type: 'patternDots',
							background: 'inherit',
							color: 'rgba(255, 255, 255, 0.3)',
							size: 4,
							padding: 1,
							stagger: true
						},
						{
							id: 'lines',
							type: 'patternLines',
							background: 'inherit',
							color: 'rgba(255, 255, 255, 0.3)',
							rotation: -45,
							lineWidth: 6,
							spacing: 10
						}
					]}
					fill={[
						{
							match: {
								id: 'ruby'
							},
							id: 'dots'
						},
						{
							match: {
								id: 'c'
							},
							id: 'dots'
						},
						{
							match: {
								id: 'go'
							},
							id: 'dots'
						},
						{
							match: {
								id: 'python'
							},
							id: 'dots'
						},
						{
							match: {
								id: 'scala'
							},
							id: 'lines'
						},
						{
							match: {
								id: 'lisp'
							},
							id: 'lines'
						},
						{
							match: {
								id: 'elixir'
							},
							id: 'lines'
						},
						{
							match: {
								id: 'javascript'
							},
							id: 'lines'
						}
					]}
					legends={[
						{
							anchor: 'bottom',
							direction: 'row',
							translateY: 56,
							itemWidth: 100,
							itemHeight: 18,
							itemTextColor: '#999',
							symbolSize: 18,
							symbolShape: 'circle',
							effects: [
								{
									on: 'hover',
									style: {
										itemTextColor: '#000'
									}
								}
							]
						}
					]}
				/>
			</Card>

			
		</div>
	)
}

function mapStateToProps (state) {
	return {
		records: state.records.records
	}
}

export default connect(mapStateToProps, null)(withRouter(RegionStats))