import React from 'react'
//import './UserSurveysPage.css'
import { connect } from 'react-redux'

import RegionsList from '../components/RegionsList';

import { withRouter } from 'react-router'
//import { Path } from '../routes';

import {
	Button,
	Drawer,
	Icon,
	Form,
	Row,
	Col,
	Input,
	Card,
	Popconfirm,
	message,
	Skeleton,
	Spin,
	Pagination
} from 'antd'

import NewRegionForm from '../components/NewRegionForm'
import { fetchAllRecords, searchRecords, fetchUserSurveys } from '../api'
import { mapFicheEtatToString } from '../util'
import Record from './Record'

import { paginateRecords } from '../actions'

const { Search } = Input

class AllRecords extends React.Component {


	constructor(props) {
		super(props)

		this.state = {
			open: false,
			newRegionDrawerVisible: false,
			records: undefined,
			loading: true,
			currentPage: 1,
			firstLoad: true,
			searchMode: false,
			searchTerm: '',
			all_surveys: []
		}
	}


	componentDidMount () {
		this.paginateRecords(6, this.state.currentPage)

		fetchUserSurveys(this.props.currentUser)
			.then(surveys => this.setState({all_surveys: surveys}))
	}

	paginateRecords = async (limit, page) => {
		this.setState({ loading: true })
		const records = await fetchAllRecords(limit, page)
		this.setState({ records: records, loading: false, firstLoad: false })
		this.props.paginateRecords(records.docs)
	}

	searchRecords = async (limit, page, term) => {
		this.setState({ loading: true })
		const records = await searchRecords(limit, page, term)
		this.setState({ records: records, loading: false, firstLoad: false })
		this.props.paginateRecords(records.docs)
	}
	
	renderLoading() {
		return (
			<div
				style={{
					flex: 1,
					height: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center'
				}}
			>
				<Spin size='large' />
			</div>
		)
	}


	render() {

		if (this.state.firstLoad) return this.renderLoading()
		
		return (
			<div
				style={{
					flex: 1,
					overflow: 'auto'
				}}
			>
				
				{/* <RegionsList/> */}
				<div
					style={{
						display: 'flex',
						flexWrap: 'wrap',
						justifyContent: 'flex-end',
						margin: '0 16px'
					}}
				>
					<Spin size='large' spinning={this.state.loading}>
						<>
							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
									paddingTop: 20
								}}
							>
								<Search
									size='large'
									placeholder="البحث عن الملفات"
									onSearch={value => {
										this.setState({searchMode: true, searchTerm: value}, () => {
											this.searchRecords(6, 1, value)
										})
										
									}}
									style={{ width: '50%' }}
								/>
							</div>
							
							<div
								style={{
									display: 'flex',
									flexWrap: 'wrap',
									justifyContent: 'flex-end',
									margin: '16px 16px 0 16px'
								}}
							>
								{
									this.state.records && this.state.records.docs.map(record => (
										<Record
											key={record._id}
											record={record}
											all_surveys={this.state.all_surveys}
											currentUser={this.props.currentUser}
											postDelete={() => this.paginateRecords(6, this.state.currentPage)}
										/>
										// <Card
										// 	key={`${record._id}`}
										// 	style={{
										// 		width: 300,
										// 		margin: 16,
										// 		boxShadow: '0px 6px 19px -8px rgba(140,140,140,1)',
										// 		fontFamily: 'Tajawal',
										// 		fontWeight: 'bold',
										// 		textAlign: 'right',
										// 		cursor: 'pointer'
										// 	}}
										// 	bodyStyle={{
										// 		height: 130
										// 	}}
										// >
										// 	<Skeleton loading={false} active>
										// 		<Card.Meta
										// 			onClick={() => {
										// 				//this.props.history.push(`${match.path}/${region._id}`)
										// 			}}
										// 			title={record.numFolder}
										// 			description={mapFicheEtatToString(record.etat)}
										// 		/>
										// 	</Skeleton>
										// </Card>
									))
								}
							</div>
						</>
					</Spin>

					<div
						style={{
							width: '100%',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center'
						}}
					>
						<Pagination
							defaultCurrent={1}
							total={this.state.records ? this.state.records.totalDocs : 6}
							defaultPageSize={this.state.records ? this.state.records.limit : 6}
							pageSize={this.state.records ? this.state.records.limit : 6}
							current={this.state.records ? this.state.currentPage : 1}
							onChange={page => {
								this.setState({ currentPage: page })
								if (this.state.searchMode) {
									this.searchRecords(6, page, this.state.searchTerm)
								} else {
									this.paginateRecords(6, page)
								}
							}}
						/>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	regions: state.regions.regions,
	isCreated: state.regions.isCreated,
	currentUser: state.auth.user
})

const mapDispatchToProps = dispatch => {
	return {
		paginateRecords: records => dispatch(paginateRecords(records))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AllRecords))