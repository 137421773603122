import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { createSurveyRequest, resetCreateSurvey } from '../actions'
import { Button } from 'antd'

class NewSurvey extends React.Component {
	
	componentDidUpdate(prevProps, prevState) {
		const { isSuccess, newSurvey } = this.props 

		if (isSuccess) {
			this.props.resetCreateSurvey()
			this.props.history.push(`${this.props.match.url}/${newSurvey._id}/edit`)
		}
	}

	render() {
		return (
			<Button
				type='primary'
				size='large'
				icon='file-add'
				style={{
					marginLeft: 12
				}}
				onClick={() => {
					this.props.createSurvey(this.props.currentUser._id, this.props.region)
				}}
			>
				إستمارة جديدة
			</Button>
		)
	}
}

const mapStateToProps = (state) => ({
	currentUser: state.auth.user,
	newSurvey: state.create_survey.survey,
	isLoading: state.create_survey.isLoading,
	isSuccess: !!state.create_survey.survey,
	error: state.create_survey.error
})

const mapDispatchToProps = (dispatch) => {
	return {
		createSurvey: bindActionCreators(createSurveyRequest, dispatch),
		resetCreateSurvey: bindActionCreators(resetCreateSurvey, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NewSurvey))