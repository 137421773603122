
export const LOGIN_REQUEST = "LOGIN_REQUEST"
export const LOGIN_REQUEST_SUCCESS = "LOGIN_REQUEST_SUCCESS"
export const LOGIN_REQUEST_FAILED = "LOGIN_REQUEST_FAILED"
export const LOGOUT = "LOGOUT"

export const FETCH_REGIONS_REQUEST = 'FETCH_REGIONS_REQUEST'
export const FETCH_REGIONS_REQUEST_SUCCESS = 'FETCH_REGIONS_REQUEST_SUCCESS'
export const FETCH_REGIONS_REQUEST_FAIL = 'FETCH_REGIONS_REQUEST_FAIL'
export const CREATE_REGION_REQUEST = 'CREATE_REGION_REQUEST'
export const CREATE_REGION_REQUEST_SUCCESS = 'CREATE_REGION_REQUEST_SUCCESS'
export const CREATE_REGION_REQUEST_FAIL = 'CREATE_REGION_REQUEST_FAIL'
export const FETCH_REGION_REQUEST = 'FETCH_REGION_REQUEST'
export const FETCH_REGION_REQUEST_SUCCESS = 'FETCH_REGION_REQUEST_SUCCESS'
export const FETCH_REGION_REQUEST_FAIL = 'FETCH_REGION_REQUEST_FAIL'

//<---------records----------->
export const FETCH_RECORDS_REQUEST = 'FETCH_RECORDS_REQUEST'
export const FETCH_RECORDS_REQUEST_SUCCESS = 'FETCH_RECORDS_REQUEST_SUCCESS'
export const FETCH_RECORDS_REQUEST_FAIL = 'FETCH_RECORDS_REQUEST_FAIL'
export const PAGINATE_REQUESTS = 'PAGINATE_REQUESTS'
//<--------endRecords----->

//<---------survey----------->
export const SUBMIT_SURVEY = 'SUBMIT_SURVEY'
export const SUBMIT_SURVEY_SUCCESS = 'SUBMIT_SURVEY_SUCCESS'
export const SUBMIT_SURVEY_FAIL = 'SUBMIT_SURVEY_FAIL'
export const FETCH_SURVEY_REQUEST = 'SURVEY_FETCH_SURVEY_REQUEST'
export const FETCH_SURVEY_REQUEST_SUCCESS = 'SURVEY_FETCH_SURVEY_REQUEST_SUCCESS'
export const FETCH_SURVEY_REQUEST_FAIL = 'SURVEY_FETCH_SURVEY_REQUEST_FAIL'
//<---------endSurvey----------->

//<---------surveys----------->
export const FETCH_SURVEYS_REQUEST = 'FETCH_SURVEYS_REQUEST'
export const FETCH_SURVEYS_REQUEST_SUCCESS = 'FETCH_SURVEYS_REQUEST_SUCCESS'
export const FETCH_SURVEYS_REQUEST_FAIL = 'FETCH_SURVEYS_REQUEST_FAIL'
//<---------endSurveys----------->

//<---------create_surveys----------->
export const CREATE_SURVEY_REQUEST = 'CREATE_SURVEY_REQUEST'
export const CREATE_SURVEY_REQUEST_SUCCESS = 'CREATE_SURVEY_REQUEST_SUCCESS'
export const CREATE_SURVEY_REQUEST_FAIL = 'CREATE_SURVEY_REQUEST_FAIL'
export const RESET_CREATE_SURVEY = 'RESET_CREATE_SURVEY'
//<---------end_create_surveys----------->

//<---------register----------->
export const REGISTER_REQUEST = 'REGISTER_REQUEST'
export const REGISTER_REQUEST_SUCCESS = 'REGISTER_REQUEST_SUCCESS'
export const REGISTER_REQUEST_FAIL = 'REGISTER_REQUEST_FAIL'
//<---------end_register----------->


//<---------Update Survey----------->
export const UPDATE_SURVEY_REQUEST = 'UPDATE_SURVEY_REQUEST'
export const UPDATE_SURVEY_REQUEST_SUCCESS = 'UPDATE_SURVEY_REQUEST_SUCCESS'
export const UPDATE_SURVEY_REQUEST_FAIL = 'UPDATE_SURVEY_REQUEST_FAIL'
//<---------End Update Survey----------->

//<---------Delete Survey----------->
export const DELETE_SURVERY_REQUEST =  'DELETE_SURVERY_REQUEST'
export const DELETE_SURVEY_REQUEST_SUCCESS =  'DELETE_SURVEY_REQUEST_SUCCESS'
export const DELETE_SURVEY_REQUEST_FAIL =  'DELETE_SURVEY_REQUEST_FAIL'
//<---------End Delete Survey----------->


//<---------edit Region----------->

export const EDIT_REGION_REQUEST = 'EDIT_CREATE_REGION_REQUEST'
export const EDIT_REGION_REQUEST_SUCCESS = 'EDIT_REGION_REQUEST_SUCCESS'
export const EDIT_REGION_REQUEST_FAIL = 'EDIT_REGION_REQUEST_FAIL'

//<---------end edit Region----------->

//<---------delete Region----------->

export const DELETE_REGION_REQUEST = 'DELETE_REGION_REQUEST'
export const DELETE_REGION_REQUEST_SUCCESS = 'DELETE_REGION_REQUEST_SUCCESS'
export const DELETE_REGION_REQUEST_FAIL = 'DELETE_REGION_REQUEST_FAIL'

//<---------end delete Region----------->

//<---------Region pvs----------->
export const CREATE_PV= 'CREATE_PV'
export const GET_PVS = 'GET_PVS'
export const UPDATE_PV= 'UPDATE_PV'
export const DELETE_PV = 'DELETE_PV'
export const GET_PVS_FAIL= 'GET_PVS_FAIL'



//<---------End Region pvs----------->


//
