import React from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'

import {Link, withRouter} from 'react-router'
//import { Path } from '../routes'
import {Tab, Button, Image, Divider, Segment, Icon, Header, Container, Modal} from 'semantic-ui-react'

import {Form, Col, Row, Card, Input, Popconfirm, message, Spin} from 'antd'

import NewPvModal from "./NewPvModal";

import {fetchPvs} from '../actions';

import 'survey-react/survey.css'
import * as Survey from 'survey-react'
import {deletePv} from "../api";

import model from '../constants/PvModel'

class RegionPv extends React.Component {

    _isMounted = false

    constructor(props) {
        super(props);
        this.state = {
            all_surveys: [],
            loading: true,
            open: false,
            pvs: []
        }
    }

    componentDidMount() {
        this._isMounted = true
        
        this.props.fetchPvs(this.props.match.params.id).then(
            this.setState({
                loading: false
            })
        )

    }

    componentWillReceiveProps(nextProps, nextContext) {


    }

    componentWillUnmount() {
        this._isMounted = false
    }


    render() {

        return (

            this.state.loading
                ? <div
                    style={{
                        flex: 1,
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <Spin size='large'/>
                </div> :
                <div style={{
                    flex: 1,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    flexDirection: 'column',
                    direction: 'rtl'
                }}>

                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginTop: 20
                    }}>
                        <div style={{flexDirection: 'row', display: 'flex'}}>

                            {/*<Button
                                    fluid
                                    content={`إنشاء محضر `}

                                    onClick={() => {alert('new record')}}
                                />*/}
                            <NewPvModal/>

                        </div>
                    </div>

                    <Divider/>

                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        direction: 'rtl',
                        textAlign: 'right',
                    }}>
                        {
                            this.props.pvs ? this.props.pvs.filter(a => a.region === this.props.match.params.id).map(pv => {
                                return (
                                    <Card

                                        key={`${pv._id}`}
                                        title={
                                            <>
                                            <h4>
                                                محضر
                                            </h4>
                                            <Popconfirm
                                                title="هل أنت متأكد من حذف محضر ؟"
                                                onConfirm={() => {
                                                    deletePv(pv._id)
                                                    this.props.fetchPvs(this.props.match.params.id)

                                                    message.success(' تم الحذف بنجاح ')


                                                }}
                                                    onCancel={()=>{message.error('الغاء الحذف')}}
                                                    okText="نعم"
                                                    cancelText="لا"
                                                    >
                                                    <i className="delete icon"></i>
                                                    </Popconfirm>
                                                    </>
                                                }
                                            >
                                                <p

                                                    onClick={() => {
                                                        setTimeout(() => {
                                                            this.survey.data = pv.pv
                                                        }, 2000)
                                                        this.setState({
                                                            open: true
                                                        })
                                                    }}
                                                >{pv.pv.المكان} </p>
                                                <Modal

                                                    size='fullscreen'
                                                    className='fixModal'
                                                    style={{width: '50%'}}
                                                    open={this.state.open}
                                                    onClose={() => this.setState({open: false})}
                                                    title={
                                                        <div style={
                                                            {
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                alignItems: 'baseline',
                                                                justifyContent: 'space-between',
                                                                padding: 10
                                                            }
                                                        }>
                                                            <h3 style={{
                                                                float: 'right'
                                                            }}>تفاصيل</h3>
                                                        </div>
                                                    }
                                                >
                                                    <Modal.Content scrolling>
                                                        {
                                                            <>
                                                                <Survey.Survey
                                                                    model={model}
                                                                    // get a ref from the survey to pull data from it
                                                                    ref={survey => {
                                                                        if (survey && survey.survey) {
                                                                            this.survey = survey.survey
                                                                        }
                                                                    }}
                                                                />
                                                            </>
                                                        }
                                                    </Modal.Content>
                                                </Modal>
                                            </Card>
                                            )
                                            }) : null
                                            }
                                            </div>
                                            < /div>
                                            )
                                        }
}

                            const mapStateToProps = (state) => ({
                                regions: state.regions.regions,
                                isLoading: state.regions.isLoading,
                                loadError: state.regions.error,
                                currentUser: state.auth.user,
                                isCreated: state.regions.isCreated,
                                records: state.records.records,
                                pvs : state.pvs.pvs
                            })


                            const mapDispatchToProps = (dispatch) => {
                                return {
                                fetchPvs:(regionId) => dispatch(fetchPvs(regionId))
                            }
                            }


                            export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RegionPv))
