import React, { Component } from 'react'

import { updateUser, fetchRegions, deleteUser } from '../api'
import { Select, Button, Form, Input, message, Descriptions, Icon, InputNumber, Popconfirm } from 'antd'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { fetchRegionsRequest } from '../actions'
import { mapRoleValueToString, mapSexString } from '../util'

const { Option } = Select

class EditableUserDetails extends Component {

	constructor(props) {
		super(props)

		this.state = {
			editMode: false,
			loading: false
		}
	}
	handleEdit = async (e) => {
		e.preventDefault()

		this.setState({ loading: true })

		const hideLoading = message.loading('En cours', 0)

		this.props.form.validateFieldsAndScroll(async (err, values) => {
			if (!err) {
				try {
					const update = await updateUser(this.props.user._id, values)

					hideLoading()
					message.success('This is a success message', 1)

					this.setState({ loading: false, editMode: false })
					this.props.onEditSuccess()

					// TODO: LOW -> Do not fetch all regions here
					const fetch  = await this.props.fetchRegions()
				} catch(error) {
					this.setState({ loading: false, editMode: false })
					hideLoading()
					message.error('There was an error', 1)
				}
			} else {
				this.setState({ loading: false, editMode: false })
				hideLoading()
				message.error('There was an error', 1)
			}
		})
	}
	render() {

		const { user } = this.props
		const { editMode, loading } = this.state
		const { getFieldDecorator } = this.props.form

		if (editMode) {
			return (
				<div
					style={{
						direction: 'ltr',
						paddingBottom: 50
					}}
				>
					<Form onSubmit={this.handleEdit.bind(this)}>
						<Form.Item label="الاسم">
							{getFieldDecorator('name', {
								rules: [
									{
										required: true,
										message: 'Please input the name!',
									}
								],
								initialValue: user.name
							})(<Input />)}
						</Form.Item>

						<Form.Item label='العمر'>
							{
								getFieldDecorator('age', {
									initialValue: user.age || 25,
									rules: [
										{
											required: true,
											message: 'يرجى إدخال العمر'
										}
									]
								})
									(<InputNumber style={{width: '100%'}} size='large' min={18} max={60} placeholder='العمر' />)
							}
						</Form.Item>

						<Form.Item label="الجنس">
							{getFieldDecorator('gender', {
								initialValue: user.gender || 'male',
								rules: [{ required: true, message: 'يرجى اختيار الجنس' }]
							})(
								<Select
									size='large'
									placeholder='الجنس'
									style={{
										direction: 'rtl',
										fontFamily: 'Tajawal'
									}}
								>
									<Option
										value='male'
										style={{
											fontFamily: 'Tajawal',
											textAlign: 'right'
										}}
									>
										ذكر
									</Option>

									<Option
										value='female'
										style={{
											fontFamily: 'Tajawal',
											textAlign: 'right'
										}}
									>
										انثى
									</Option>
								</Select>,
							)}
						</Form.Item>

						<Form.Item label="البريد الإلكتروني">
							{getFieldDecorator('email', {
								rules: [
									{
										type: 'email',
										required: true,
										message: 'Not valid email!',
									}
								],
								initialValue: user.email
							})(<Input />)}
						</Form.Item>

						<Form.Item label="Password">
							{getFieldDecorator('password', {
								rules: [
									{
										required: true,
										message: 'Please input your password!',
									}
								],
								initialValue: user.password
							})(<Input.Password />)}
						</Form.Item>

						<Form.Item label="الدور">
							{getFieldDecorator('role', {
								initialValue: user.role,
								rules: [{ required: true, message: 'Please select the role!' }]
							})(
								<Select placeholder='الدور'>
									<Option
										value='jeune'
										style={{
											fontFamily: 'Tajawal',
											textAlign: 'right'
										}}
									>
										شاب مرافق جسور
									</Option>
									<Option
										value='social'
										style={{
											fontFamily: 'Tajawal',
											textAlign: 'right'
										}}
									>
											أخصّائي إجتماعي
									</Option>
									<Option
										value='psy'
										style={{
											fontFamily: 'Tajawal',
											textAlign: 'right'
										}}
									>
										أخصّائي نفساني
									</Option>
									<Option
										value='panel'
										style={{
											fontFamily: 'Tajawal',
											textAlign: 'right'
										}}
									>
										عضو في مجلس جسور
									</Option>
								</Select>,
							)}
						</Form.Item>

						<div
							style={{
								position: 'absolute',
								bottom: 0,
								width: '100%',
								borderTop: '1px solid #e8e8e8',
								padding: '10px 16px',
								textAlign: 'right',
								left: 0,
								background: '#fff',
								borderRadius: '0 0 4px 4px',
							}}
						>
							<Button
								disabled={loading}
								loading={loading}
								style={{
									marginRight: 8,
								}}
								onClick={() => this.setState({ editMode: false })}
							>
								إلغاء
							</Button>
							<Button
								disabled={loading}
								type='primary'
								htmlType='submit'
							>
								تعديل
							</Button>
						</div>
					</Form>
				</div>
			)
		} else {
			return (
				<div>
					<Descriptions
						title=''
						column={1}
						bordered
						style={{
							direction: 'rtl'
						}}
					>
						<Descriptions.Item label={'الاسم'} >
							{user.name || <Icon type="small-dash" />}
						</Descriptions.Item>
						<Descriptions.Item label={'العمر'} >
							{user.age || <Icon type="small-dash" />}
						</Descriptions.Item>
						<Descriptions.Item label={'الجنس'} >
							{mapSexString(user.gender) || <Icon type="small-dash" />}
						</Descriptions.Item>
						<Descriptions.Item label={'البريد الإلكتروني'} >
							{user.email || <Icon type="small-dash" />}
						</Descriptions.Item>
						<Descriptions.Item label={'كلمة السر'} >
							{user.password || <Icon type="small-dash" />}
						</Descriptions.Item>
						<Descriptions.Item label={'الدور'} >
							{mapRoleValueToString(user.role) || <Icon type="small-dash" />}
						</Descriptions.Item>
					</Descriptions>

					<div
						style={{
							position: 'absolute',
							bottom: 0,
							width: '100%',
							borderTop: '1px solid #e8e8e8',
							padding: '10px 16px',
							textAlign: 'right',
							left: 0,
							background: '#fff',
							borderRadius: '0 0 4px 4px',
						}}
					>
						<Popconfirm
							title='هل أنت متأكد أنك تريد حذف هذا المستخدم'
							onConfirm={ async () => {
								await deleteUser(this.props.user._id)
								await this.props.fetchRegions()
								message.success('تم حذف المستخدم')
							}}
							onCancel={() => {
								message.error('لم يتم حذف المستخدم')
							}}
							okText="حسنا"
							cancelText="إلغاء"
						>
							<Button
								style={{
									marginRight: 5
								}}
								type='danger'
							>
								حذف
							</Button>
						</Popconfirm>

						<Button
							type="primary"
							onClick={() => this.setState({ editMode: true })}
						>
							تعديل
						</Button>
					</div>
				</div>
			)
		}
	}

}

function mapDispatchToProps (dispatch) {
	return {
		fetchRegions: bindActionCreators(fetchRegionsRequest, dispatch)
	}
}

export default connect(null, mapDispatchToProps)(Form.create()(EditableUserDetails))
