import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { fetchRegionsRequest } from '../actions'
import { withRouter } from 'react-router'
import { Spin, Divider, Statistic, Card, Row, Col, Icon } from 'antd'
import EditRegion from "./EditRegion"
import NewRegionForm from './NewRegionForm'

class RegionsList extends React.Component {

	async loadData() {
		await this.props.fetchRegions()
	}

	componentDidMount() {
		this.loadData()
	}

	componentWillReceiveProps(nextProps, nextContext) {
		if (nextProps.isCreated){
			this.loadData()
		}
	}

	renderLoading() {
		return (
			<div
				style={{
					flex: 1,
					height: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center'
				}}
			>
				<Spin size='large' />
			</div>
		)
	}

	renderError() {
		return <div>Load Error</div>
	}

	render() {
		const { regions, isLoading, loadError } = this.props

		if (isLoading) {
			return this.renderLoading()
		}

		if (loadError) {
			return this.renderError()
		}

		return (
			<>	
				<div
					style={{
						flex: 1,
						display: 'flex',
						minHeight: 50,
						flexDirection: 'column',
						alignItems: 'flex-end'
					}}
				>
					<NewRegionForm/>

					<Divider/>
				</div>

				<div
					style={{
						display: 'flex',
						flexWrap: 'wrap',
						justifyContent: 'flex-end',
						margin: '0 16px'
					}}
				>
					{
						regions.map((region, index) => (

							!region.deleted ?
							<EditRegion key={`${index}`} region={region} /> :
								null
						))
					}
				</div>
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	regions: state.regions.regions,
	isLoading: state.regions.isLoading,
	loadError: state.regions.error,
	currentUser: state.auth.user,
	isCreated: state.regions.isCreated
})

const mapDispatchToProps = (dispatch) => {
	return {
		fetchRegions: bindActionCreators(fetchRegionsRequest, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RegionsList))
