import React, {useState, useEffect} from 'react'
import {baseUri, getFile, uploadFile, Back, uri, deleteFile} from '../api'

import {Icon, Drawer, Form, Input, Upload, message, Button, Popconfirm} from 'antd'
import Colors from '../constants/colors'

import {connect} from 'react-redux'

function Document(props) {

    const [editMode, setEditMode] = useState(false)
    const [fileToUpload, setFileToUpload] = useState({})

    const changeFromFirebase = (file, title, cb) => {

        Back
            .storage()
            .ref(`/pdfs/${props.document.id}.pdf`)
            .put(file, {contentType: 'file/pdf'})
            .then(res => {
                try {
                    Back
                        .database()
                        .ref(`pdfs/${props.document.id}`)
                        .update({title})
                        .then(() => {
                            cb()
                        })
                } catch (error) {
                    if (process.env.NODE_ENV === 'development')
                        console.log(error)
                }
            })
    }

    const changeFromServer = (file, title, cb) => {

     console.log(props.document)
       uploadFile(file, title , props.document._id ).then(() => {
            props.refresh()
            cb()
        })
    }

    const deleteDoc = async id => {
        //await Back.database().ref(`/pdfs/${id}`).remove()
     await  deleteFile(id)
        props.refresh()
    }

    const onSubmit = event => {

        event.preventDefault()

        const {form} = props

        form.validateFields((err, values) => {

            if (!values.title && values.title.length == 0) {
                message.error('يرجى إدخال عنوان')
                return
            }

            if (err) {
                if (values.dragger === undefined) {
                    message.error('اضغط أو اسحب الملف إلى هذه المنطقة للتحميل')
                }
                return
            }

            changeFromServer(values.dragger.file, values.title, () => {
                props.refresh()
                setEditMode(!editMode)
            })
        })
    }

    const {getFieldDecorator} = props.form

    return (
        <div
            style={{
                width: '100%',
                height: '80px',
                backgroundColor: Colors.wraning,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-end',
                paddingLeft: '15px',
                paddingRight: '15px',
                cursor: 'pointer',
                position: 'relative',
                marginBottom: 2
            }}
        >
            {
                props.user.role === 'super_admin'

                    ? <>
                        <Popconfirm
                            title={'هل أنت متأكد أنك تريد حذف هذه الوثيقة'}
                            okText={'حسنا'}
                            cancelText={'إلغاء'}
                            onConfirm={() => {
                                deleteDoc(props.document._id)
                            }}
                        >
                            <div
                                style={{
                                    width: '50px',
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginBottom: 10
                                }}
                            >
                                <Icon
                                    type='delete'
                                    style={{
                                        fontSize: 20
                                    }}
                                />
                            </div>

                            <div
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setEditMode(!editMode)
                                }}
                                style={{
                                    width: '50px',
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginTop: 10
                                }}
                            >
                                <Icon
                                    type='edit'
                                    style={{
                                        fontSize: 20
                                    }}
                                />
                            </div>
                        </Popconfirm>
                    </>

                    : null
            }

            <div
                onClick={() => {
                    //window.open(baseUri + files[0].path)
                    window.open(baseUri +  props.document.path)
                }}
                style={{
                    flex: 1,
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end'
                }}
            >
				<span
                    style={{
                        fontFamily: 'Tajawal',
                        fontSize: 15,
                        paddingTop: '5px',
                        textAlign: 'right'
                    }}
                >
					{/* { files && files.title ? files.title : ''} */}
                    {props.document.name}
				</span>

                <span style={{width: '20px'}}/>

                <Icon
                    type='file'
                    style={{
                        fontSize: 20
                    }}
                />
            </div>

            <Drawer
                title='تعديل'
                placement='left'
                width={'50%'}
                onClose={() => setEditMode(!editMode)}
                visible={editMode}
            >
                <Form onSubmit={onSubmit}>
                    <Form.Item
                        label='العنوان'
                        style={{
                            marginBottom: 20
                        }}
                    >
                        {
                            getFieldDecorator('title', {
                                initialValue: props.document.name,
                                rules: [{required: true, message: 'يرجى اختيار العنوان'}]
                            })(
                                <Input size='large' placeholder='العنوان'/>
                            )
                        }
                    </Form.Item>

                    {
                        getFieldDecorator('dragger', {
                            valuePropName: 'file',
                            rules: [{required: true, message: 'اضغط أو اسحب الملف إلى هذه المنطقة للتحميل'}]
                        })(
                            <Upload.Dragger
                                multiple={false}
                                name="file"
                                onRemove={() => {
                                    setFileToUpload({})
                                }}
                                beforeUpload={file => {
                                    setFileToUpload(file)
                                    return false
                                }}
                            >
                                <p className="ant-upload-drag-icon">
                                    <Icon type="inbox"/>
                                </p>
                                <p className="ant-upload-text">اضغط أو اسحب الملف إلى هذه المنطقة للتحميل</p>
                            </Upload.Dragger>
                        )
                    }

                    <div
                        style={{
                            position: 'absolute',
                            bottom: 0,
                            width: '100%',
                            borderTop: '1px solid #e8e8e8',
                            padding: '10px 16px',
                            textAlign: 'right',
                            left: 0,
                            background: '#fff',
                            borderRadius: '0 0 4px 4px',
                        }}
                    >
                        <Button
                            style={{
                                marginRight: 8,
                            }}
                            onClick={() => setEditMode(false)}
                        >
                            إلغاء
                        </Button>
                        <Button
                            type="primary"
                            htmlType='submit'
                        >
                            حسنا
                        </Button>
                    </div>
                </Form>
            </Drawer>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        user: state.auth.user
    }
}

export default connect(mapStateToProps, null)(Form.create()(Document))
