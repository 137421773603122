
import React, { useState, useEffect, useRef } from 'react'
import { withRouter } from 'react-router-dom'

import { connect } from 'react-redux'
import { fetchPvs } from '../actions'
import { bindActionCreators  } from 'redux'

import 'survey-react/survey.css'
import * as Survey from 'survey-react'
import model from '../constants/PvModel'

import { deletePv } from '../api'
import NewPvModal from './NewPvModal'
import { Spin, Card, Divider, message, Popconfirm, Button, Modal } from 'antd'
import moment from 'moment'
import 'moment/locale/ar-tn'

moment.locale('ar-tn')

function PvRegion ({ fetchPvs, pvs, match, currentUser }) {

	const [all_surveys, setAll_surveys] = useState([])
	const [loading, setLoading] = useState(false)
	const [open, setOpen] = useState(false)

	const survey = useRef(null)

	useEffect(() => {
		async function loadPvs () {
			setLoading(true)
			await fetchPvs(match.params.id)
			setLoading(false)
		}

		loadPvs()
	}, [])

	if (loading) {
		return (
			<div
				style={{
					flex: 1,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center'
				}}
			>
				<Spin size='large'/>
			</div>
		)
	} else {
		return (
			<div>
				<NewPvModal />
				<Spin
					spinning={loading}
				>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							flexWrap: 'wrap',
							justifyContent: 'flex-end'
						}}
					>
						{
							pvs.map((pv, index) => (
								<Card
									key={`${index}`}
									type='inner'
									title='محضر'
									style={{
										marginLeft: 16,
										marginBottom: 16,
										width: 300,
										display: 'flex',
										flexDirection: 'column'
									}}
									bodyStyle={{
										flex: 1
									}}
									actions={
										[
											currentUser.role === 'super_admin' || currentUser.role === 'admin'
											?	<Popconfirm
													title="هل أنت متأكد من حذف المحضر؟"
													onConfirm={async (e) => {
														e.stopPropagation()
														setLoading(true)
														await deletePv(pv._id)
														await fetchPvs(match.params.id)
														setLoading(false)
														message.success(' تم الحذف بنجاح ')
													}}
													onCancel={() => { message.error('الغاء الحذف') }}
													okText="نعم"
													cancelText="لا"
												>
													<Button
														type='danger'
													>
														حذف المحضر
													</Button>
												</Popconfirm>

											:	null
											,
											<Button
												type='default'
												onClick={() => {
													setTimeout(() => {
														survey.current.survey.data = pv.pv
													}, 1000)

													setOpen(true)
												}}
											>
												تفاصيل المحضر
											</Button>
										]
									}
								>
									<p>{moment(pv.createdAt).calendar()}</p>
								</Card>
							))
						}
					</div>
				</Spin>

				<Modal
					visible={open}
					title='P . V'
					onCancel={() => setOpen(false)}
					width={720}
				>
					<div
						style={{
							direction: 'rtl'
						}}
					>
						<Survey.Survey
							ref={survey}
							model={model}
						/>
					</div>
				</Modal>
			</div>
		)
	}
}

function mapStateToProps (state) {
	return {
		currentUser: state.auth.user,
		pvs : state.pvs.pvs
	}
}

function mapDispatchToProps (dispatch) {
	return {
		fetchPvs: regionId => dispatch(fetchPvs(regionId))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PvRegion))
