import _ from 'lodash'

import {
    FETCH_RECORDS_REQUEST,
    FETCH_RECORDS_REQUEST_SUCCESS,
    FETCH_RECORDS_REQUEST_FAIL,
    PAGINATE_REQUESTS
} from '../constants/action-types'

export default function (state = { records: [], isLoading: false, error: null }, action) {
    switch (action.type) {
        case PAGINATE_REQUESTS:
            return {
                isLoading: false,
                error: null,
                //records: [...state.records, ...action.payload]
                records: _.uniqBy([...state.records, ...action.payload], (e) => e._id)
            }
        case FETCH_RECORDS_REQUEST:
            return {
                isLoading: true,
                error: null,
                records: []
            }
        case FETCH_RECORDS_REQUEST_SUCCESS:
            return {
                isLoading: false,
                error: null,
                records: _.uniqBy([...state.records, ...action.payload], (e) => e._id)
            }
        case FETCH_RECORDS_REQUEST_FAIL:
            return {
                isLoading: false,
                error: action.payload,
                records: []
            }
        default:
            return state
    }
}
