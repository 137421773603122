import React from 'react'
//import './UserSurveysPage.css'
import { connect } from 'react-redux'
import {Tab, Header, Segment, Input} from 'semantic-ui-react'
import { withRouter } from 'react-router'
import RegionUsers from '../components/RegionUsers'
import RegionSurveys from '../components/RegionSurveys'
import RegionRecords from '../components/RegionRecords'
import RegionMatches from '../components/RegionMatches'
import RegionMatch from '../components/RegionMatch'
import RegionPv from '../components/RegionPv'
import RegionComments from '../components/RegionComments'
import RegionStats from '../components/RegionStats'
import { baseUri, getFiles, uploadFile, Back, uri } from '../api'

import { Tabs, Alert, Icon, Button } from 'antd'
import '../assets/region.css'
import Colors from '../constants/colors';
import Document from '../components/Document';
import Comments from '../components/Comments';
import PvRegion from '../components/PvRegion';

class RegionPage extends React.Component {

	constructor(props) {
		super(props)
		this.fileInput = React.createRef()
		this.state = {
			file: undefined,
			name: undefined,
			files: [],
			edit: false
		}
	}

	mapKeysToTabs = key => {
		switch (key) {
			case '7':
				return 'users'
				break

			case '6':
				return 'surveys'
				break

			case '5':
				return 'records'
				break

			case '4':
				return 'matches'
				break

			case '3':
				return 'reports'
				break

			case '2':
				return 'comments'
				break

			case '1':
				return 'stats'
				break

			default:
				break
		}
	}

	mapTabsToKeys = tab => {
		switch (tab) {
			case 'users':
				return '7'
				break

			case 'surveys':
				return '6'
				break

			case 'records':
				return '5'
				break

			case 'matches':
				return '4'
				break

			case 'reports':
				return '3'
				break

			case 'comments':
				return '2'
				break

			case 'stats':
				return '1'
				break

			default:
				break
		}
	}

	componentDidMount() {
		getFiles().then(res => this.setState({ files: res }))
	}

	componentWillReceiveProps(nextProps, nextContext) {
		getFiles().then(res => this.setState({ files: res }))
	}

	callback = (key) => {
		const newLocation = this.props.location.pathname.split('/').slice(0,-1).join('/') + `/${this.mapKeysToTabs(key)}`
		this.props.history.push(newLocation)
	}

	changeFromFirebase(file, title) {
		let updates = {}
		let metadata = {
			contentType: 'file/pdf',
		}
		let uploadTask = Back.storage().ref(`/pdfs/file.pdf`).put(file, metadata).then(
			res => {
				res.ref.getDownloadURL().then((downloadURL) => {
					let image = {
						id: 'pdf',
						title: title,
						URL: downloadURL,
					}
					try {
						updates[`pdfs`] = image

						Back.database().ref().update(updates).then(() => {})
					} catch (error) {
						if (process.env.NODE_ENV === 'development')
							console.log(error)
					}
				})
			}
		)

	}

	getFromFirebase() {}

	render() {

		const { tab } = this.props.match.params
		const { currentUser } = this.props

		return (
			<div
				style={{
					flex: 1,
					display: 'flex',
					flexDirection: 'column'
				}}
			>
				<div
					style={{
						flex: 1,
						padding: 15,
						textAlign: 'right'
					}}
				>
					{
						currentUser.role === 'super_admin' || currentUser.role === 'admin'

						?	<Tabs
								animated={false}
								defaultActiveKey="7"
								onChange={this.callback.bind(this)}
								activeKey={this.mapTabsToKeys(tab) || '7'}
							>

								<Tabs.TabPane tab="إحصائيات" key="1">
									<RegionStats/>
								</Tabs.TabPane>

								<Tabs.TabPane tab="تعليقات" key="2">
									{/* <RegionComments/> */}
									<Comments/>
								</Tabs.TabPane>

								<Tabs.TabPane tab="المحاضر" key="3">
									{/* <RegionPv/> */}
									<PvRegion/>
								</Tabs.TabPane>

								<Tabs.TabPane tab="تطابقات" key="4">
									{/* <RegionMatches/> */}
									<RegionMatch/>
								</Tabs.TabPane>

								<Tabs.TabPane tab="الملفات" key="5">
									<RegionRecords/>
								</Tabs.TabPane>

								<Tabs.TabPane tab="الإستمارات" key="6">
									<RegionSurveys/>
								</Tabs.TabPane>

								<Tabs.TabPane
									tab="المستخدمون"
									key="7"
								>
									<RegionUsers />
								</Tabs.TabPane>
							</Tabs>

						:	<Tabs
								animated={false}
								defaultActiveKey="1"
								onChange={this.callback.bind(this)}
							>
								<Tabs.TabPane tab="الملفات" key="1">
									<RegionRecords/>
								</Tabs.TabPane>
								{currentUser.role === 'psy' || currentUser.role === 'social' ?

									<Tabs.TabPane tab="المحاضر" key="3">
										{/* <RegionPv/> */}
										<PvRegion/>
									</Tabs.TabPane> :
									null
								}

							</Tabs>
					}
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	currentUser: state.auth.user,
	regions: state.regions.regions,
	isCreated: state.regions.isCreated
})

export default connect(mapStateToProps, null)(withRouter(RegionPage))
