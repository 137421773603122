import React from 'react'
import ReactDOM from 'react-dom'
import 'antd/dist/antd.css'
import './assets/layout.css'
import {Layout, Menu, Breadcrumb, Icon, Divider, Avatar, Badge, Dropdown} from 'antd'

import logo from './assets/joussour.png'
import {fetchAllResults} from './api'
import {connect} from 'react-redux'
import {withRouter, NavLink, Route, Link, Redirect} from 'react-router-dom'
import Colors from './constants/colors'
import styled from 'styled-components'

import {mapRoleValueToString} from './util'
import {CSVLink, CSVDownload} from "react-csv";
import {userRequestLogout} from './actions'
import RegionsPage from './pages/RegionsPage'
import RegionPage from './pages/RegionPage'
import AllRecords from './components/AllRecords'
import SurveyResult from './pages/SurveyResult'
import SurveyPage from './pages/SurveyPage'
import SurveyEditorComponent from './pages/SurveyEditorComponent'

import {
    Switch
} from 'react-router-dom'
import LimeLight from './components/LimeLight';
import Document from './components/Document';
import GeneralStats from './components/GeneralStats';
import {object} from "prop-types";
import AddDocument from './components/AddDocument';
import Documents from './components/Documents';

const {Header, Content, Footer, Sider} = Layout
const {SubMenu} = Menu


const MItem = styled.div`
	background-color: ${props => props.ative ? Colors.dark_blue_active : Colors.dark_blue};
	width: 100%;
	height: 7%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	cursor: pointer;

	&:hover {
		background-color: ${props => Colors.dark_blue_active};
	}
`


function MenuItem({title, active, onClick}) {
    return (
        <MItem
            ative={active}
            onClick={onClick}
        >
			<span
                style={{
                    fontFamily: 'Tajawal',
                    fontWeight: 'bold',
                    color: Colors.white,
                    fontSize: 20
                }}
            >
				{title}
			</span>

            {
                active

                    ? <div
                        style={{
                            backgroundColor: Colors.white,
                            width: '1%',
                            height: '100%',
                            position: 'absolute',
                            right: 0
                        }}
                    />

                    : null
            }
        </MItem>
    )
}

class SiderDemo extends React.Component {
    exportApi = () => {
        let res = []
        let question = []
        let response = []
        fetchAllResults().then(items => {
            items.map(item => {
                for (let x in item.result) {

                    let region = this.props.regions.find(a => a._id === item.survey.region)
                    res.push({
                        folder: item.record.numFolder,
                        survey: item.survey.title,
                        question: x,
                        response: JSON.stringify(item.result[x.toString()]),
                        region: region ? region.nom : " "
                    })
                }
            })
            this.setState({csvData: res, loadData: true}, () => {

            })

        })
    }
    state = {
        collapsed: false,
        csvData: [],
        loadData: false
    }

    onCollapse = collapsed => {
        this.setState({collapsed})
    }

    render() {
        const {match, user} = this.props
        const {location} = this.props

        const currentRoute = location.pathname.split('/')[2]

        return (
            <div
                style={{
                    display: 'flex',
                    height: '100vh',
                    backgroundColor: Colors.light_grey,
                    flexDirection: 'column',
                    overflow: 'hidden'
                }}
            >
                {/* Header Main Navigation */}
                <div
                    style={{
                        height: '80px',
                        backgroundColor: Colors.white,
                        zIndex: 99,
                        flexDirection: 'row-reverse',
                        display: 'flex',
                        boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.36)',
                        flexShrink: 0,
                        overflow: 'hidden'
                    }}
                >
                    <div
                        style={{
                            flex: 1,
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <img
                            style={{
                                height: 'auto',
                                width: '100px'
                            }}
                            src={logo}
                        />
                    </div>

                    <div
                        style={{
                            flex: 4,
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end'
                        }}
                    >
                        <LimeLight clickable={user.role === 'super_admin' || user.role === 'admin'}/>
                    </div>
                </div>

                {/* <Document admin={user.role === 'super_admin'} /> */}

                <div
                    style={{
                        display: 'flex',
                        height: '100vh',
                        backgroundColor: Colors.light_grey
                    }}
                >
                    {/* Main Content */}
                    <div
                        style={{
                            flex: 4,
                            display: 'flex',
                            overflow: 'auto',
                            //marginBottom : 80

                        }}
                    >
                        <Switch>
                            <Route exact path={`${match.path}/records`} component={AllRecords}/>
                            <Route exact path={`${match.path}/stats`} component={GeneralStats}/>
                            <Route exact path={`${match.path}/regions`} component={RegionsPage}/>
                            <Route exact path={`${match.path}/regions/:id`} component={RegionPage}/>
                            <Route exact path={`${match.path}/regions/:id/:tab`} component={RegionPage}/>
                            <Route exact path={`${match.path}/regions/:id/surveys/:surveyId/edit`}
                                   component={SurveyEditorComponent}/>
                            <Route exact path={`${match.path}/regions/:id/records/:recordId/surveys/:surveyId`}
                                   component={SurveyPage}/>
                            {/* <Route exact path={`${match.path}/regions/:id/surveys/:surveyId/:recordId`} component={SurveyPage} /> */}
                            <Route exact path={`${match.path}/regions/:id/records/:recordId/surveys/:surveyId/results`}
                                   component={SurveyResult}/>
                            <Route exact path={`${match.path}/records/:recordId/surveys/:surveyId/results`}
                                   component={SurveyResult}/>
                            <Route exact path={`${match.path}/records/:recordId/surveys/:surveyId`}
                                   component={SurveyPage}/>
                        </Switch>
                    </div>

                    {/* Side Bar */}
                    <div
                        style={{
                            flex: 1,
                            display: 'flex',
                            backgroundColor: Colors.dark_blue,
                            paddingTop: 10,
                            flexDirection: 'column',
                            flexShrink: 0,
                            overflow: 'hidden',
                            position: 'relative',
                            width: 200,
                            height: '100%'
                        }}
                    >
                        {
                            user.role === 'super_admin'

                                ? <>
                                    <MenuItem
                                        onClick={() => {
                                            this.props.history.push(`${match.url}/stats`)
                                        }}
                                        title={'احصائيات عامة'}
                                        active={currentRoute == 'stats'}
                                    />

                                    <div style={{width: '100%', height: '1px'}}/>

                                    <MenuItem
                                        onClick={() => {
                                            this.props.history.push(`${match.url}/regions`)
                                        }}
                                        title={'المناطق'}
                                        active={currentRoute == 'regions'}
                                    />

                                    <div style={{width: '100%', height: '1px'}}/>

                                    <MenuItem
                                        onClick={() => {
                                            this.props.history.push(`${match.url}/records`)
                                        }}
                                        title={'الملفات'}
                                        active={currentRoute == 'records'}
                                    />
                                </>

                                : null
                        }

                        <div
                            style={{
                                position: 'absolute',
                                bottom: 0,
                                //backgroundColor: 'red',
                                width: '100%',
                                minHeight: '10%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexDirection: 'column'
                            }}
                        >
                            {
                                user.role === 'super_admin'

                                    ?


                                    <div
                                        onClick={() => {

                                            this.exportApi()

                                        }} // TODO export api
                                        style={{
                                            backgroundColor: Colors.wraning,
                                            width: '100%',
                                            height: '80px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            fontFamily: 'Tajawal',
                                            color: 'black',
                                            cursor: 'pointer',
                                            marginBottom: 2
                                        }}
                                    >
                                        <CSVLink
                                            style={{
                                                color: 'black'
                                            }}
                                            data={this.state.csvData}
                                            filename={"data.csv"}
                                            asyncOnClick={true}
                                            headers={[
                                                {label: "region", key: "region"},
                                                {label: "folder", key: "folder"},
                                                {label: "survey", key: "survey"},
                                                {label: "question", key: "question"},
                                                {label: "response", key: "response"}

                                            ]}
                                            onClick={(event, done) => {


                                                this.exportApi()

                                                done(this.state.loadData); // Don't Proceed

                                            }}
                                        >


                                            {this.state.loadData ?
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                        justifyContent: 'center'
                                                    }}
                                                >
                                                    <Icon
                                                        type='export'
                                                        style={{
                                                            marginRight: 10,
                                                            fontSize: 20
                                                        }}
                                                    /> <p>تنزيل</p>
                                                </div>
                                                : <p> تصدير البيانات</p>}

                                        </CSVLink>
                                    </div>

                                    : null
                            }

                            <Documents />
                            {/* <Document admin={user.role === 'super_admin'}/> */}
                            

                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    height: '10%',
                                    marginTop: '8%',
                                    marginBottom: '8%'
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        textAlign: 'right'
                                    }}
                                >
									<span
                                        style={{
                                            color: Colors.white,
                                            fontFamily: 'Tajawal',
                                            fontWeight: 'bold',
                                            marginRight: '10px'
                                            // font-family: Tajawal;
                                            // font-weight: bold;
                                            // /* vertical-align: -7.15em; */
                                            // margin-right: 10px;
                                        }}
                                    >
										{user.name}
									</span>

                                    <span
                                        style={{
                                            color: Colors.white,
                                            fontFamily: 'Tajawal',
                                            fontWeight: 'bold',
                                            marginRight: '10px'
                                            // font-family: Tajawal;
                                            // font-weight: bold;
                                            // /* vertical-align: -7.15em; */
                                            // margin-right: 10px;
                                        }}
                                    >
										{mapRoleValueToString(user.role)}
									</span>
                                </div>

                                <Dropdown
                                    overlay={(
                                        <Menu>
                                            <Menu.Item
                                                onClick={() => {
                                                    this.props.logout()
                                                    this.props.history.replace('/login')
                                                }}
                                            >
                                                الخروج
                                            </Menu.Item>
                                        </Menu>
                                    )}
                                    placement="topRight"
                                >
                                    <Badge dot>
                                        <Avatar style={{cursor: 'pointer'}} shape='square' size="large" icon="user"/>
                                    </Badge>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        regions: state.regions.regions,
        user: state.auth.user
    }
}

function mapDispatchToProps(dispatch) {
    return {
        logout: () => dispatch(userRequestLogout())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SiderDemo))
