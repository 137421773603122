import React, { Component } from 'react'

import { connect } from "react-redux"
import { withRouter } from "react-router"
import { Popconfirm, message } from 'antd'
import { bindActionCreators } from "redux"
import { Table, Select, Modal } from 'semantic-ui-react'
import Button from "semantic-ui-react/dist/commonjs/elements/Button"
import { fetchRecordsRequest, fetchRegionsRequest } from "../actions"
import { fetchMatchesByRegion, fetchUserSurveys, addMatch, deleteMatch, updateMatch } from '../api'

class RegionMatches extends Component {

	constructor(props) {
		super(props)

		this.state = {
			matches: [],
			all_surveys: [],
			showAdd: false,
			listQustion1: [],
			listQustion2: [],
			survey1: {},
			survey2: {},
			question1: {},
			question2: {},
		}
	}

	componentDidMount() {
		
		fetchMatchesByRegion(this.props.match.params.id)
			.then(res => {
				this.setState({
					matches: res
				})
			})

		fetchUserSurveys(this.props.currentUser)
			.then(surveys => {
				let all_surveys = surveys.filter(survey => survey.region === this.props.match.params.id).map(item => ({
					key: item._id,
					text: item.title,
					value: item
				}))

				this.setState({
					all_surveys,
					loading: false
				})
			})
	}


	getquestionfroms = (type, survey) => {

		let q = []

		survey.value.pages.map(page => {
			page.elements.map(element => {
				q.push({
					key: element._id,
					text: element.name,
					value: element
				})
			})

		})

		if (type === 1)
			this.setState({ listQustion1: q })
		else
			this.setState({ listQustion2: q })
	}


	newmatch = () => {
		
		fetchUserSurveys(this.props.currentUser)
			.then(surveys => {

				this.setState({
					all_surveys: surveys.filter(survey => survey.region === this.props.match.params.id).map(item => ({
						key: item._id,
						text: item.title,
						value: item
					})),
					loading: false
				})
			})
	}


	render() {
		return (
			<div style={{direction : 'rtl'}}>

				<Table
					style={{direction : 'rtl'}}
					celled padded
				>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell singleLine>الإستبيان الأول </Table.HeaderCell>
							<Table.HeaderCell>الاستبيان الثاني</Table.HeaderCell>
							<Table.HeaderCell>السؤال الأول </Table.HeaderCell>
							<Table.HeaderCell>السؤال الثاني</Table.HeaderCell>
							<Table.HeaderCell>Actions</Table.HeaderCell>

						</Table.Row>
					</Table.Header>

					<Table.Body>

						{
							this.state.matches && this.state.all_surveys && this.state.all_surveys.length > 0 && this.state.matches.length > 0 ?

								this.state.matches.map(item => {
									return <Table.Row

										style={{direction : 'rtl'}}
										key={item._id}>
										<Table.Cell>

											{this.state.all_surveys.find(a => a.key === item.survey1).text}

										</Table.Cell>
										<Table.Cell>
											{this.state.all_surveys.find(a => a.key === item.survey2).text}
										</Table.Cell>
										<Table.Cell>

											{item.question1.title}

										</Table.Cell>
										<Table.Cell>
											{item.question2.title}
										</Table.Cell>
										<Table.Cell>

											<div className="ui basic green button" onClick={() => {
												this.setState({
													[`openEditModal${item._id}`]: true,
													survey1: this.state.all_surveys.find(a => a.key === item.survey1),
													survey2: this.state.all_surveys.find(a => a.key === item.survey2)
												})
											}
											} >تعديل</div>

										<Popconfirm
                                        title="هل أنت متأكد من حذف تطابقات؟"
                                        onConfirm={() => {

											deleteMatch(item._id).then(() => {
												fetchMatchesByRegion(this.props.match.params.id).then(res => {

													this.setState({
														matches: res,
														[`openDeleteModal${item._id}`]: false
													})

												})
											})
											message.success(' تم الحذف بنجاح ')
										}}

                                        onCancel={() => { message.error('الغاء الحذف') }}
                                        okText="نعم"
                                        cancelText="لا"
                                    >
                                        <div className="ui basic red button"

											>حذف </div>
                                        </Popconfirm>



											<Modal
												style={{
													direction: 'rtl'
												}}
												open={this.state[`openEditModal${item._id}`]}
												className='fixModal'
												size='fullscreen'
												closeIcon
												onClose={() => {
													this.setState({
														[`openEditModal${item._id}`]: false
													})
												}}
											>
												<Modal.Header>
												تعديل التطابق
												</Modal.Header>

												<Modal.Content>
													<Table
														celled
														padded
														style={{
															width: '100%',
															direction : 'rtl'
														}}
													>
														<Table.Header>
															<Table.Row>
																<Table.HeaderCell singleLine>الإستبيان
                                                                    الأول </Table.HeaderCell>
																<Table.HeaderCell>الاستبيان الثاني</Table.HeaderCell>
																<Table.HeaderCell>السؤال الأول </Table.HeaderCell>
																<Table.HeaderCell>السؤال الثاني</Table.HeaderCell>
															</Table.Row>
														</Table.Header>

														<Table.Body>
															<Table.Row>
																<Table.Cell>
																	<Select
																		placeholder='Select survey'
																		options={this.state.all_surveys}
																		value={this.state.survey1}
																		text={this.state.survey1.text}
																		key={this.state.survey1._id}
																		onChange={(e, data) => {
																			this.getquestionfroms(1, data)
																			this.setState({
																				survey1: data.value
																			})
																		}}
																	/>
																</Table.Cell>
																<Table.Cell>
																	<Select placeholder='Select survey'


																		value=
																		{this.state.survey2}
																		text={this.state.survey2.text}
																		key={this.state.survey2._id
																		}


																		options={
																			this.state.all_surveys
																		}
																		onChange={
																			(e, data) => {
																				this.getquestionfroms(2, data)
																				this.setState({
																					survey2: data.value
																				})
																			}
																		}
																	/>
																</Table.Cell>
																<Table.Cell>
																	<Select placeholder='Select survey'

																		value={this.state.question1}
																		key={this.state.question1._id}
																		text={this.state.question1.name}
																		options={
																			this.state.listQustion1
																		}
																		onChange={(e, data) => {
																			this.setState({
																				question1: data.value
																			})
																		}}
																	/>
																</Table.Cell>
																<Table.Cell>
																	<Select placeholder='Select survey'


																		value={this.state.question2}
																		key={this.state.question2._id}
																		text={this.state.question2.name}

																		options={this.state.listQustion2}

																		onChange={(e, data) => {

																			this.setState({
																				question2: data.value
																			})

																		}}
																	/>
																</Table.Cell>
															</Table.Row>
														</Table.Body>
													</Table>
												</Modal.Content>
												<Modal.Actions>

													<Button positive icon='checkmark' labelPosition='right'
														onClick={() => {

															if (this.state.survey2._id && this.state.survey1._id &&
																this.state.question1._id && this.state.question2._id) {
																updateMatch(item._id,
																	{

																		survey1: this.state.survey1._id,
																		survey2: this.state.survey2._id,
																		question1: {
																			_id: this.state.question1._id,
																			title: this.state.question1.name

																		},
																		question2: {
																			_id: this.state.question2._id,
																			title: this.state.question2.name
																		},
																		region: this.props.match.params.id

																	}
																).then(() => {
																	fetchMatchesByRegion(this.props.match.params.id).then(res => {

																		this.setState({
																			matches: res,
																			[`openEditModal${item._id}`]: false
																		})

																	})
																})
															} else {
																alert('تأكد من اختيارك')
															}
														}}
														content='نعم ' />
												</Modal.Actions>
											</Modal>


										</Table.Cell>
									</Table.Row>
								}) :
								null
						}
					</Table.Body>
				</Table>

				{
					this.state.showAdd ?
						<Table
							style={{direction : 'rtl'}}
							celled padded style={
							{
								width: '100%'
							}
						}>
							<Table.Header>
								<Table.Row>
									<Table.HeaderCell singleLine>الإستبيان الأول </Table.HeaderCell>
									<Table.HeaderCell>الاستبيان الثاني</Table.HeaderCell>
									<Table.HeaderCell>السؤال الأول </Table.HeaderCell>
									<Table.HeaderCell>السؤال الثاني</Table.HeaderCell>
								</Table.Row>
							</Table.Header>

							<Table.Body>


								<Table.Row>
									<Table.Cell>
										<Select placeholder='Select survey' options={
											this.state.all_surveys
										}

											onChange={(e, data) => {

												this.getquestionfroms(1, data)
												this.setState({
													survey1: data.value
												})
											}}

										/>
									</Table.Cell>
									<Table.Cell singleLine>
										<Select placeholder='Select survey' options={
											this.state.all_surveys
										}
											onChange={
												(e, data) => {
													this.getquestionfroms(2, data)
													this.setState({
														survey2: data.value
													})
												}
											}
										/>
									</Table.Cell>
									<Table.Cell>
										<Select placeholder='Select survey' options={
											this.state.listQustion1
										}
											onChange={(e, data) => {
												this.setState({
													question1: data.value
												})
											}}
										/>
									</Table.Cell>
									<Table.Cell textAlign='right'>
										<Select placeholder='Select survey' options={this.state.listQustion2}

											onChange={(e, data) => {

												this.setState({
													question2: data.value
												})

											}}
										/>
									</Table.Cell>

								</Table.Row>
							</Table.Body>
						</Table> : null
				}

				<Button
					onClick={() => {

						if (this.state.showAdd) {
							addMatch({

								survey1: this.state.survey1._id,
								survey2: this.state.survey2._id,
								question1: {
									_id: this.state.question1._id,
									title: this.state.question1.name

								},
								question2: {
									_id: this.state.question2._id,
									title: this.state.question2.name
								},
								region: this.props.match.params.id

							}).then(() => {

								fetchMatchesByRegion(this.props.match.params.id).then(res => {

									this.setState({
										matches: res,
										showAdd: false

									})

								})

							})


						} else {
							this.setState({
								showAdd: true
							});
							this.newmatch()
						}
					}}>

					إضافة تطابق </Button>

			</div>
		);
	}

}

const mapStateToProps = (state) => ({
	regions: state.regions.regions,
	isLoading: state.regions.isLoading,
	loadError: state.regions.error,
	currentUser: state.auth.user,
	isCreated: state.regions.isCreated,
	records: state.records.records
}
)

const mapDispatchToProps = (dispatch) => {
	return {
		fetchRegions: (fetchRegionsRequest, dispatch),
		fetchRecords: bindActionCreators(fetchRecordsRequest, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RegionMatches))