
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { fetchRegionsRequest } from '../actions'

import _ from 'lodash'
import NewUserForm from './NewUserForm'
import UsersByRole from './UsersByRole'

class RegionUsers extends React.Component {

	state = {
		open: false
	}

	componentDidMount () {
		this.props.fetchRegions()
	}

	usersByRole = (users) => {
		const realUsers = users.filter(user => !user.deleted)
		const usersByRole = _.groupBy(realUsers, 'role')

		return Object.keys(usersByRole).map(role => {
			return {
				role,
				users: usersByRole[role]
			}
		})
	}

	render() {
		const { regions } = this.props
		const region = regions.find(r => r._id === this.props.match.params.id)

		return (
			<>
				<NewUserForm />

				<div
					style={{
						flex: 1,
						display: 'flex',
						justifyContent: 'flex-end'
					}}
				>
					{
						region && this.usersByRole(region.users).map((ubr, index) => {
							return (
								<UsersByRole key={`${index}`} usersByRole={ubr} />
							)
						})
					}
				</div>
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	regions: state.regions.regions,
	isLoading: state.regions.isLoading,
	loadError: state.regions.error,
	currentUser: state.auth.user,
	isCreated: state.regions.isCreated
})

const mapDispatchToProps = (dispatch) => {
	return {
		fetchRegions: bindActionCreators(fetchRegionsRequest, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RegionUsers))