import * as types from '../constants/action-types'

export default function (state = { regions: [], isLoading: false, error: null, isCreated: false }, action) {
	switch (action.type) {
		case types.FETCH_REGIONS_REQUEST:
			return {
				isLoading: true,
				error: null,
				regions: []
			}
		case types.FETCH_REGIONS_REQUEST_SUCCESS:
			return {
				isLoading: false,
				error: null,
				regions: action.payload
			}
		case types.FETCH_REGIONS_REQUEST_FAIL:
			return {
				isLoading: false,
				error: action.payload,
				regions: []
			}
		case types.FETCH_REGION_REQUEST:
			return {
				isLoading: true,
				error: null,
				regions: []
			}
		case types.FETCH_REGION_REQUEST_SUCCESS:
			return {
				isLoading: false,
				error: null,
				regions: [...state.regions, action.payload]
			}
		case types.FETCH_REGION_REQUEST_FAIL:
			return {
				isLoading: false,
				error: action.payload,
				regions: []
			}


		case types.CREATE_REGION_REQUEST:
			return {
				...state,
				isCreated: false,
				error: null
			}
		case types.CREATE_REGION_REQUEST_SUCCESS:
			return {
				...state,
				isCreated: true,
				error: null
			}
		case types.CREATE_REGION_REQUEST_FAIL:
			return {
				...state,
				isCreated: false,
				error: action.payload
			}
		case types.EDIT_REGION_REQUEST:
			return {
				...state,
				isCreated: false,
				error: null
			}
		case types.EDIT_REGION_REQUEST_SUCCESS:
			return {
				...state,
				isCreated: true,
				error: null
			}
		case types.EDIT_REGION_REQUEST_FAIL:
			return {
				...state,
				isCreated: false,
				error: action.payload
			}
		default:
			return state
	}
}
