import React from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
//import Survey from '../components/Survey/Survey'
import survey, {getSurvey, getFetchError, getFetchStatus, getSubmitStatus} from '../reducers/survey'
import {submitResult, fetchSurvey} from '../actions/survey'
import {withRouter} from 'react-router'

//import '../styles.css'
import { JsonToTable } from "react-json-to-table";

import 'survey-react/survey.css'
import * as Survey from 'survey-react'
import {Loader, Segment, Dimmer, Image, Header} from 'semantic-ui-react';
import {saveResult, fetchMatchBySurvey, fetchResults, getUserDetails} from '../api';
import {
    Card,
    Icon,
    Badge,
    Affix,
    Button,
    Divider,
    Popconfirm,
    Descriptions,
    Modal
} from 'antd'


import {mapUserDetailToString} from "../util";
import CommentModal from '../components/CommentModal';
import $ from 'jquery';

class SurveyPage extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            recordId: 'checklist',
            isCompleted: false,
            disabled: true,
            modalOpen: false,
            moreInfos: undefined,
            value: '',
            match: undefined,
            userDetails: undefined,
            userDetailsLoading: undefined
        }

        this.onCompleteComponent = this.onCompleteComponent.bind(this)
    }

    isAdmin = () => this.props.currentUser.role == 'super_admin'

    onCompleteComponent() {
        this.setState({isCompleted: true})
    }

    componentDidMount() {
        const surveyId = this.props.match.params.surveyId
        this.props.fetchSurvey(surveyId)
        $('span:contains("**")').parent().css('background-color', '#FFFC76');
        $('span:contains("***")').parent().css('background-color', '#FFC000');
        $('span:contains("****")').parent().css('background-color', '#F15E0D');

        getUserDetails(this.props.match.params.recordId)
            .then(details => {
                this.setState({
                    userDetails: details,
                    userDetailsLoading: false
                })
            })
    }

    componentDidUpdate(prevProps) {
        if (this.props.surveyId !== prevProps.surveyId) {
            this.props.fetchSurvey(this.props.surveyId)
        }
    }

    //Define a callback on survey complete
    onComplete(survey, options) {}

    onValueChanged = (a, b) => {
        if (this.props.survey.type === 'fiche3') {

            $(".sv_progress_bar span").text(
                parseInt(
                    (((Object.keys(this.survey.data).length) / 116) * 100)
                )

                + '%');

            this.getScore(this.survey.data)
        }
    }

    setMatches = (
        questionId,
        cb = () => {
        }
    ) => {
        const surveyId = this.props.match.params.surveyId
        fetchMatchBySurvey(surveyId, questionId)
            .then(item => {

                if (item) {
                    if (item.survey1 == surveyId) {
                        fetchResults(item.survey2)
                            .then(res2 => {
                                const recordId = this.props.match.params.recordId
                                res2.map(result => {
                                    if (result.record == recordId) {
                                        this.setState({
                                            moreInfos: result.result,
                                            match: item
                                        }, cb)
                                    }
                                })
                            })
                    } else if (item.survey2 == surveyId) {
                        fetchResults(item.survey1)
                            .then(res1 => {
                                const recordId = this.props.match.params.recordId
                                res1.map(result => {

                                    if (result.record == recordId) {
                                        this.setState({
                                            moreInfos: result.result,
                                            match: item
                                        }, cb)
                                    }
                                })
                            })
                    }
                } else {
                    this.setState({
                        moreInfos: 'لايوجد بيانات',
                        match: undefined
                    }, cb)
                }
            })
    }

    getScore(reseult) {
        let allQuestion = this.survey.getAllQuestions()

        let score1 = 0
        let score2 = 0
        let score = 0

        Object.entries(reseult).map((item, index) => {

            if (allQuestion.find(a => a.name === item[0]) && allQuestion.find(a => a.name === item[0]).isVisible) {
                if (item[0].includes('yes') && item[1] === 'نعم ') {
                    if (parseInt(item[0]) < 25)
                        score1 = score1 + 3
                    else if (parseInt(item[0]) >= 25) {
                        score2 = score2 + 3
                    }
                } else if (item[0].includes('yes') && item[1] === 'لا') {
                    {
                        if (parseInt(item[0]) < 25)
                            score1 = score1 + 2
                        else if (parseInt(item[0]) >= 25) {
                            score2 = score2 + 2
                        }
                    }
                } else if
                (item[0].includes('no') && item[1] === 'نعم ') {
                    {
                        if (parseInt(item[0]) < 25)
                            score1 = score1 + 1
                        else if (parseInt(item[0]) >= 25) {
                            score2 = score2 + 1
                        }
                    }
                } else if (item[0].includes('no') && item[1] === 'لا') {
                    {
                        if (parseInt(item[0]) < 25)
                            score1 = score1
                        else if (parseInt(item[0]) >= 25) {
                            score2 = score2
                        }
                    }
                }
            }
        })

        score = score1 + score2

        this.survey.setValue('total1', score1)

        this.survey.setValue('total2', score2)

        this.survey.setValue('total', score)

        return score
    }

    render() {
        $('span:contains("**")').parent().css('background-color', '#FFFC76');
        $('span:contains("***")').parent().css('background-color', '#FFC000');
        $('span:contains("****")').parent().css('background-color', '#F15E0D');

        const {
            survey,
            isLoading
        } = this.props

        if (isLoading) {
            return (
                <div
                    style={{
                        paddingLeft: 50,
                        paddingRight: 50,
                        marginTop: 50,
                        height: 150
                    }}
                >
                    <Segment loading>
                        <Image style={{opacity: 0}} src='https://react.semantic-ui.com/images/wireframe/paragraph.png'/>
                    </Segment>
                </div>
            )
        } else {
            let survey_model_copy = {...survey}
            delete survey_model_copy.deleted
            delete survey_model_copy.minQuestions
            delete survey_model_copy.questions
            delete survey_model_copy.region
            delete survey_model_copy.type
            delete survey_model_copy.updatedAt
            delete survey_model_copy.__v
            delete survey_model_copy._id
            delete survey_model_copy.locale
            delete survey_model_copy.title

            delete survey_model_copy.goNextPageAutomatic

            delete survey_model_copy.isSinglePage
            delete survey_model_copy.focusOnFirstError
            delete survey_model_copy.focusFirstQuestionAutomatic
            // const model = new Survey.Model({
            //     ...survey,
            //     //mode: 'display'
            // })
            Survey.JsonObject.metaData.addProperty("question", "_id:text")
            const model = new Survey.Model({
                locale: 'ar',
                showTitle: true,
                goNextPageAutomatic: false,
                isSinglePage: false,
                focusFirstQuestionAutomatic: false,
                focusOnFirstError: false,
                ...survey_model_copy
            })
            model.onAfterRenderQuestion.add((survey, options) => {
                //Return if there is no description to show in popup
                //if(!options.question.popupdescription) return;
                //Add a button;
                $('span:contains("**")').parent().css('background-color', '#FFFC76');
                $('span:contains("***")').parent().css('background-color', '#FFC000');
                $('span:contains("****")').parent().css('background-color', '#F15E0D');

                var btn = document.createElement("button");
                btn.type = "button";
                btn.className = "btn btn-info btn-xs";
                btn.innerHTML = "الاجابات السابقة";
                var question = options.question;

                btn.onclick = () => {
                    window.question = question
                    window.options = options

                    this.setMatches(question._id, () => {
                        if (this.state.match) {
                            if (this.state.match.question1._id == question._id) {
                                this.setState({
                                    modalOpen: true,
                                    value: this.state.moreInfos && this.state.moreInfos[this.state.match.question2.title] ?
                                        this.state.moreInfos[this.state.match.question2.title] : 'لايوجد بيانات'
                                })
                            } else if (this.state.match.question2._id == question._id) {
                                this.setState({
                                    modalOpen: true,
                                    value: this.state.moreInfos && this.state.moreInfos[this.state.match.question1.title] ?
                                        this.state.moreInfos[this.state.match.question1.title] : 'لايوجد بيانات'
                                })
                            }
                        } else {
                            this.setState({
                                modalOpen: true,
                                value: 'لايوجد بيانات'
                            })
                        }
                    })
                }


                var header = options.htmlElement.querySelector("h5")

                if (header) {
                    var span = document.createElement("span")
                    span.innerHTML = "  "
                    header.appendChild(span)
                    header.appendChild(btn)
                }
            })

            return (


                <div style={{
                    paddingBottom: 90,
                    overflow: 'auto'
                    // padding-bottom: 90px;
                    // overflow: auto;
                }}>


                    <Modal
                      title="تفاصيل"
                        visible={this.state.modalOpen}
                        onOk={()=>this.setState({modalOpen :false})}
                        onCancel={()=>this.setState({modalOpen :false})}
                      footer={null}
                    >
                        {
                           typeof  this.state.value === 'string'  ? this.state.value :

                               <JsonToTable styles={{direction : 'rtl'}} json={this.state.value} />

                        }

                    </Modal>





                    <Affix
                        offsetTop={81}
                    >
                        <div
                            style={{
                                width: '100%',
                                height: 'auto',
                                minHeight: '50px',
                                backgroundColor: '#1bb393',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                boxShadow: '0px 0px 2px 1px rgba(235,235,235,1)'
                            }}
                        >
                            <h3
                                style={{
                                    fontFamily: 'Tajawal',
                                    color: 'white',
                                    textAlign: 'center',
                                    marginTop: '9px'
                                }}
                            >
                                {this.props.survey && this.props.survey.title ? this.props.survey.title : null}
                            </h3>
                        </div>
                    </Affix>

                    <div style={{
                        direction: 'rtl',
                        paddingLeft: 50,
                        paddingRight: 50,
                        marginTop: 50,
                        position: 'relative',
                        textAlign: 'right',
                        display: 'flex',
                        flexDirection: 'row',

                    }}>

                        <div style={{
                            //  flex: 2,
                            boxShadow: '0px 6px 19px -8px rgba(140,140,140,1)',
                            //overflow: 'scroll',
                            //height: '550px'
                        }}>
                            <Survey.Survey
                                // get a ref from the survey to pull data from it
                                ref={survey => {
                                    if (survey && survey.survey) {
                                        this.survey = survey.survey
                                    }
                                }}
                                style={{
                                    direction: 'rtl'
                                }}
                                model={model}
                                onValueChanged={this.onValueChanged.bind(this)}
                            />
                        </div>

                        <div
                            style={{
                                width: '5%'
                            }}
                        />

                        <Affix
                            offsetTop={181}
                        >
                            <div style={{
                                //flex: '1',
                                boxShadow: '0px 0px 2px 1px rgba(235,235,235,1)',
                                backgroundColor: 'white',
                                //height: '5%',
                                //maxWidth: '600px',
                                //width: '200px',
                                display: 'flex',
                                flexDirection: 'column',
                                position: 'relative'
                            }}>

                                <div style={{ height: 10 }} />

                                <Divider dashed>
                                <span
                                    style={{
                                        fontFamily: 'Tajawal',
                                        fontWeight: 'bold'
                                    }}
                                >
                                    تفاصيل الحالة
                                </span>
                                </Divider>

                                <div
                                    style={{
                                        padding: 10
                                    }}
                                >
                                    <Descriptions
                                        title=''
                                        column={1}
                                        bordered
                                    >
                                        {
                                            this.state.userDetails && Object.entries(this.state.userDetails).map((detail, index) => (
                                                <Descriptions.Item
                                                    key={`${index}`}
                                                    label={mapUserDetailToString(detail[0])}
                                                >
                                                    {detail[1] || <Icon type="small-dash" />}
                                                </Descriptions.Item>
                                            ))
                                        }
                                    </Descriptions>
                                </div>

                                {
                                    this.props.survey && this.props.survey._id

                                        ? <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                bottom: 5,
                                                width: '100%',
                                                minHeight: '50px',

                                            }}
                                        >
                                            <Divider dashed />

                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                padding: 10,
                                                width: '100%',
                                                flexWrap : 'wrap'
                                                }}>
                                                    <CommentModal
                                                    userId={this.props.currentUser._id}
                                                    surveyId={this.props.survey._id}
                                                    regionId={this.props.survey.region}
                                                />
                                                  <>
                                                    <Button
                                                        style={{width: '100%', margin: 8}}
                                                        size='large'
                                                        type='dashed'
                                                        onClick={() => {
                                                            const results = {
                                                                ...this.survey.data,
                                                                draft: true
                                                            }

                                                            const recordId = this.props.match.params.recordId
                                                            const surveyId = this.props.survey._id


                                                            if (this.props.survey.type === 'fiche4')
                                                                this.getScore(this.survey.data)


                                                            //this.props.onSubmit(surveyId, recordId, results)

                                                            //<Route path='/surveys/:surveyId/:recordId/results' component={SurveyResult} />

                                                            saveResult(surveyId, recordId, results)
                                                                .then(() => {
																	//this.props.history.push(`/app/regions/${this.props.survey.region}`)
																	this.props.history.replace(`/app/regions/${this.props.survey.region}/records`)
                                                                })

                                                        }}

                                                    >
                                                        حفظ النموذج كمسودة
                                                    </Button>
                                                    <Popconfirm
                                                        placement='top'
                                                        title='تفعيل الإستمارة'
                                                        onConfirm={
                                                            () => {
                                                                if (Object.keys(this.survey.data).length < (this.props.survey.minQuestions || 0)) {
                                                                    alert('يجب عليك الإجابة على الأقل 10 أسئلة قبل تقديم الإستمارة ')
                                                                } else {
                                                                    const results = {
                                                                        ...this.survey.data,
                                                                        draft: false
                                                                    }

                                                                    const recordId = this.props.match.params.recordId
                                                                    const surveyId = this.props.survey._id

                                                                    if (this.props.survey.type === 'fiche4')
                                                                        this.getScore(this.survey.data)

                                                                    saveResult(surveyId, recordId, results)
                                                                        .then(() => {
																			this.props.history.replace(`/app/regions/${this.props.survey.region}/records`)
                                                                            //this.props.history.push(`/app/regions/${this.props.survey.region}`)
                                                                        })
                                                                }
                                                            }
                                                        }
                                                        okText={'حسنا'}
                                                        cancelText={'إلغاء'}
                                                    >
                                                        <Button
                                                            size='large'
                                                            type='primary'
                                                            style={{width: '100%'}}
                                                        >
                                                            تفعيل الإستمارة
                                                        </Button>
                                                    </Popconfirm>
                                                  </>
                                            </div>
                                        </div>
                                        : null
                                }
                            </div>
                        </Affix>

                    </div>
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => {
    return {
        survey: getSurvey(state.survey),
        isLoading: getFetchStatus(state.survey),
        error: getFetchError(state.survey),
        isSuccess: getSubmitStatus(state.survey),
        currentUser: state.auth.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onSubmit: (surveyId, recordId, results, success) => dispatch(submitResult(surveyId, recordId, results, success)),
        fetchSurvey: bindActionCreators(fetchSurvey, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SurveyPage))
