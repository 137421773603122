import React from 'react'
import { createComment } from '../api'
//import { Modal, Button, Form } from 'semantic-ui-react'

import { Modal, Form, Button, message, Input, notification } from 'antd'

const { TextArea } = Input

class CommentModal extends React.Component {

    state = {
        title: '',
        content: '',
        open: false
    }

    handleSubmit = (e) => {
        e.preventDefault()
        const { userId, surveyId, regionId } = this.props

        this.props.form.validateFields((err, values) => {
            if (!err) {
                const { title, content } = values

                createComment(userId, surveyId, regionId, title, content)
                    .then(res => {
                        this.setState({
                            open: false
                        })

                        notification['success']({
                            message: 'لقد نجحت العملية', description: 'تم إضافة التعليق '
                        })

                        this.props.form.resetFields()
                    })
            }
        })


        // const { title, content } = this.state

        // createComment(userId, surveyId, regionId, title, content)
        //     .then(res => {
        //         this.setState({
        //             open: false
        //         })
        //     })
    }

    render () {

        const { getFieldDecorator } = this.props.form

        return (
            <div>
                <Button
                    size='large'
                    type='default'
                    style={{width : '100%'}}
                    onClick={() => {
                        this.setState({ open: true })
                    }}
                >
                    تعليق
                </Button>

                <Modal
                    title='إضافة تعليق'
                    centered
                    visible={this.state.open}
                    okText={'حسنا'}
                    cancelText={'إلغاء'}
                    onOk={this.handleSubmit.bind(this)}
                    onCancel={() => {
                        this.setState({ open: false })
                    }}
                >
                    <Form layout='vertical'>
                        <Form.Item label='عنوان التعليق'>
                            {
                                getFieldDecorator('title', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Field must be filled!'
                                        }
                                    ]
                                })(<Input size='large' />)
                            }
                        </Form.Item>

                        <Form.Item label='محتوى التعليق'>
                            {
                                getFieldDecorator('content', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Field must be filled!'
                                        }
                                    ]
                                })(<TextArea rows={4} />)
                            }
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        )


        // return (
        //     <Modal
        //         ref={modal => this.modal = modal}
        //         className='fixModal'
        //         open={this.state.open}
        //         style={{ direction: 'rtl' }}
        //         closeOnDimmerClick
        //         onClose={() => this.setState({ open: false })}
        //         trigger={
        //             <Button
        //                 style={{
        //                     // position: 'absolute',
        //                     // bottom: 10,
        //                     // right: '50%'
        //                 }}
        //                 circular
        //                 size='massive'
        //                 icon='comment'
        //                 onClick={() => this.setState({ open: true })}
        //             />
        //         }
        //     >
        //         <Modal.Header>إضافة تعليق</Modal.Header>
        //         <Modal.Content>
        //             <Form onSubmit={this.handleSubmit}>
        //                 <Form.Field required>
        //                     <label>عنوان التعليق</label>
        //                     <input
        //                         placeholder='عنوان التعليق'
        //                         value={this.state.title}
        //                         onChange={(event) => this.setState({ title: event.target.value })}
        //                     />
        //                 </Form.Field>
        //                 <Form.Field required>
        //                     <label>محتوى التعليق</label>
        //                     <textarea
        //                         placeholder='محتوى التعليق'
        //                         value={this.state.content}
        //                         onChange={(event) => this.setState({ content: event.target.value })}
        //                     />
        //                 </Form.Field>
        //                 <Button type='submit'>تقديم التعليق</Button>
        //             </Form>
        //         </Modal.Content>
        //     </Modal>
        //)
    }
}

export default Form.create()(CommentModal)
