import {
    FETCH_RECORDS_REQUEST,
    FETCH_RECORDS_REQUEST_SUCCESS,
    FETCH_RECORDS_REQUEST_FAIL, GET_PVS, GET_PVS_FAIL
} from '../constants/action-types'

export default function (state = { pvs: [], error: null }, action) {
    switch (action.type) {
        case GET_PVS:
            return {
                error: null,
                pvs: action.payload
            }

        case GET_PVS_FAIL:
            return {
                error: action.payload,
                pvs: []
            }
        default:
            return state
    }
}
