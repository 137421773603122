
import React, { useState, useEffect } from 'react'

import { withRouter } from 'react-router-dom'
import { Card, Spin, Button, Popconfirm } from 'antd'
import { fetchCommentsByRegion, deleteComment } from '../api'

function Comments ({ match }) {

	const [comments, setComments] = useState([])
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		async function loadComments () {
			setLoading(true)
			
			const regionId = match.params.id
			const comments = await fetchCommentsByRegion(regionId)
			
			setComments(comments)
			setLoading(false)
		}

		loadComments()
	}, [])

	if (loading) {
		return (
			<div
				style={{
					flex: 1,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center'
				}}
			>
				<Spin size='large'/>
			</div>
		)
	} else {
		return (
			<div
				style={{
					flex: 1,
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'flex-end',
					direction: 'rtl'
				}}
			>
				{
					comments.map((comment, index) => (
						<Card
							key={`${index}`}
							hoverable
							type='inner'
							style={{
								//width: 250
								marginBottom: 16
							}}
							extra={
								<Popconfirm
									title='هل أنت واثق؟'
									okText='حسنا'
									cancelText='إلغاء'
									onConfirm={ async () => {
										setLoading(true)

										await deleteComment(comment._id)
										const comments = await fetchCommentsByRegion(match.params.id)
										
										setComments(comments)
										setLoading(false)
									}}
								>
									<Button
										type='danger'
										icon='delete'
									/>
								</Popconfirm>
							}
							title={comment.title}
							bordered={false}
						>
							{comment.content}
						</Card>
					))
				}
			</div>
		)
	}
}

export default withRouter(Comments)