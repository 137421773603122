import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Form, Col, Row, Input, Button, Drawer, Select, InputNumber } from 'antd'

import {
	reinitRequest,
	registerRequest,
	fetchRegionsRequest,
	registerRequestFail,
	registerRequestSuccess,
} from '../actions'

import { bindActionCreators } from 'redux'

import '../assets/newuserform.css'

const { Option } = Select

class NewUserForm extends Component {

	constructor(props) {
		super(props)

		this.state = {
			newUserDrawerVisible: false
		}
	}

	componentWillReceiveProps(nextProps, nextState) {
		if (nextProps.isCreated) {
			this.setState({
				newUserDrawerVisible: false
			})
		}
	}

	onSubmit = e => {
		e.preventDefault()

		const { match, form, fetchRegions, onSubmit } = this.props
		const region = match.params.id

		form.validateFields((err, values) => {
			if (err)
				return

			onSubmit({
				...values,
				region: region
			}).then((res) => {
				fetchRegions()

				this.setState({
					newUserDrawerVisible: false
				})
			})
			.catch(error => {
				if (process.env.NODE_ENV === 'development')
					console.log(error)
			})
		})
	}

	render() {

		const { getFieldDecorator } = this.props.form

		return (
			<>
				<Button
					type='primary'
					size='large'
					onClick={() => this.setState({ newUserDrawerVisible: true })}
				>
					مستخدم جديد
				</Button>

				<Drawer
					title="مستخدم جديد"
					placement="left"
					width={520}
					closable={false}
					onClose={() => this.setState({ newUserDrawerVisible: false })}
					visible={this.state.newUserDrawerVisible}
				>
					<Form
						layout='vertical'
						onSubmit={this.onSubmit.bind(this)}
					>
						<Row gutter={16}>
							<Row gutter={16}>
								<Col span={8}>
									<Form.Item label="الجنس">
										{getFieldDecorator('gender', {
											initialValue: 'male',
											rules: [{ required: true, message: 'يرجى اختيار الجنس' }]
										})(
											<Select
												size='large'
												placeholder='الجنس'
												style={{
													direction: 'rtl',
													fontFamily: 'Tajawal'
												}}
											>
												<Option
													value='male'
													style={{
														fontFamily: 'Tajawal',
														textAlign: 'right'
													}}
												>
													ذكر
												</Option>

												<Option
													value='female'
													style={{
														fontFamily: 'Tajawal',
														textAlign: 'right'
													}}
												>
													انثى
												</Option>
											</Select>,
										)}
									</Form.Item>
								</Col>

								<Col span={8}>
									<Form.Item label='العمر'>
										{
											getFieldDecorator('age', {
												rules: [
													{
														required: true,
														message: 'يرجى إدخال العمر'
													}
												]
											})
												(<InputNumber style={{width: '100%'}} size='large' min={18} max={60} placeholder='العمر' />)
										}
									</Form.Item>
								</Col>

								<Col span={8}>
									<Form.Item label="اسم المستخدم">
										{
											getFieldDecorator('username', {
												rules: [
													{
														required: true,
														message: 'يرجى إدخال اسم المستخدم'
													}
												]
											})
												(<Input size='large' placeholder='اسم المستخدم' />)
										}
									</Form.Item>
								</Col>
							</Row>

							<Form.Item label='البريد الإلكتروني'>
								{getFieldDecorator('email', {
									rules: [
										{
											type: 'email',
											message: 'البريد الإلكتروني غير صالح',
										},
										{
											required: true,
											message: 'يرجى إدخال البريد الإلكتروني',
										},
									],
								})(<Input size='large' placeholder='يرجى إدخال البريد الإلكتروني' />)}
							</Form.Item>

							<Form.Item label="كلمه السر">
								{
									getFieldDecorator('password', {
										rules: [
											{
												required: true,
												message: 'يرجى إدخال كلمة المرور'
											}
										]
									})
										(<Input.Password size='large' placeholder='يرجى إدخال كلمة المرور' />)
								}
							</Form.Item>

							<Form.Item label="الدور">
								{getFieldDecorator('role', {
									initialValue: 'jeune',
									rules: [{ required: true, message: 'يرجى اختيار الوظيفة' }]
								})(
									<Select
										size='large'
										placeholder='الدور'
										style={{
											direction: 'rtl',
											fontFamily: 'Tajawal'
										}}
									>
										<Option
											value='jeune'
											style={{
												fontFamily: 'Tajawal',
												textAlign: 'right'
											}}
										>
											شاب مرافق جسور
										</Option>

										<Option
											value='social'
											style={{
												fontFamily: 'Tajawal',
												textAlign: 'right'
											}}
										>
											أخصّائي إجتماعي
										</Option>

										<Option
											value='psy'
											style={{
												fontFamily: 'Tajawal',
												textAlign: 'right'
											}}
										>
											أخصّائي نفساني
										</Option>

										<Option
											value='panel'
											style={{
												fontFamily: 'Tajawal',
												textAlign: 'right'
											}}
										>
											عضو في مجلس جسور
										</Option>
										<Option
											value='admin'
											style={{
												fontFamily: 'Tajawal',
												textAlign: 'right'
											}}
										>
											مشرف
										</Option>
									</Select>,
								)}
							</Form.Item>
						</Row>

						<div
							style={{
								position: 'absolute',
								bottom: 0,
								width: '100%',
								borderTop: '1px solid #e8e8e8',
								padding: '10px 16px',
								textAlign: 'right',
								left: 0,
								background: '#fff',
								borderRadius: '0 0 4px 4px',
							}}
						>
							<Button
								style={{
									marginRight: 8,
								}}
								onClick={() => this.setState({ newUserDrawerVisible: false })}
							>
								إلغاء
							</Button>
							<Button
								type="primary"
								htmlType='submit'
							>
								حسنا
							</Button>
						</div>
					</Form>
				</Drawer>
			</>
		)
	}
}

function mapStateToProps(state) {
	return {
		isCreated: state.regions.isCreated
	}
}

function mapDispatchToProps(dispatch) {
	return {
		fetchRegions: bindActionCreators(fetchRegionsRequest, dispatch),
		onSubmit: (values) => {
			return dispatch(registerRequest({
				name: values.username,
				gender: values.gender,
				age: values.age,
				email: values.email.toLowerCase(),
				password: values.password,
				role: values.role,
				region: values.region
			})).then(res => {
				dispatch(registerRequestSuccess(res))
				dispatch(reinitRequest())
				return Promise.resolve(res)
			}).catch(err => {
				if (process.env.NODE_ENV === 'development')
					console.log(err)
				dispatch(registerRequestFail(err))
				return Promise.reject(err)
			})
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(withRouter(NewUserForm)))
