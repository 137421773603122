import React from 'react'
import {withRouter} from 'react-router-dom'
import {Button, Popconfirm} from 'antd'
import {updateRecord} from "../api";

function NextNode({lastSurvey, all_surveys, history, match, record, results, currentUser , closeDrawer}) {

    const openRecord = () => {

        let rec = record
        rec.status = {
            user: currentUser,
            closed: false
        }
        updateRecord(record._id, rec)
        closeDrawer(false)
    }

    if (record.status.closed) {
        return (

                <Popconfirm placement="topLeft" title={'إعادة فتح الملف '} onConfirm={()=>{openRecord()}} okText="نعم " cancelText="لا ">
                <Button
                    block
                    disabled={currentUser.role === 'jeune'}
                >
                    ملف مغلق
                </Button>
            </Popconfirm>

        )

    }

    if (
        lastSurvey &&
        lastSurvey.survey.type === 'fiche0' &&
        !lastSurvey.draft &&
        all_surveys.find(survey => survey.type === 'fiche1')
    ) {
        return (
            <Button
                block
                onClick={() => {
                    history.push(`${match.url}/${record._id}/surveys/${all_surveys.find(survey => survey.type === 'fiche1')._id}`)
                }}
            >
                جدول ملخص للهشاشة
            </Button>
        )
    }

    if (
        lastSurvey &&
        lastSurvey.survey.type === 'fiche1' &&
        !lastSurvey.draft &&
        all_surveys.find(survey => survey.type === `fiche2`) &&
        all_surveys.find(survey => survey.type === `fiche3`)
    ) {
        return (
            <div>
                <Button
                    block
                    style={{
                        marginBottom: 10
                    }}
                    onClick={() => {
                        history.push(`${match.url}/${record._id}/surveys/${all_surveys.find(survey => survey.type === 'fiche2')._id}`)
                    }}
                >
                    استبيان رصد وتحديد وضعيات
                </Button>

                <Button
                    block
                    onClick={() => {
                        history.push(`${match.url}/${record._id}/surveys/${all_surveys.find(survey => survey.type === 'fiche3')._id}`)
                    }}
                >
                    سلم تقييم الهشاشة
                </Button>
            </div>
        )
    }

    if (
        lastSurvey &&
        lastSurvey.survey.type === 'fiche2' &&
        all_surveys.find(survey => survey.type === `fiche3`)
    ) {
        return (
            <Button
                block
                onClick={() => {
                    history.push(`${match.url}/${record._id}/surveys/${all_surveys.find(survey => survey.type === 'fiche3')._id}`)
                }}
            >
                سلم تقييم الهشاشة
            </Button>
        )
    }

    if (
        lastSurvey &&
        lastSurvey.survey.type === 'fiche3' &&
        !lastSurvey.draft &&
        results.find(a => a.survey.type === 'fiche2') &&
        !results.find(a => a.survey.type === 'fiche2').draft &&
        all_surveys.find(survey => survey.type === `fiche4`)
    ) {
        return (
            <Button
                block
                onClick={() => {
                    history.push(`${match.url}/${record._id}/surveys/${all_surveys.find(survey => survey.type === 'fiche4')._id}`)
                }}
            >
                تحديد عوامل الخطر والوقاية
            </Button>
        )
    }

    if (
        lastSurvey &&
        lastSurvey.survey.type === 'fiche3' &&
        !results.find(a => a.survey.type === 'fiche2') &&
        all_surveys.find(survey => survey.type === `fiche2`)
    ) {
        return (
            <Button
                block
                onClick={() => {
                    history.push(`${match.url}/${record._id}/surveys/${all_surveys.find(survey => survey.type === 'fiche2')._id}`)
                }}
            >
                استبيان رصد وتحديد وضعيات
            </Button>
        )
    }

    if (
        lastSurvey &&
        lastSurvey.survey.type === 'fiche4' &&
        !lastSurvey.draft &&
        all_surveys.find(survey => survey.type === `fiche5`)
    ) {
        return (
            <Button
                block
                onClick={() => {
                    history.push(`${match.url}/${record._id}/surveys/${all_surveys.find(survey => survey.type === 'fiche5')._id}`)
                }}
            >
                تحديد حاجيات الشاب
            </Button>
        )
    }

    if (
        lastSurvey &&
        lastSurvey.survey.type === 'fiche5' &&
        !lastSurvey.draft &&
        all_surveys.find(survey => survey.type === `fiche6`)
    ) {
        return (
            <Button
                block
                onClick={() => {
                    history.push(`${match.url}/${record._id}/surveys/${all_surveys.find(survey => survey.type === 'fiche6')._id}`)
                }}
            >
                جذاذة مداولات مجلس جسور
            </Button>
        )
    }

    if (
        lastSurvey &&
        lastSurvey.survey.type === 'fiche6' &&
        !lastSurvey.draft &&
        all_surveys.find(survey => survey.type === `fiche7`)
    ) {
        return (
            <Button
                block
                onClick={() => {
                    history.push(`${match.url}/${record._id}/surveys/${all_surveys.find(survey => survey.type === 'fiche7')._id}`)
                }}
            >
                بطاقة المتابعة والتقييم
            </Button>
        )
    }

    return null
}

export default withRouter(NextNode)
