/* global location */
/* eslint no-restricted-globals: ["off", "location"] */

import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { fetchRegionsRequest } from '../actions'
import { withRouter } from 'react-router'
import { Icon, Card, Popconfirm, message, Spin, Button, Modal, Select, notification } from 'antd'
import { cloneSurvey, fetchUserSurveys, deleteSurveyHard } from '../api'
import NewSurvey from './NewSurvey'
import '../assets/style.css'

class RegionSurveys extends React.Component {

	_isMounted = false

	constructor(props) {
		super(props)

		this.state = {
			all_surveys: null,
			loading: true,
			regionToClone: {},
			surveyToClone: {},
			regionsToClone: [],
			surveysToClone: [],
			copyModalOpen: false,
			cloningSurveyInProgress: false
		}
	}

	fetchData = async () => {
		fetchUserSurveys(this.props.currentUser)
			.then(surveys => {
				if (this._isMounted) {
					this.setState({
						all_surveys: surveys.filter(survey => survey.region === this.props.match.params.id),
						loading: false
					}, () => {
						const regionOptions = this.props.regions.map(item => ({
							key: item._id,
							text: item.nom,
							value: item,
						}))

						const surveyOptions = this.state.all_surveys.map(item => ({
							key: item._id,
							text: item.title,
							value: item,
						}))

						this.setState({
							regionsToClone: regionOptions,
							surveysToClone: surveyOptions,
						})
					})
				}
			})
	}

	componentDidMount() {
		this._isMounted = true
		this.fetchData()
	}

	componentWillUnmount() {
		this._isMounted = false
	}

	render() {
		const { regions } = this.props
		const region = regions.find(region => region._id === this.props.match.params.id)

		if (this.state.loading) {
			return (
				<div
					style={{
						flex: 1,
						height: '100%',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center'
					}}
				>
					<Spin size='large' />
				</div>
			)
		}

		return (
			<div
				style={{
					flex: 1,
					display: 'flex',
					justifyContent: 'flex-end',
					flexDirection: 'column',
					direction: 'rtl'
				}}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'space-between'
					}}
				>
					<div
						style={{
							flexDirection: 'row',
							display: 'flex',
							marginBottom: 16
						}}
					>
						<NewSurvey region={region} />

						{
							this.props.currentUser.role === 'super_admin'

							?	<Button
									icon='copy'
									size='large'
									type='dashed'
									onClick={() => this.setState({copyModalOpen: true})}
								>
									نسخ إستمارة
								</Button>

							:	null
						}

						<Modal
							title='نسخ إستمارة'
							visible={this.state.copyModalOpen}
							onOk={() => {
								this.setState({ cloningSurveyInProgress: true })

								cloneSurvey(this.state.surveyToClone, this.state.regionToClone._id)
									.then(() => {
										this.setState({
											cloningSurveyInProgress: false,
											copyModalOpen: false
										})

										notification['success']({
											message: 'لقد نجحت العملية',
											description: ''
										})

										// setTimeout(() => {
										// 	this.props.history.replace(`/app/regions/${this.state.regionToClone._id}/surveys`)
										// }, 1000)
									})
							}}
							onCancel={() => this.setState({copyModalOpen: false})}
							okText={'حسنا'}
							cancelText={'إلغاء'}
							bodyStyle={{
								display: 'flex',
								flexDirection: 'column'
							}}
							confirmLoading={this.state.cloningSurveyInProgress}
						>
							<Select
								size='large'
								style={{ width: '100%' }}
								placeholder="الإستمارة التي تريد نسخها"
								onChange={value => {
									const selectedSurvey = this.state.surveysToClone.find(survey => survey.key === value)
									this.setState({ surveyToClone: selectedSurvey.value })
								}}
							>
								{
									this.state.surveysToClone.map(survey => (
										<Select.Option
											key={survey.key}
											value={survey.key}
											style={{
												fontFamily: 'Tajawal',
												textAlign: 'right'
											}}
										>
											{survey.text}
										</Select.Option>
									))
								}
							</Select>

							<Icon
								type='arrow-down'
								style={{
									fontSize: 20,
									marginTop: 10,
									marginBottom: 10
								}}
							/>

							<Select
								size='large'
								style={{ width: '100%' }}
								placeholder='المنطقة المستهدفة'
								onChange={value => {
									const selectedRegion = this.state.regionsToClone.find(region => region.key === value)
									this.setState({ regionToClone: selectedRegion.value })
								}}
							>
								{
									this.state.regionsToClone.map(region => (
										<Select.Option
											key={region.key}
											value={region.key}
											style={{
												fontFamily: 'Tajawal',
												textAlign: 'right'
											}}
										>
											{region.text}
										</Select.Option>
									))
								}
							</Select>
						</Modal>
					</div>
				</div>

				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						flexWrap: 'wrap'
					}}
				>
					{
						this.state.all_surveys && this.state.all_surveys.length > 0

						?	this.state.all_surveys.map(survey => {
								if (this.props.currentUser.role === 'super_admin' || this.props.currentUser.role === 'admin') {
									return (
										!survey.deleted
										?	<Card
												key={`${survey._id}`}
												hoverable
												title={
													<h4
														style={{
															whiteSpace: 'normal'
														}}
													>
														{survey.title}
													</h4>
												}
												style={{
													marginLeft: 16,
													marginBottom: 16,
													width: 300,
													display: 'flex',
													flexDirection: 'column'
												}}
												headStyle={{
													flex: 1,
													fontFamily: 'Tajawal'
												}}
												type='inner'
											>
												<Popconfirm
													title="هل أنت متأكد من حذف الإستمارة؟"
													onConfirm={(e) => {
														deleteSurveyHard(survey._id).then(res => {
															this.fetchData()
														})
														message.success(' تم الحذف بنجاح ')
													}}
													onCancel={() => {
														message.error('الغاء الحذف')
													}}
													okText="نعم"
													cancelText="لا"
												>
													<Button type='danger' icon='delete' />
												</Popconfirm>

												<Button
													style={{
														marginRight: 6
													}}
													type='default'
													icon='edit'
													onClick={() => this.props.history.push(`${this.props.match.url}/${survey._id}/edit`)}
												/>
											</Card>

										:	null
									)
								} else {
									return (
										survey && !survey.deleted ?
											<Card
												key={`${survey._id}`}
											>
												<Card.Content header={survey.title} />
												<Card.Content description={survey.subTitle} />
												<Card.Content extra>
													<Button
														fluid
														content={`Remplir un ${survey.title}`}
														icon='right arrow'
														labelPosition='right'
													/>
												</Card.Content>
											</Card> : null
									)
								}
							})

						:	null
					}
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	regions: state.regions.regions,
	isLoading: state.regions.isLoading,
	loadError: state.regions.error,
	currentUser: state.auth.user,
	isCreated: state.regions.isCreated
})

const mapDispatchToProps = (dispatch) => {
	return {
		fetchRegions: bindActionCreators(fetchRegionsRequest, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RegionSurveys))
