import * as api from '../api'
import * as actionTypes from '../constants/action-types'

export const registerRequest = (params) => dispatch => {
	dispatch({
		type: actionTypes.REGISTER_REQUEST,
		params
	})
	return api.createAndAddUserToRegion(params)
}

export const reinitRequest = () => dispatch => {
	dispatch({
		type: actionTypes.REGISTER_REQUEST
	})
}

export const registerRequestSuccess = (res) => ({
	type: actionTypes.REGISTER_REQUEST_SUCCESS,
	res
})

export const registerRequestFail = (err) => ({
	type: actionTypes.REGISTER_REQUEST_FAIL,
	payload: err
})
