import React from 'react'

import {
	Switch,
	Route,
	withRouter,
	Redirect
} from 'react-router-dom'

import { connect } from 'react-redux'
import LoginPageV2 from './pages/LoginPageV2'
import RegionPage from './pages/RegionPage'
import SiderDemo from './SiderDemo'

import { userRequestLogout } from './actions'

function PrivateRoute({ component: Component, isAuthenticated, ...rest }) {
	return (
		<Route
			{...rest}
			render={props => {
				if (isAuthenticated) {
					return (
						<Component {...props} />
					)
				} else {
					return (
						<Redirect to={'/login'} />
					)
				}
			}}
		/>
	)
}

class App extends React.Component {

	timer

	componentDidMount () {
		if (process.env.NODE_ENV === 'production') {
			window.onmousemove = this.resetTimer.bind(this)
			window.onmousedown = this.resetTimer.bind(this)
			window.onclick = this.resetTimer.bind(this)
			window.onscroll = this.resetTimer.bind(this)
			window.onkeypress = this.resetTimer.bind(this)
		}
	}

	logout = () => {
		this.props.logout()
		this.props.history.replace('/login')
	}

	resetTimer = () => {
		this.timer && clearTimeout(this.timer)
		this.timer = setTimeout(this.logout.bind(this), 1800000) // 30 minutes idle => logout
	}

	componentWillMount() {
		document.body.style.backgroundColor = '#F8F8F8'
	}

	componentWillUnmount() {
		document.body.style.backgroundColor = null
		this.timer && clearTimeout(this.timer)
		window.onmousemove = undefined
		window.onmousedown = undefined
		window.onclick = undefined
		window.onscroll = undefined
		window.onkeypress = undefined
	}

	render() {
		const { user } = this.props
		const isAuthenticated = user != null

		return (
			<Switch>
				<Route path={'/'} exact component={LoginPageV2} />
				<Route path='/login' exact component={LoginPageV2} />
				<PrivateRoute path={'/app'} component={SiderDemo} isAuthenticated={isAuthenticated} />
			</Switch>
		)
	}
}

function mapStateToProps(state) {
	return {
		user: state.auth.user
	}
}

function mapDispatchToProps (dispatch) {
	return {
		logout: () => dispatch(userRequestLogout())
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App))