import * as api from '../api'
import initSurvey from '../constants/InitSurvey'
import * as actionTypes from '../constants/action-types'

export const fetchSurveysRequest = user => dispatch => {
	dispatch({
		type: actionTypes.FETCH_SURVEYS_REQUEST,
		payload: user
	})

	return api.fetchUserSurveys(user).then(res => {
		dispatch({
			type: actionTypes.FETCH_SURVEYS_REQUEST_SUCCESS,
			payload: res
		})
	}).catch(err => {
		dispatch({
			type: actionTypes.FETCH_SURVEYS_REQUEST_FAIL,
			payload: err
		})
	})
}

export const createSurveyRequestSuccess = (res) => ({
	type: actionTypes.CREATE_SURVEY_REQUEST_SUCCESS,
	payload: res
})

export const createSurveyRequestFail = (err) => ({
	type: actionTypes.CREATE_SURVEY_REQUEST_FAIL,
	payload: err
})

export const createSurveyRequest = (userId, region) => dispatch => {
	dispatch({
		type: actionTypes.CREATE_SURVEY_REQUEST,
		userId,
		payload: { ...initSurvey, region: region._id }
	})
	return api.createSurvey(userId, { ...initSurvey, region: region._id })
		.then(res => dispatch(createSurveyRequestSuccess(res)))
		.catch(err => dispatch(createSurveyRequestFail(err)))
}

export const resetCreateSurvey = () => ({
	type: actionTypes.RESET_CREATE_SURVEY
})