
import React, { useState } from 'react'

import {
	Form,
	Input,
	Modal,
	Button,
	message
} from 'antd'

import { withRouter } from 'react-router-dom'

import '../assets/style.css'

function NewRecord ({ form, checklist, history, match }) {

	const [modalVisible, setModalVisible] = useState(false)
	const [createRecordInProgress, setCreateRecordInProgress] = useState(false)

	const onSubmit = e => {
		e.preventDefault()

		form.validateFields((err, values) => {
			if (!err) {
				history.push(`${match.url}/checklist${values.num_dossier}/surveys/${checklist._id}`)
				message.success(' تم الاضافة بنجاح  ')
			}
		})
	}

	const { getFieldDecorator } = form

	return (
		<>
			<Button
				size='large'
				type='primary'
				onClick={() => setModalVisible(true)}
			>
				إنشاء ملف
			</Button>

			<Modal
				title='إنشاء ملف'
				visible={modalVisible}
				onOk={() => {
					setCreateRecordInProgress(true)
				}}
				onCancel={() => setModalVisible(false)}
				okText={'حسنا'}
				cancelText={'إلغاء'}
				bodyStyle={{
					display: 'flex',
					flexDirection: 'column'
				}}
				confirmLoading={createRecordInProgress}
				cancelButtonProps={{
					disabled: createRecordInProgress
				}}
				okButtonProps={{
					form: 'form',
					htmlType: 'submit'
				}}
			>
				<Form id='form' onSubmit={onSubmit}>
					<Form.Item label='رقم الملف'>
						{
							getFieldDecorator('num_dossier', {
								rules: [
									{
										required: true,
										message: 'يرجى إدخال رقم الملف'
									}
								]
							})
							(<Input size='large' placeholder="رقم الملف" disabled={createRecordInProgress} />)
						}
					</Form.Item>
				</Form>
			</Modal>
		</>
	)
}

export default withRouter(Form.create()(NewRecord))