/* global location */
/* eslint no-restricted-globals: ["off", "location"] */

import React,{ Component } from 'react'
import { SurveyEditor } from 'surveyjs-editor'

import '../widgets.js'
import 'surveyjs-editor/surveyeditor.min.css'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { fetchSurveyForEdit, updateSurvey } from '../actions'

import { withRouter } from 'react-router-dom'

function s4() {
	return Math
		.floor((1 + Math.random()) * 0x10000)
		.toString(16)
		.substring(1)
}

function guid() {
    return s4() + s4() + '' + s4() + '' + s4() + '' + s4() + '' + s4() + s4() + s4()
}

class SurveyEditorComponent extends Component {

	/** @type { SurveyEditor } */
	editor

	componentDidMount() {

		this.editor = new SurveyEditor('surveyEditorContainer', {
			showJSONEditorTab: true,
			haveCommercialLicense: true,
			showEmbededSurveyTab: false,
			designerHeight: '500px'
		})

		this.editor.toolbarItems.push({
			id: 'fill-form',
			visible: true,
			title: 'Lien vers le remplissage du formulaire',
			action: () => {
				let thisSurveyId = this.props.match.params.surveyId

				window.open(`${location.origin}/surveys/${thisSurveyId}/test`)
			}
		})

		this.editor.saveSurveyFunc = this.save

		// props (edit survey from redux)

		this.editor.text = ``

		this.forceUpdate()

		let thisSurveyId = this.props.match.params.surveyId

		this.props.fetchSurveyForEdit(thisSurveyId)
	}

	componentWillReceiveProps (nextProps, nextState) {

		// Survey has been loaded for edit grab it from redux and set to the editor
		if (nextProps.survey) {
			this.editor.text = JSON.stringify(nextProps.survey)
		}
	}

	save = () => {
		let survey = JSON.parse(this.editor.text)

		if (survey.pages.length > 0) {
			survey.pages.map(page => {
				if (page.elements && page.elements.length > 0) {
					page.elements.map(element => {
						element._id = guid()
						return false
					})
				}
				return false
			})
		}

		let copy = this.props.survey

		copy.pages = survey.pages

		copy = {
			...copy,
			...survey
		}

		this.props.updateSurvey(copy)
	}

	render() {
		return (
			<div
				style={{
					direction: 'ltr',
					paddingLeft: 50,
					paddingRight: 50,
					marginTop: 25
				}}
			>
				<div id='surveyEditorContainer' />
			</div>
		)
	}
}

function mapStateToProps (state) {
	return {
		survey: state.edit.survey,
		//results: state.data.results,
		isDeleteSuccess: state.edit.isDeleteSuccess
	}
}

function mapDispatchToProps (dispatch) {
	return {
		updateSurvey: bindActionCreators(updateSurvey, dispatch),
		//deleteSurvey: bindActionCreators(deleteSurvey, dispatch),
		fetchSurveyForEdit: bindActionCreators(fetchSurveyForEdit, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SurveyEditorComponent))
