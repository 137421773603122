import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Form, Col, Row, Input, Button, Drawer } from 'antd'
import { connect } from 'react-redux'

import {
    createRegionRequest,
    createRegionRequestFail,
    createRegionRequestSuccess
} from '../actions/regions'

class NewRegionForm extends Component {

    constructor(props) {
        super(props)

        this.state = {
            newRegionDrawerVisible: false
        }
    }

    componentWillReceiveProps(nextProps, nextState) {
        if (nextProps.isCreated) {
			this.setState({
				newRegionDrawerVisible: false
			})
		}
    }

    onSubmit = (e) => {
        e.preventDefault()

        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.createRegion(values.nom_region)
            }
        })
    }

    render() {

        const { getFieldDecorator } = this.props.form

        return (
            <>
                <Button
                    //type='primary'
                    style={{
                        width: '17%',
                        margin: 34
                    }}
                    size='large'
                    onClick={() => this.setState({ newRegionDrawerVisible: true })}
                >
                    منطقة جديدة
                </Button>

                <Drawer
                    
                    title="منطقة جديدة"
                    placement="left"
                    width={520}
                    closable={false}
                    onClose={() => this.setState({ newRegionDrawerVisible: false })}
                    visible={this.state.newRegionDrawerVisible}
                >
                    <Form
                        layout='vertical'
                        onSubmit={this.onSubmit.bind(this)}
                    >
                        <Row gutter={16}>
                            <Form.Item label="اسم المنطقة">
                                {
                                    getFieldDecorator('nom_region', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'الرجاء إدخال اسم المنطقة'
                                            }
                                        ]
                                    })
                                    (<Input size='large' placeholder="الرجاء إدخال اسم المنطقة" />)
                                }
                            </Form.Item>
                        </Row>

                        <div
                            style={{
                                position: 'absolute',
                                bottom: 0,
                                width: '100%',
                                borderTop: '1px solid #e8e8e8',
                                padding: '10px 16px',
                                textAlign: 'right',
                                left: 0,
                                background: '#fff',
                                borderRadius: '0 0 4px 4px',
                            }}
                        >
                            <Button
                                style={{
                                    marginLeft: 8,
                                }}
                                onClick={() => this.setState({ newRegionDrawerVisible: false })}
                            >
                                إلغاء
                            </Button>
                            <Button
                                type="primary"
                                htmlType='submit'
                            >
                                حسنا
                            </Button>
                        </div>
                    </Form>
                </Drawer>
            </>
        )
    }
}

function mapStateToProps (state) {
    return {
        isCreated: state.regions.isCreated
    }
}

function mapDispatchToProps (dispatch) {
    return {
        createRegion: (values) => {
            return dispatch(createRegionRequest({
                nom: values
            })).then(res => {
                dispatch(createRegionRequestSuccess(res))
                return Promise.resolve(res)
            }).catch(err => {
                dispatch(createRegionRequestFail(err))
                return Promise.reject(err)
            })
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(NewRegionForm))