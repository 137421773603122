/**
 * TODO - 1 - implement the update on the match
 *
 */

import React, { useState, useEffect } from 'react'

import {
	Table,
	Divider,
	Tag,
	Button,
	Modal,
	Select,
	Icon,
	message,
	Popconfirm
} from 'antd'

import { withRouter } from 'react-router-dom'

import {
	fetchMatchesByRegion,
	fetchUserSurveys,
	addMatch,
	deleteMatch,
	updateMatch
} from '../api'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

function RegionMatch({ match, user }) {

	const [matches, setMatches] = useState([])
	const [all_surveys, setAll_surveys] = useState([])
	const [loading, setLoading] = useState(true)
	const [showAdd, setShowAdd] = useState(false)

	const [survey1, setSurvey1] = useState({})
	const [survey2, setSurvey2] = useState({})

	const [questionsList1, setQuestionsList1] = useState([])
	const [questionsList2, setQuestionsList2] = useState([])

	const [question1, setQuestion1] = useState({})
	const [question2, setQuestion2] = useState({})

	const [modalVisible, setModalVisible] = useState(false)

	const getquestionfroms = (type, survey) => {

		let q = []

		survey.value.pages.map(page => {
			page.elements.map(element => {
				q.push({
					key: element._id,
					text: element.title ? element.title.ar ? element.title.ar : element.title : element.name,
					value: element
				})
			})

		})

		if (type === 1)
			setQuestionsList1(q)
		else
			setQuestionsList2(q)
	}

	useEffect(() => {
		async function loadData () {
			setLoading(true)

			const surveys = await fetchUserSurveys(user)

			let all_surveys = surveys.filter(survey => survey.region === match.params.id).map(item => ({
				key: item._id,
				text: item.title,
				value: item
			}))

			setAll_surveys(all_surveys)

			const res = await fetchMatchesByRegion(match.params.id)

			let data = res.map((item, index) => {
				return {
					key: `${index}`,
					_id: item._id,
					survey1: all_surveys.find(a => a.key === item.survey1).text,
					survey2: all_surveys.find(a => a.key === item.survey2).text,
					question1: item.question1.title,
					question2: item.question2 ? item.question2.title : 'no title'
				}
			})

			setMatches(data)
			setLoading(false)
		}

		loadData()
	}, [])

	const columns = [
		{
			title: 'الإستبيان الأول',
			dataIndex: 'survey1',
			key: 'survey1'
		},
		{
			title: 'الاستبيان الثاني',
			dataIndex: 'survey2',
			key: 'survey2',
		},
		{
			title: 'السؤال الأول',
			dataIndex: 'question1',
			key: 'question1',
		},
		{
			title: 'السؤال الثاني',
			dataIndex: 'question2',
			key: 'question2',
		},
		{
			title: 'أفعال',
			dataIndex: 'actions',
			key: 'actions',
			render: (text, record, index) => {
				return (
					<>
						<Popconfirm
							title='هل أنت متأكد من أنك تريد حذف هذه المطابقة'
							onConfirm={async () => {
								setLoading(true)

								await deleteMatch(record._id)
								const res = await fetchMatchesByRegion(match.params.id)

								let data = res.map((item, index) => {
									return {
										key: `${index}`,
										_id: item._id,
										survey1: all_surveys.find(a => a.key === item.survey1).text,
										survey2: all_surveys.find(a => a.key === item.survey2).text,
										question1: item.question1.title,
										question2: item.question2 ?  item.question2.title : 'no title'
									}
								})

								setMatches(data)
								setLoading(false)
							}}
							onCancel={() => {
								message.error('لم يتم حذف المطابقة')
							}}
							okText="حسنا"
							cancelText="إلغاء"
						>
							<Button
								type='danger'
							>
								حذف
							</Button>
						</Popconfirm>
					</>
				)
			}
		}
	]

	return (
		<div
			style={{
				direction: 'rtl'
			}}
		>
			<Button
				type='primary'
				size='large'
				style={{
					marginBottom: 16
				}}
				onClick={() => setModalVisible(true)}
			>
				إضافة مطابقة
			</Button>

			<Table
				size='middle'
				loading={loading}
				bordered
				pagination={false}
				columns={columns}
				dataSource={matches}
				locale={{
					emptyText: 'لا يوجد تطابق'
				}}
				style={{
					backgroundColor: 'white',
					fontFamily: 'Tajawal'
				}}
			/>

			<Modal
				destroyOnClose
				title='إضافة مطابقة'
				visible={modalVisible}
				onOk={async () => {
					setLoading(true)

					await addMatch({
						survey1: survey1._id,
						survey2: survey2._id,
						question1: {
							_id: question1._id,
							title: question1.name
						},
						question2: {
							_id: question2._id,
							title: question2.name
						},
						region: match.params.id
					})

					const res = await fetchMatchesByRegion(match.params.id)

					let data = res.map((item, index) => {
						return {
							key: `${index}`,
							_id: item._id,
							survey1: all_surveys.find(a => a.key === item.survey1).text,
							survey2: all_surveys.find(a => a.key === item.survey2).text,
							question1: item.question1.title,
							question2: item.question2 ?  item.question2.title : 'no title'
						}
					})

					setMatches(data)
					setModalVisible(false)

					setLoading(false)
				}}
				onCancel={() => setModalVisible(false)}
				okText={'حسنا'}
				cancelText={'إلغاء'}
				bodyStyle={{
					display: 'flex',
					flexDirection: 'column'
				}}
				confirmLoading={loading}
			>
				<Select
					size='large'
					style={{ width: '100%' }}
					placeholder='الإستبيان الأول'
					onChange={value => {
						const selectedSurvey = all_surveys.find(survey => survey.key === value)
						getquestionfroms(1, selectedSurvey)
						setSurvey1(selectedSurvey.value)
					}}
				>
					{
						all_surveys.map(survey => (
							<Select.Option
								key={survey.key}
								value={survey.key}
								style={{
									fontFamily: 'Tajawal',
									textAlign: 'right'
								}}
							>
								{survey.text}
							</Select.Option>
						))
					}
				</Select>

				<Icon
					type='arrow-down'
					style={{
						fontSize: 20,
						marginTop: 10,
						marginBottom: 10
					}}
				/>

				<Select
					size='large'
					style={{ width: '100%' }}
					placeholder='الاستبيان الثاني'
					onChange={value => {
						const selectedSurvey = all_surveys.find(survey => survey.key === value)
						getquestionfroms(2, selectedSurvey)
						setSurvey2(selectedSurvey.value)
					}}
				>
					{
						all_surveys.map(survey => (
							<Select.Option
								key={survey.key}
								value={survey.key}
								style={{
									fontFamily: 'Tajawal',
									textAlign: 'right'
								}}
							>
								{survey.text}
							</Select.Option>
						))
					}
				</Select>

				<Icon
					type='swap'
					style={{
						fontSize: 20,
						marginTop: 10,
						marginBottom: 10
					}}
				/>

				<Select
					size='large'
					style={{ width: '100%' }}
					placeholder='السؤال الأول'
					onChange={value => {
						const selectedQuestion = questionsList1.find(q => q.key === value)
						setQuestion1(selectedQuestion.value)
					}}
				>
					{
						questionsList1.map(q => (
							<Select.Option
								key={q.key}
								value={q.key}
								style={{
									fontFamily: 'Tajawal',
									textAlign: 'right'
								}}
							>
								{q.text}
							</Select.Option>
						))
					}
				</Select>

				<Icon
					type='arrow-down'
					style={{
						fontSize: 20,
						marginTop: 10,
						marginBottom: 10
					}}
				/>

				<Select
					size='large'
					style={{ width: '100%' }}
					placeholder='السؤال الثاني'
					onChange={value => {
						const selectedQuestion = questionsList2.find(q => q.key === value)
						setQuestion2(selectedQuestion.value)
					}}
				>
					{
						questionsList2.map(q => (
							<Select.Option
								key={q.key}
								value={q.key}
								style={{
									fontFamily: 'Tajawal',
									textAlign: 'right'
								}}
							>

								{q.text}
							</Select.Option>
						))
					}
				</Select>
			</Modal>
		</div>
	)
}

function mapStateToProps (state) {
	return {
		user: state.auth.user
	}
}

export default connect(mapStateToProps, null)(withRouter(RegionMatch))
