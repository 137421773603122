import * as api from '../api'
import {
    FETCH_RECORDS_REQUEST,
    FETCH_RECORDS_REQUEST_SUCCESS,
    FETCH_RECORDS_REQUEST_FAIL,
    GET_PVS, GET_PVS_FAIL
} from "../constants/action-types";

export const fetchPvs = (regionId) => dispatch => {
    return api.fetchPvesByRegion( regionId).then(res => {
        dispatch({
            type: GET_PVS,
            payload: res
        })
    }).catch(err => {
        dispatch({
            type: GET_PVS_FAIL,
            payload: err
        })
    })
}
