import * as api from '../api'
import * as types from '../constants/action-types'

export const fetchRegionsRequest = () => dispatch => {
    dispatch({
        type: types.FETCH_REGIONS_REQUEST,
        payload: undefined
    })

    return api.fetchRegions().then(res => {
        dispatch({
            type: types.FETCH_REGIONS_REQUEST_SUCCESS,
            payload: res
        })
    }).catch(err => {
        dispatch({
            type: types.FETCH_REGION_REQUEST_FAIL,
            payload: err
        })
    })
}

export const createRegionRequest = (params) => dispatch => {
    dispatch({
        type: types.CREATE_REGION_REQUEST,
        params
    })
    return api.createRegion(params)
}

export const createRegionRequestSuccess = (res) => ({
    type: types.CREATE_REGION_REQUEST_SUCCESS,
    res
})

export const createRegionRequestFail = (err) => ({
    type: types.CREATE_REGION_REQUEST_FAIL,
    err
})

export const fetchRegion = regionId => dispatch => {
    dispatch({
        type: types.FETCH_REGION_REQUEST,
        regionId
    })

    api.fetchRegion(regionId).then(res => {
        dispatch({
            type: types.FETCH_REGION_REQUEST_SUCCESS,
            payload: res
        })
    }).catch(err => {
        dispatch({
            type: types.FETCH_REGION_REQUEST_FAIL,
            payload: err
        })
    })
}






export const editRegionRequest = (params) => dispatch => {
    dispatch({
        type: types.EDIT_REGION_REQUEST,
        params
    })
    return api.editRegion(params.regionId,params.region)
}

export const editRegionRequestSuccess = (res) => ({
    type: types.EDIT_REGION_REQUEST_SUCCESS,
    res
})

export const editRegionRequestFail = (err) => ({
    type: types.EDIT_REGION_REQUEST_FAIL,
    err
})










