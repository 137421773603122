import React from 'react'
import { connect } from 'react-redux'

import { Modal } from 'semantic-ui-react'
import RegionsList from '../components/RegionsList'
import { withRouter, Redirect } from 'react-router'
import NewRegionForm from '../components/NewRegionForm'
import { Button, Drawer, Icon, Form, Row, Col, Input } from 'antd'

class RegionsListPage extends React.Component {
	
	constructor(props) {
		super(props)

		this.state = {
			open: false,
			newRegionDrawerVisible: false
		}
	}

	render() {

		if (this.props.user.role !== 'super_admin') {
			return (
				<Redirect to={`/app/regions/${this.props.user.region}/records`} />
			)
		}
		return (
			<div
				style={{
					flex: 1,
					overflow: 'auto'
				}}
			>
				<RegionsList/>
			</div>
		)
	}
}

function mapStateToProps (state) {
	return {
		regions: state.regions.regions,
		user: state.auth.user
	}
}

export default connect(mapStateToProps, null)(withRouter(RegionsListPage))