
import React , {Component , createRef} from 'react'

import {Tab, Button, Image, Divider, Segment, Icon, Header, Container, Modal ,Sticky , Rail, Confirm} from 'semantic-ui-react'
import NewPvModal from "./RegionPv";
import {fetchCommentsByRegion, deleteComment} from "../api";
import {withRouter} from 'react-router-dom'
import {Form, Col, Row, Card, Input, Popconfirm, message, Spin} from 'antd'

class RegionComments extends Component{
	contextRef = createRef()

	constructor(props) {
		super(props);

		this.state = {
			comments : []
		}
	}

	componentDidMount() {
		fetchCommentsByRegion(this.props.match.params.id).then(res => {
			this.setState({ comments: res })
		})
	}

	render() {
		return (
			<div
				style={{
					flex: 1,
					display: 'flex',
					justifyContent: 'flex-end',
					flexDirection: 'column',
					direction: 'rtl'
				}}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'space-between',
						marginTop: 20
					}}
				>
					<div style={{ flexDirection: 'row', display: 'flex' }}>
					</div>
				</div>

				<Divider />

				<div style={{minHeight: 250}}>
				   {
							this.state.comments ?
								this.state.comments.length > 0 ?
								this.state.comments.map(comment => {
								return (
									<Card
										style={{ textAlign: 'justify' }}
										key={`${comment._id}`}
										title = {comment.title}
									>

										<p>{comment.content} </p>
										<div>

											<Button
												basic
												color='red'
												onClick={() => {
													this.setState({
														[`deleteCommentConfirm${comment._id}`]: true
													})
												}}
											>
											حذف التعليق
											</Button>

											<Confirm
												content={'هل أنت واثق؟'}
												className='fixModal'
												cancelButton='إلغاء'
												confirmButton='حسنا'
												open={this.state[`deleteCommentConfirm${comment._id}`]}
												onCancel={() => {
													this.setState({
														[`deleteCommentConfirm${comment._id}`]: false
													})
												}}
												onConfirm={async () => {
													this.setState({
														[`deleteCommentConfirm${comment._id}`]: false
													})

													await deleteComment(comment._id)

													fetchCommentsByRegion(this.props.match.params.id)
														.then(res => {
															this.setState({ comments: res })
														})
												}}
											/>
										</div>
									</Card>

								)
							}):<h3> لا يوجد تعليقات </h3> : <div
									style={{
										flex: 1,
										height: '100%',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center'
									}}
								>
									<Spin size='large'/>
								</div>
						}
				</div>
			</div>
		)
	}
}

export default withRouter(RegionComments)