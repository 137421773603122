import * as api from '../api'
import * as actionTypes from '../constants/action-types'

export const fetchSurvey = surveyId => dispatch => {
	dispatch({
		type: actionTypes.FETCH_SURVEY_REQUEST,
		surveyId
	})

	api.fetchSurvey(surveyId).then(res => {
		dispatch({
			type: actionTypes.FETCH_SURVEY_REQUEST_SUCCESS,
			payload: res
		})
	}).catch(err => {
		dispatch({
			type: actionTypes.FETCH_SURVEY_REQUEST_FAIL,
			payload: err
		})
	})
}

export const submitResult = (surveyId, recordId, result, success) => dispatch => {

	dispatch({
		type: actionTypes.SUBMIT_SURVEY,
		surveyId,
		payload: result
	})

	return api.saveResult(surveyId, recordId, result).then(() => {
		success()
		
		dispatch({
			type: actionTypes.SUBMIT_SURVEY_SUCCESS
		})
	}).catch((err) => {
		dispatch({
			type: actionTypes.SUBMIT_SURVEY_FAIL,
			payload: err
		})
	})
}