import * as api from '../api'
import * as actionTypes from '../constants/action-types'

export const fetchSurveyForEdit = surveyId => dispatch => {
	dispatch({
		type: actionTypes.FETCH_SURVEY_REQUEST,
		surveyId
	})

	api.fetchSurvey(surveyId).then(res => {
		dispatch({
			type: actionTypes.FETCH_SURVEY_REQUEST_SUCCESS,
			payload: res
		})
	}).catch(err => {
		dispatch({
			type: actionTypes.FETCH_SURVEY_REQUEST_FAIL,
			payload: err
		})
	})
}

export const updateSurvey = survey => dispatch => {
	dispatch({
		type: actionTypes.UPDATE_SURVEY_REQUEST,
		payload: survey
	})
	api.updateSurvey(survey).then(() => {
		dispatch({
			type: actionTypes.UPDATE_SURVEY_REQUEST_SUCCESS
		})
		dispatch(fetchSurveyForEdit(survey._id))
	}).catch((err) => {
		dispatch({
			type: actionTypes.UPDATE_SURVEY_REQUEST_FAIL,
			payload: err
		})
	})
}