import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
//import Survey from '../components/Survey/Survey'
import { getSurvey, getFetchError, getFetchStatus, getSubmitStatus } from '../reducers/survey'
import { submitResult, fetchSurvey } from '../actions/survey'
import { withRouter } from 'react-router'

//import '../styles.css'
import 'survey-react/survey.css'
import * as Survey from 'survey-react'
//import {Button , Modal} from "semantic-ui-react";
import { Button, Modal  } from 'antd'

import { addPv } from '../api'
import {fetchPvs} from "../actions";

class NewPvModal extends React.Component {

	constructor(props) {
		super(props)

		this.state = {
			isCompleted: false,
			isOpen: false
		}

		this.onCompleteComponent = this.onCompleteComponent.bind(this)
	}

	onCompleteComponent(survey , optionts) {
		this.setState({ isCompleted: true })
	}

	render() {

			const model = new Survey.Model(JSON.stringify(
				{
				"title": "محضر جلسة متابعة برنامج جسور",
				"pages": [
					{
						"name": "page1",
						"elements": [
							{
								"type": "text",
								"name": "المكان",
								"title": "المكان",
								"placeHolder": "مركز الدفاع والإدماج الاجتماعي بـ"
							},
							{
								"type": "text",
								"name": "المكان1",
								"title": "المكان",
								"placeHolder": "قسم النهوض الاجتماعي بـــ"
							},
							{
								"type": "text",
								"name": "مكان آخر, ذكره",
								"title": "مكان آخر, ذكره"
							},
							{
								"type": "matrixdynamic",
								"name": "الحاضرون",
								"title": "الحاضرون",
								"columns": [
									{
										"name": "الاسم واللقب"
									},
									{
										"name": "الصفة في البرنامج"
									}
								],
								"choices": [
									1,
									2,
									3,
									4,
									5
								],
								"cellType": "text"
							},
							{
								"type": "matrixdynamic",
								"name": "متدخلين  تم تشريكهم بالإجتماع",
								"title": "متدخلين \nتم تشريكهم بالإجتماع\n",
								"columns": [
									{
										"name": "الاسم واللقب"
									},
									{
										"name": "الصفة /المؤسسة"
									}
								],
								"choices": [
									1,
									2,
									3,
									4,
									5
								],
								"cellType": "text"
							}
						],
						"title": "الإطار العام "
					},
					{
						"name": "page2",
						"elements": [
							{
								"type": "matrixdynamic",
								"name": "دراسة حالات جديدة تم إستكشافها",
								"title": "دراسة حالات جديدة تم إستكشافها",
								"columns": [
									{
										"name": "yes",
										"cellType": "boolean"
									},
									{
										"name": "عددها ",
										"cellType": "text",
										"enableIf": "{دراسة حالات جديدة تم إستكشافها[0].yes} = true"
									}
								],
								"choices": [
									1,
									2,
									3,
									4,
									5
								],
								"rowCount": 1
							},
							{
								"type": "matrixdynamic",
								"name": "متابعة حالات (تم استكشافها سابقا) بهدف التعمق في دراسته",
								"title": "متابعة حالات (تم استكشافها سابقا) بهدف التعمق في دراسته",
								"columns": [
									{
										"name": "yes",
										"cellType": "boolean"
									},
									{
										"name": "عددها ",
										"cellType": "text",
										"enableIf": "{متابعة حالات (تم استكشافها سابقا) بهدف التعمق في دراسته[0].yes} = true"
									}
								],
								"choices": [
									1,
									2,
									3,
									4,
									5
								],
								"rowCount": 1
							},
							{
								"type": "boolean",
								"name": "مناقشة عوامل الهشاشة التي تمت ملاحظتها  بالحي",
								"title": "مناقشة عوامل الهشاشة التي تمت ملاحظتها  بالحي"
							},
							{
								"type": "expression",
								"name": "  يتم تقديم الوضعيات المكتشفة طبقا للأنموذج المرفق بالملاحق  واستنادا على المثال المقدم في الغرض",
								"title": "  يتم تقديم الوضعيات المكتشفة طبقا للأنموذج المرفق بالملاحق  واستنادا على المثال المقدم في الغرض\n",
								"commentText": "Other (describe)"
							}
						],
						"title": "جدول الأعمال"
					},
					{
						"name": "page3",
						"elements": [
							{
								"type": "comment",
								"name": "في علاقة بوضعيات الشباب",
								"title": "في علاقة بوضعيات الشباب"
							},
							{
								"type": "comment",
								"name": "في علاقة بوضعية الهشاشة بالحي",
								"title": "في علاقة بوضعية الهشاشة بالحي"
							},
							{
								"type": "comment",
								"name": "القرارات و التوصيّات",
								"title": "القرارات و التوصيّات"
							},
							{
								"type": "expression",
								"name": "الاجتماع القادم",
								"title": "الاجتماع القادم",
								"commentText": "Other (describe)"
							},
							{
								"type": "datepicker",
								"name": "التاريخ",
								"title": "التاريخ"
							},
							{
								"type": "text",
								"name": "التوقيت",
								"title": "التوقيت",
								"placeHolder": "من ............. إلى ............. "
							},
							{
								"type": "text",
								"name": "المكان2",
								"title": "المكان"
							},
							{
								"type": "text",
								"name": "مقرر الجلسة",
								"title": "مقرر الجلسة"
							},{
								"type": "signaturepad",
								"name": "الإمضاء ",
								"title": "الإمضاء "
							   }
						],
						"title": "فعاليات الجلسة"
					}
				]
			}
			))

			return (
				<>
					<Button
						type='primary'
						size='large'
						style={{
							marginLeft: 16,
							marginBottom: 16
						}}
						onClick={() => {
							//this.props.createSurvey(this.props.currentUser._id, this.props.region)
							this.setState({isOpen: true})
						}}
					>
						إنشاء محضر
					</Button>

					<Modal
						visible={this.state.isOpen}
						title='P . V'
						onCancel={() => this.setState({isOpen: false})}
						width={720}
						okText={'تقديم المحضر'}
						cancelText={'إلغاء'}
						onOk={async () => {
							
							await addPv({
								pv: this.survey.data,
								user: this.props.currentUser,
								region: this.props.match.params.id
							})

							await this.props.fetchPvs(this.props.match.params.id)
							
							this.setState({ isOpen: false })
						}}
					>
						<div
							style={{
								direction: 'rtl'
							}}
						>
							<Survey.Survey
								ref={survey => {
									if (survey && survey.survey) {
										this.survey = survey.survey
										window.survey = survey.survey
									}
								}}
								model={model}
								onComplete={this.onCompleteComponent}
								//onValueChanged={this.onValueChanged}
							/>
						</div>
					</Modal>
				</>
			)
		}

}

const mapStateToProps = (state) => {
	return {
		survey: getSurvey(state.survey),
		isLoading: getFetchStatus(state.survey),
		error: getFetchError(state.survey),
		isSuccess: getSubmitStatus(state.survey),
		currentUser: state.auth.user
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		onSubmit: bindActionCreators(submitResult, dispatch),
		fetchSurvey: bindActionCreators(fetchSurvey, dispatch),
		fetchPvs:(regionId) => dispatch(fetchPvs(regionId))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NewPvModal))